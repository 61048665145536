export function isFunction(item) {
	const obj = _.isObject(item)
	return !!(obj && typeof item.type === 'function' && _.has(item, 'type'))
}

export function isPlainObject(item) {
	// Lodash' isPlainObject function returns true for rendered components
	const obj = _.isObject(item)
	return !!(obj && !isFunction(item))
}
