import {LegacyDropdownPicker as DropdownPicker, LegacyDatePicker as DatePicker, LegacySwitch as Switch} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {finishSubStep} from 'actions/onboarding'
import {ModalContext} from 'components/general/modal'
import useMergeState from 'hooks/use_merge_state'
import {useShared} from 'hooks/use_shared'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {queryInEnv} from 'selectors/records'
import {submitExport} from './actions'
import {TeamButton} from 'components/team'
import {ListPicker} from 'common/components'

let orgFilterItems = [
	{
		id: 1,
		name: 'envOnly',
	},
	{
		id: 2,
		name: 'envContractees',
	},
	{
		id: 3,
		name: 'org',
	},
]

const ExportForm = ({name, formRef, initialValues = {}, opts = {}, ...rest}) => {
	const _initialValues = {...initialValues, contract_types: '', org_filter: 1}
	const [state, setState] = useMergeState(_initialValues)
	const {isOrg, isMultiOrg} = useShared()
	const teams = useSelector(state => queryInEnv(state, 'teams'))
	let contractTypes = useSelector(state => state.taxonomy.contract_types)
	contractTypes = [{id: '', name: 'all'}, ...contractTypes] // we use empty string because its falsy and antd doesnt recongize null/undefined
	let {required = [], optional = []} = opts

	if (!isMultiOrg) {
		required = required.filter(r => r != 'org_filter')
	}

	if (!isOrg) {
		// only allow the org option if you're part of more than 1 env.. not really what it should be ofc, but enough to get along for now..
		orgFilterItems = orgFilterItems.filter(o => o.name != 'org')
	}

	const {org_filter} = state

	const data = {
		teams,
		org_filter,
		contractTypes,
	}

	const {startDatePicker, endDatePicker, orgFilterPicker, contractTypesPicker, teamsPicker, filterUsersOnHoursPicker} = makeFields({
		optional,
		required,
		data,
	})

	const modal = useContext(ModalContext)

	const afterSubmit = () => {
		utils.openNotif(t('prepFile'), 'info', t('giveMin'))
		finishSubStep('uren', 'exported')
		modal.goBack()
	}

	return (
		<div className="borderTop" style={{marginTop: 24}}>
			<Form
				ref={formRef}
				setState={setState}
				mirrorFields={['org_filter']}
				onSubmit={data => submitExport(name, data)}
				afterSubmit={afterSubmit}
				{...rest}
				initialValues={_initialValues}
			>
				{startDatePicker && (
					<div className="form-row" fieldWrapper>
						{startDatePicker}
						{endDatePicker}
					</div>
				)}

				{orgFilterPicker}

				{!filterUsersOnHoursPicker
					? contractTypesPicker
					: filterUsersOnHoursPicker && (
							<div className="form-row" fieldWrapper>
								{contractTypesPicker}
								{filterUsersOnHoursPicker}
							</div>
					  )}
				{teamsPicker}
			</Form>
		</div>
	)
}

export default ExportForm

const makeFields = ({required, optional, data}) => {
	let obj = {}
	const props = {required: true}
	required.forEach(r => (obj[r] = makeField(r, {data, props})))
	optional.forEach(r => (obj[r] = makeField(r, {data})))
	obj = _.mapKeys(obj, (value, key) => `${_.camelCase(key)}Picker`)
	return _.pickBy(obj) // this filters out null values
}

const makeField = (name, {props = {}, data = {}} = {}) => {
	const sharedProps = {field: name, ...props}
	const {teams, org_filter, contractTypes} = data
	switch (name) {
		case 'start_date':
			return <DatePicker bordered={false} label="start_export_date" isStart {...sharedProps} />
		case 'end_date':
			return <DatePicker bordered={false} label="end_export_date" isEnd {...sharedProps} />
		case 'teams':
			return (
				<div fieldWrapper className="fRow aCen jBetween" style={{display: org_filter != 1 ? 'none' : 'flex', padding: '12px 24px'}}>
					<p className="eitje-text-secondary">{t('export_filter_teams')}</p>
					<ListPicker
						raw
						dropdown
						disabled={org_filter != 1}
						items={teams}
						bordered={false}
						title="teams"
						placeholder={t('pickTeams')}
						labelField="naam"
						Trigger={TeamButton}
						{...sharedProps}
					/>
				</div>
			)

		case 'filter_users_on_hours':
			return <Switch {...sharedProps} />
		case 'org_filter':
			return <DropdownPicker bordered={false} noSort title="orgOpts" items={orgFilterItems} {...sharedProps} />
		case 'contract_types':
			return <DropdownPicker bordered={false} noSort items={contractTypes} {...sharedProps} />
	}
}
