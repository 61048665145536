import {FullName, PlannedTime, TotalMins, getDifference, CheckedInTime} from './cells'
import {buildColumn, XLARGE_CELL_WIDTH, sortRowsOnTimes, dateToTime} from 'common/components/advanced_table'
import {StatusLegend} from 'cores/time_registration/components/status_legend'

const getRegStart = row => row.original.start_date
const getRegEnd = row => row.original.end_date
const getPlanShift = record => (record.tableName === 'planningShifts' ? record : record.planningShift)
const getPlanStart = row => getPlanShift(row.original)?.start_date
const getPlanEnd = row => getPlanShift(row.original)?.end_date

export const columns = [
	{
		id: 'user_id',
		cell: FullName,
		accessorFn: shift => shift.user?.full_name,
		width: XLARGE_CELL_WIDTH,
	},
	{
		id: 'team_id',
		cell: 'TeamCell',
		sortingFn: (team1, team2) => {
			// passing 'alphanumeric' doesn't work when the sorted TeamCell column is grouped
			const name1 = team1.original.team.naam
			const name2 = team2.original.team.naam
			return name1.localeCompare(name2)
		},
	},
	{
		id: 'date',
		cell: 'DateCell',
	},
	{
		id: 'total',
		cell: TotalMins,
		accessorFn: shift => (shift.isReg() ? shift.totalMins() : 0),
		groupedRowCell: 'TimeCellWithZero',
	},
	{
		id: 'plan_shift_id',
		cell: PlannedTime,
		accessorFn: shift => (shift.isPlan() ? shift.totalMins() : shift.planningShift?.totalMins?.() || 0),
		groupedRowCell: 'TimeCellWithZero',
		aggregationFn: 'sum',
	},
	{
		id: 'plan_start_time',
		cell: 'TimeCell',
		accessorFn: shift => dateToTime(getPlanShift(shift)?.start_date),
		sortingFn: (row1, row2) => sortRowsOnTimes({row1, row2, accessorFn: getPlanStart}),
	},
	{
		id: 'plan_end_time',
		cell: 'TimeCell',
		accessorFn: shift => dateToTime(getPlanShift(shift)?.end_date),
		sortingFn: (row1, row2) => sortRowsOnTimes({row1, row2, accessorFn: getPlanEnd}),
	},
	{
		id: 'start',
		cell: 'TimeCell',
		accessorFn: shift => shift.isReg() && dateToTime(shift.start_date),
		sortingFn: (row1, row2) => sortRowsOnTimes({row1, row2, accessorFn: getRegStart}),
	},
	{
		id: 'end',
		cell: 'TimeCell',
		accessorFn: shift => shift.isReg() && dateToTime(shift.end_date),
		sortingFn: (row1, row2) => sortRowsOnTimes({row1, row2, accessorFn: getRegEnd}),
	},
	{
		id: 'difference',
		cell: 'TimeCellWithZero',
		accessorFn: getDifference,
		groupedRowCell: 'TimeCell',
	},
	{
		id: 'checked_in_time',
		cell: props => <CheckedInTime {...props} />,
		accessorFn: shift => shift.checkInTime(),
		customPlaceholder: true,
		groupedRowCell: 'TimeCellWithZero',
	},
	{
		id: 'total_worked',
		accessorFn: shift => shift.durationString({isHours: true}),
	},
	{
		accessorKey: 'pauze_duur',
		cell: 'TimeCellWithZero',
	},
	{
		id: 'environment_id',
		accessorFn: shift => shift.team?.environment?.naam,
	},
	{
		id: 'exported',
		accessorFn: shift => (shift.exported() ? 'yes' : 'no'),
		cell: 'BooleanCell',
		aggregates: false,
	},
	{
		id: 'meal',
		accessorFn: shift => shift.maaltijd?.length,
		sortingFn: 'basic',
		aggregates: true,
	},
	{
		id: 'status',
		cell: 'StatusCell',
		accessorFn: shift => shift.regStatus(),
		tooltipElement: StatusLegend,
	},
].map(buildColumn)
