import {find} from '@eitje/easy_api'
import {LegacyDropdownPicker as DropdownPicker} from '@eitje/form-fields-web'
import {inviteAllUsers} from 'actions/entities'
import HRSyncButton from 'components/hr_sync_button'
import {RouteSel} from 'components/routing'
import {ConfirmButton, EitjeButton, Layout} from 'common/components'
import useParams from 'hooks/use_params'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {getEnvSetting} from 'selectors/new_settings'
import {TourButton} from 'common/components'
import {useHasRoleInAnyEnv, useShared} from 'hooks'
import './header.less'

const EnvRouteSel = () => {
	const hist = useHistory()
	const {id} = useParams()
	const team = useSelector(state => find(state, 'teams', id))
	const env = useSelector(state => find(state, 'environments', team.environment_id))

	const {orgEnvs} = useShared()

	let branchItems = _.orderBy(
		orgEnvs.map(e => ({name: e.naam, id: `/teams/${e.locked_team_id}`})),
		'name',
	)
	if (orgEnvs.length == 1) return null
	branchItems = [{name: t('allBranches'), id: `/users`}, ...branchItems]
	return (
		<DropdownPicker
			style={{width: '192px'}}
			items={branchItems}
			value={id ? env.naam : t('allBranches')}
			onChange={val => hist.push(val)}
			noSort
		/>
	)
}

const Header = ({columnPicker, searchInput}) => {
	const inrichtingsModus = useSelector(state => getEnvSetting(state, 'onboarding', 'active'))
	const {pathname} = useLocation()
	const {hasContractRole, org} = useShared()
	const hasManagerRole = useHasRoleInAnyEnv('manager')
	const employedItems = [
		{name: t('employed'), id: pathname.replace('/unemployed', '')},
		{name: t('unemployed'), id: pathname.includes('unemployed') ? pathname : `${pathname}/unemployed`},
	].filter(Boolean)

	return (
		<div className="page-menu-container">
			<div className="page-menu team-page-menu">
				<Layout>
					<div style={{width: '192px'}}>{searchInput}</div>
					<EnvRouteSel />
					{hasManagerRole && <RouteSel style={{width: 140}} className="employment-status-dropdown" items={employedItems} />}
				</Layout>

				{(hasManagerRole || hasContractRole) && (
					<Layout>
						<TourButton tourId={375305} />
						{columnPicker}

						<HRSyncButton />

						{hasManagerRole && !org.billing_done && !inrichtingsModus && (
							<ConfirmButton iconLeft="person" onClick={inviteAllUsers} title={t('inviteAllWarning')}>
								{t('inviteAllUsers')}
							</ConfirmButton>
						)}

						<EitjeButton
							data-intercom-target="invite-button"
							iconLeft="person-plus"
							colorSet="solid"
							modalLink="/forms/invite"
							t="invite"
						/>
					</Layout>
				)}
			</div>
		</div>
	)
}

export default Header
