import React, {createContext, useState} from 'react'

export const ChatStateContext = createContext({})

export const ChatStateProvider = props => {
	const [channelFilter, setChannelFilter] = useState('all')
	const [searchUserQuery, setSearchUserQuery] = useState('')
	const [showConversationMaker, toggleConversationMaker] = useState(false) //create a new chat div
	const [activelySelectedChannels, setActivelySelectedChannels] = useState([])
	const [isGroupEdit, setGroupEdit] = useState(false)
	const [isChatDropdown, toggleChatDropdown] = useState(false)
	const [currentChannel, setCurrentChannel] = useState({})
	const [chatWindowOpen, setChatWindowOpen] = useState(true)
	const [oneToOneUsers, setOneToOneUsers] = useState([])
	const [filterUnreadMessages, setFilterUnreadMessages] = useState(false)

	const [modular, setModular] = useState(false)
	const [openToMe, setOpenToMe] = useState({})

	const searchContext = {
		channelFilter,
		setChannelFilter,
		searchUserQuery,
		openToMe,
		isGroupEdit,
		setGroupEdit,
		setOpenToMe,
		setSearchUserQuery,
		showConversationMaker,
		toggleConversationMaker,
		activelySelectedChannels,
		setActivelySelectedChannels,
		isChatDropdown,
		toggleChatDropdown,
		currentChannel,
		setCurrentChannel,
		chatWindowOpen,
		setOneToOneUsers,
		setChatWindowOpen,
		setModular,
		modular,
		oneToOneUsers,
		filterUnreadMessages,
		setFilterUnreadMessages,
	}

	return <ChatStateContext.Provider value={searchContext}>{props.children}</ChatStateContext.Provider>
}
