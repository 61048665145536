import {buildColumn, LARGE_CELL_WIDTH, XLARGE_CELL_WIDTH} from 'common/components/advanced_table'
import {t} from 'initializers/i18n'

export const columns = [
	{
		accessorKey: 'formatted_invoice_number',
	},
	{
		accessorKey: 'status',
		cell: 'StatusCell',
	},
	{
		accessorKey: 'billing_date',
		cell: 'DateCell',
	},
	// Skip aggregates for below money cells to not confront users with how much they paid us in total :p
	{
		accessorKey: 'amt',
		cell: 'MoneyCell',
		aggregates: false,
	},
	{
		accessorKey: 'vat_amt',
		cell: 'MoneyCell',
		aggregates: false,
	},
	{
		accessorKey: 'total_amt',
		cell: 'MoneyCell',
		aggregates: false,
	},
	{
		accessorKey: 'pdf',
		cell: 'ExtLinkCell',
	},
	{
		id: 'env_names',
		accessorFn: invoice => invoice.env_names.join(', '),
		width: XLARGE_CELL_WIDTH,
	},
]
	.map(column => ({width: LARGE_CELL_WIDTH, ...column}))
	.map(buildColumn)
