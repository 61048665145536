import {useFilteredShifts, useEditableFilteredShifts, useNonPlanShifts, getTotals} from 'cores/time_registration'
import {NAMESPACE, useCheckboxLeft} from '.'

export function useDayItems(date) {
	const dayData = useTotals(date)
	return items(dayData)
}

export function items(data) {
	const shared = {type: 'greySubtitle', namespace: NAMESPACE}
	return [
		{value: data.totalWorkedString, localValue: 'hoursShowTotalWorked', ...shared},
		{value: data.totalPlannedString, localValue: 'hoursShowPlannedWorkedDiff', ...shared},
	]
}

export function useTotals(dateOrRange) {
	const {planShifts} = useFilteredShifts()
	const nonPlanShifts = useNonPlanShifts()
	const workedShifts = nonPlanShifts.where({date: dateOrRange})
	const dayPlanShifts = planShifts.where({date: dateOrRange})
	return getTotals({regShifts: workedShifts, planShifts: dayPlanShifts})
}

export function useDayCheckbox(date) {
	const shifts = useEditableFilteredShifts().where({date})
	return useCheckboxLeft(shifts)
}
