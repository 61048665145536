import {PrivateRoute, ProtectedRoute, PublicRoute} from 'components/private_route'
import {
	Welcome,
	Login,
	ActivateAccount,
	UserInfo,
	CreateAccount,
	CreateEnvironment,
	ForgotPassword,
	ResetPassword,
	ResetPasswordInstructions,
	NoEnvironment,
} from 'cores/auth/pages'
import {Route} from 'react-router-dom'

export const authRoutes = [
	<PublicRoute path="/" component={Welcome} exact />,
	<PublicRoute path="/register" component={Welcome} />,
	<PublicRoute path="/login" component={Login} />,
	<PublicRoute path="/activate_account" component={ActivateAccount} />,
	<PublicRoute path="/create_account" component={CreateAccount} />,
	<Route path="/create_environment" component={CreateEnvironment} />,
	<PublicRoute path="/forgot_password" component={ForgotPassword} />,
	<PublicRoute path="/reset_password" component={ResetPassword} />,
	<PublicRoute path="/reset_password_instructions" component={ResetPasswordInstructions} />,
]

export const afterAuthRoutes = [
	<PrivateRoute path="/user_info" exact component={UserInfo} />,
	<PrivateRoute path="/no_environment" exact component={NoEnvironment} />,
]
