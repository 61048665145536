import {useEffect, useState} from 'react'

export const useContainerDimensions = ref => {
	const [dimensions, setDimensions] = useState({width: 0, height: 0})

	const getDimensions = () => {
		if (!ref.current) return dimensions
		return {
			width: ref.current.offsetWidth,
			height: ref.current.offsetHeight,
		}
	}

	useEffect(() => {
		if (ref && ref.current) {
			const handleResize = () => {
				setDimensions(getDimensions())
			}
			if (ref.current) {
				setDimensions(getDimensions())
			}

			window.addEventListener('resize', handleResize)

			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}
	}, [ref?.current?.offsetWidth, ref?.current?.offsetHeight])

	return dimensions
}
