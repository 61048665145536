import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import useAvailability from 'hooks/use_availability'
import {usePlanningDates} from 'cores/planning/index'

const usePlanningDatesLocked = envId => {
	const {isLocked} = useAvailability(envId)
	const {dateRange, dateArray} = usePlanningDates()
	const days = useWhere('days', {environment_id: envId})
	return dateArray.every(d => {
		return isLocked(d) || days.find(d2 => d2.date == d.format())?.closed
	})
}

export default usePlanningDatesLocked
