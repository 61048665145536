import {chatClient} from 'actions/chat'
import {Spin} from 'antd'
import ChatClientWrapper from 'components/chat/ChatClientWrapper'
import ChannelRhsContainer from 'components/chat/containers/rhs_chat_container/rhs_chat_container'
import {ChatStateContext, ChatStateProvider} from 'components/providers/chat_provider'
import {store} from 'index'
import React, {useContext, useEffect} from 'react'
import {Channel, MessageInput, MessageList, Window} from 'stream-chat-react'
import useShared from 'hooks/use_shared'
import {PageLayout} from 'common/components'

const _messageActions = ['quote', 'react']

const StateWrapper = () => {
	const {hasAdminRole} = useShared()

	const {isGroupEdit, setGroupEdit, toggleConversationMaker} = useContext(ChatStateContext)
	const handleEditBlur = () => {
		toggleConversationMaker(false)
		setGroupEdit(false)
	}

	const messageActions = [..._messageActions, hasAdminRole && 'delete', hasAdminRole && 'edit'].filter(Boolean)

	return (
		<ChatClientWrapper>
			<div onClick={handleEditBlur} className={isGroupEdit && 'chat_window_overlay'} />
			<Channel>
				<Window>
					<MessageList messageActions={messageActions} />
					<MessageInput focus={true} />
				</Window>
				<ChannelRhsContainer />
			</Channel>
		</ChatClientWrapper>
	)
}

const ChatMain = props => {
	useEffect(() => {
		return () => {
			store.dispatch({type: 'OPEN_TO_ME', openToMe: ''})
		}
	})

	const body = chatClient?._user?.id ? (
		<div className="chat_container veedree">
			<ChatStateProvider>
				<StateWrapper />
			</ChatStateProvider>
		</div>
	) : (
		<div className="chat-loading-container">
			<Spin size="large" />
		</div>
	)

	return (
		<PageLayout name="chat" bodyBottomPadding={0}>
			{body}
		</PageLayout>
	)
}

export default ChatMain
