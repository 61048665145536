import {Text, EitjeButton} from 'common/components'
import {InboxList} from '.'
import {useShared} from 'hooks'

export function useTaxonomy() {
	const {me, env} = useShared()
	const ns = 'modals.inbox.buttons'

	const integBtn = {iconLeft: 'computer-server-exchange', t: `${ns}.integrations`, link: '/integrations'}
	const notifBtn = {iconLeft: 'envelope', t: `${ns}.notification_settings`, modalLink: `/forms/user_preferences/${me.id}/settings`}
	const birthdayBtn = {iconLeft: 'birthday-cake', t: `${ns}.birthdays`, link: `/teams/${env.locked_team_id}`}
	const stdExportBtn = {iconLeft: 'document', t: `${ns}.standard_exports`, modalLink: '/exports/hours'}
	const myTablesBtn = {iconLeft: 'document', t: `${ns}.my_tables`, link: '/exports2/tables'}

	return [
		{name: 'all', placeholder: {animation: 'ringing-bell'}, markRead: true},
		{
			name: 'reminders',
			placeholder: {animation: 'pinned-note'},
			buttons: notifBtn,
			subRoutes: [
				{
					name: 'birthdays',
					placeholder: {animation: 'confetti'},
					buttons: [notifBtn, birthdayBtn],
				},
				{name: 'contracts', placeholder: {animation: 'contract'}, buttons: [notifBtn]},
			],
		},
		{
			name: 'operations',
			placeholder: {animation: 'gear-and-document'},
			markRead: true,
			subRoutes: [
				{
					name: 'excels',
					scope: 'excel',
					placeholder: {animation: 'excel'},
					buttons: stdExportBtn,
					markRead: true,
				},
				{
					name: 'custom_excels',
					scope: 'custom_excel',
					placeholder: {animation: 'excel'},
					buttons: myTablesBtn,
				},
				{
					name: 'hr_integrations',
					scope: 'hr',
					placeholder: {animation: 'three-people-with-pointing-finger'},
					buttons: integBtn,
					markRead: true,
				},
				{name: 'pos_integrations', scope: 'pos', placeholder: {animation: 'coins'}, buttons: [integBtn], markRead: true},
				{name: 'loyalty_integrations', scope: 'loyalty', placeholder: {animation: 'coins'}, buttons: integBtn, markRead: true},
			],
		},
	]
}

export function getTaxItem(name) {
	const tax = useTaxonomy()
	let group = tax.find(t => t.name === name)
	if (group) return {...group, groupName: group.name}

	group = tax.find(t => t.subRoutes?._map('name').some(t => t === name))
	const subGroup = group?.subRoutes.find(t => t.name === name)

	return {...subGroup, groupName: group?.name}
}
