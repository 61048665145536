import {userSearcher} from 'actions/chat'
import {PlusFab} from 'components/ui'
import {t} from 'initializers/i18n'
import React, {useContext, useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {orgUsersSelector} from 'selectors/entities'
import {CheckboxButton} from 'components/ui'
import {ChannelList, withChatContext} from 'stream-chat-react'
import {ChatStateContext} from '../../providers/chat_provider'
import ChannelListBox from '../ChannelListBox'
import ChannelTypeSelector from '../ChannelTypeSelector'
import {useTotalChatUnreadCount} from 'components/chat/use_total_chat_unread_count'
import ConversationSearchBox from '../ConversationSearchBox'
import ChannelListContainer from './ChannelListContainer'
import ConversationCreatorContainer from './ConversationCreatorContainer'

export const EmptyList = () => {
	return (
		<div className="fRow aCen jCen" style={{width: '100%', marginTop: 40}}>
			<p className="eitje-text-secondary bold">No Conversations Found</p>
		</div>
	)
}

const PlusSvg = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" fill="white" height="20" viewBox="0 0 25 25">
		<path fill="white" d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
	</svg>
)

const filterUnreadChannels = (channels = []) =>
	channels.filter(channel => channel?.state?.unreadCount).filter(channel => channel.state.unreadCount > 0)

const StreamChannelList = React.memo(props => {
	const {modular, searchUserQuery, channelFilter, filterUnreadMessages} = useContext(ChatStateContext)
	const currentUserId = useSelector(state => state.auth.user.id)
	const openToMe = useSelector(state => state.chat.openToMe)
	const envUsersArr = useSelector(orgUsersSelector).filter(u => u.id !== currentUserId)

	const searchUserArr = userSearcher(envUsersArr, searchUserQuery)
	const userArr = searchUserArr.length > 0 ? {$in: searchUserArr} : []
	const members = {members: {$in: [currentUserId.toString()]}}

	const filters = {
		all: members,
		team: {type: {$in: ['team', 'group']}, ...members},
		personal: {type: {$in: ['messaging']}, ...members},
		search:
			searchUserQuery.length === 0
				? members
				: {
						$or: [
							{type: {$in: ['team', 'group', 'messaging']}, members: userArr},
							{type: {$in: ['team', 'group']}, name: {$autocomplete: searchUserQuery}},
						],
				  },
	}

	return (
		<ChannelList
			customActiveChannel={openToMe.length > 0 ? openToMe : null}
			filters={filters[channelFilter]}
			Preview={ChannelListBox}
			List={ChannelListContainer}
			channelRenderFilterFn={(channels = []) => (filterUnreadMessages ? filterUnreadChannels(channels) : channels)}
			setActiveChannelOnMount={!modular}
			sort={{last_updated: -1}}
			EmptyStateIndicator={EmptyList}
		/>
	)
})

// translate
const MessageCountHeader = () => {
	const count = useTotalChatUnreadCount()
	return (
		<div className="fRow aCen" style={{marginLeft: '4px'}}>
			<h3 className="removeMargin">{t('messages')}</h3>
			{count > 0 && (
				<div className="chat_notify_circle" style={{marginLeft: '4px'}}>
					{count}
				</div>
			)}
		</div>
	)
}

const FilterUnreadMessages = () => {
	const {setFilterUnreadMessages, filterUnreadMessages} = useContext(ChatStateContext)
	return (
		<div>
			<CheckboxButton onChange={() => setFilterUnreadMessages(state => !state)} checked={filterUnreadMessages} t="filterUnread" />
		</div>
	)
}

const ChannelContainer = props => {
	const {
		showConversationMaker,
		toggleConversationMaker,
		modular,
		channelFilter,
		toggleChatDropdown,
		setFilterUnreadMessages,
		filterUnreadMessages,
	} = useContext(ChatStateContext)
	const ref = useRef(null)
	const typeShow = modular || channelFilter === 'search'

	useEffect(() => {
		modular && props.setActiveChannel()
	}, [modular, props])

	useEffect(() => {
		const handleWindowClick = e => {
			if (
				ref.current &&
				!ref.current.contains(e.target) &&
				!e.target.classList.contains('userAvatar') &&
				!e.target.classList.contains('ant-popover-inner-content') &&
				!e.target.classList.contains('chatModularTrigger')
			) {
				toggleChatDropdown(false)
			}
		}
		document.addEventListener('click', handleWindowClick)
		return () => {
			document.removeEventListener('click', handleWindowClick)
		}
	}, [toggleChatDropdown])

	if (modular) {
		return (
			<div className={'modular channel_list veedree'} ref={ref}>
				<div className="channel_list_header">
					<MessageCountHeader />
					<div className="modular_channel_icons">
						<Link to="/chat">
							<div className="channel_list_icons">
								<img src={'/images/web/icons/enlargeChatIcon.png'} />
							</div>
						</Link>
						<div onClick={() => toggleConversationMaker(s => !s)} className="channel_list_icons">
							<img src={'/images/web/icons/addChatIcon.png'} />
						</div>
					</div>
				</div>
				<div style={{padding: '0px 12px 8px 12px'}}>
					<ConversationSearchBox />
				</div>
				<StreamChannelList />
				{showConversationMaker && <ConversationCreatorContainer />}
			</div>
		)
	}

	return (
		<div className={'channel_list'} ref={ref}>
			<div className="channel_list_header">
				<div className="channel-list-header-wrapper">
					<MessageCountHeader />
					<FilterUnreadMessages />
				</div>
				<ChannelTypeSelector />
			</div>
			<StreamChannelList />
			<div className="footer shadowTopSoft" style={{gap: '12px'}}>
				<ConversationSearchBox footer />
				<PlusFab
					onClick={() => {
						toggleConversationMaker(state => !state)
					}}
				>
					<PlusSvg />
				</PlusFab>
			</div>

			{showConversationMaker && <ConversationCreatorContainer />}
		</div>
	)
}

export default withChatContext(ChannelContainer)
