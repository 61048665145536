import {WeekTableCell, EitjeDropdown} from 'common/components'
import {ModalLink, DocsLink} from 'components/routing'
import {t} from 'initializers/i18n'
import {useRoleMessage} from 'hooks'

export const Panel = ({...rest}) => {
	return (
		<WeekTableCell
			{...rest}
			cellType="tile"
			columnType="panel"
			displayName={t('common.events')}
			Wrapper={PanelDropdown}
			contentLayoutProps={{horizontal: 'start'}}
		/>
	)
}

const PanelDropdown = ({...rest}) => {
	const elements = [
		{icon: 'plus', element: <ModalLink t="common.add_event" to="/forms/events" />, disabled: useRoleMessage('manager')},
		{icon: 'question-mark-circled', element: <DocsLink to="6557134" t={t('events.docs_links.add_event_to_post')} />},
	]

	return <EitjeDropdown elements={elements} {...rest} placement="right-start" strategy="fixed" />
}
