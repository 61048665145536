import {TABLE_INFO} from 'common/components/advanced_table/instances/invoices'
import {GroupingPicker, ColumnPicker} from 'common/components/advanced_table'
import {PageHeader as _PageHeader} from 'common/components'

export const PageHeader = () => {
	return (
		<_PageHeader
			height="small"
			staticLabel="common.invoices"
			right={[<GroupingPicker {...TABLE_INFO} />, <ColumnPicker {...TABLE_INFO} />]}
		/>
	)
}
