import {useMemo} from 'react'
import {useCache} from '.'
import utils from '@eitje/web_utils'

export const useVisibleColumns = ({tableName, allTableColumns}) => {
	const cache = useCache({tableName}).visible || []
	return useMemo(() => filterVisibleColumns(cache, allTableColumns), [cache, allTableColumns])
}

// Use this memoized function to maintain object reference integrity, since without it, the array of columnNames
// would cause infinite loops when used as dependency in a useEffect hook.
const filterVisibleColumns = (cache, allTableColumns) => {
	const hasSavedPreferences = utils.exists(cache)

	// Simply show all columns if the user has not manually chosen any specific columns.
	if (!hasSavedPreferences) return allTableColumns

	// Otherwise return the stored user preference.
	return allTableColumns.filter(column => cache.includes(column)).uniq()
}
