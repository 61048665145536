import useAvShift from 'cores/availability/hooks/use_av_shift'
import {API, where, backend, useFind} from '@eitje/easy_api'
import {WeekShifts as BaseWeekShifts, AvCell} from '../components'
import {useUserContext} from 'contexts/user'
import {Link} from 'components/routing'
import {useShared} from 'hooks'
import '../styles/user_availability_table_button.less'

export const AvailabilityWeek = ({dates = [], id}) => {
	if (dates.length == 0) return null
	const {userId} = useUserContext()
	return (
		<div className="user-availability-table-row">
			{dates.map(d => (
				<AvailabilityDay date={d} />
			))}
			<div className="user-availability-table-row-item">
				<WeekShifts date={dates[0]} />
			</div>
			<div className="user-availability-table-row-item">
				<div className="user-availability-table-button">
					<Link to={`/user/${userId}`} className="user-availability-table-row-item-button">
						<img src="/images/web/icons/left_return_arrow.png" width={14} height={14} />
						<h5>Back</h5>
					</Link>
				</div>
			</div>
		</div>
	)
}

const AvailabilityDay = ({date}) => {
	const {userId} = useUserContext()
	const query = {user_id: userId, date: date.format('YYYY-MM-DD')}
	const avShift = useAvShift(query, {kind: 'availabilityTemplateShifts'})

	return (
		<div className="default-availability-cell-wrapper">
			<div className="availability-title-cell">
				<h5>{date.format('dddd')} </h5>
			</div>
			<div className="availability-status-cell">
				<AvCell alwaysOpen item={avShift} />
			</div>
		</div>
	)
}

const WeekShifts = props => {
	const {envId} = useShared()
	const {userId} = useUserContext()
	const userWeek = useFind('userTemplateWeeks', {user_id: userId, environment_id: envId})

	return <BaseWeekShifts userWeek={userWeek} {...props} />
}
