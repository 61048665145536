import {t} from 'initializers/i18n'
import useShared from 'hooks/use_shared'
import 'pages/v3/settings/style/settings.less'
import {EitjeButton, Layout, Text, Title} from 'common/components'
import {DocsLink} from 'components/routing'

export const OpenAPI = () => {
	const {org} = useShared()
	const {api_username, api_password} = org
	if (!api_username) return null // back-end doesn't return if you don't have rights
	return (
		<Layout horizontal="center" direction="vertical">
			<Title> Open API </Title>
			<Layout margin="8 - - -">
				<Text bold> Username: </Text>
				<Text className="unset-text-transform" darkGrey>
					{api_username}
				</Text>
			</Layout>
			<Layout>
				<Text bold>Password:</Text>
				<Text className="unset-text-transform" darkGrey>
					{api_password}
				</Text>
			</Layout>
			<Layout gap={12}>
				<DocsLink darkPink underline to="8581248" t="uitleg" />
				<a href="https://documenter.getpostman.com/view/443091/2s9YXk2gBR" target="_blank" rel="noreferrer">
					<Text darkPink underline>
						API docs
					</Text>
				</a>
			</Layout>
		</Layout>
	)
}

const BaseSettingsModalRow = ({btnText, to, label}) => {
	return (
		<div className="setting-container">
			<span className="setting-container-label">{label}</span>
			<div className="setting-container-input">
				<EitjeButton width="full" modalLink={to} t={btnText} />
			</div>
		</div>
	)
}

export const AvSettings = () => {
	const {envId} = useShared()
	return (
		<div className="border-bottom">
			<h3 style={{marginBottom: 8}}>{t('settings_schedule_availability')}</h3>
			<BaseSettingsModalRow btnText={'settings'} to={`/environments/${envId}/planning/settings`} label={t('planning.settings.name')} />
			<BaseSettingsModalRow btnText={'settings'} to={`/availability/${envId}/settings`} label={t('availSettings')} />
			<BaseSettingsModalRow btnText={'availPeriodSettings'} to={`/availability/${envId}/settings/period`} label={t('configureAvPeriod')} />
			<BaseSettingsModalRow btnText={'autoLockSettings'} to={`/availability/${envId}/team_days`} label={t('autoLockTemplateExplained')} />
		</div>
	)
}

export const TimeRegSettings = () => {
	const {envId} = useShared()

	return (
		<div className="time-reg-settings">
			<BaseSettingsModalRow label={t('time_registration.settings')} to={`/time_registration/${envId}/settings`} btnText={'settings'} />
		</div>
	)
}
