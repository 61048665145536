// const importModules = require('import-modules');
// importModules("initializers")
import {history} from 'initializers/history'
import 'initializers/why_did_you_render'
import 'initializers/sentry'
import 'initializers/colors'
import 'initializers/object_patches'
import 'initializers/array_patches'
import 'initializers/number_patches'
import 'initializers/string_patches'
import 'initializers/string'
import 'initializers/lodash'
import 'initializers/form'
import 'initializers/i18n'
import 'initializers/date'
import 'initializers/sizes'
import 'initializers/intersection_observer_polyfill'
import 'initializers/resize_observer_polyfill'
import 'initializers/console'
import 'initializers/web_utils'
import 'initializers/log'
import 'initializers/web_components'
import {scrollFromTop} from 'actions/routing'
import usePrevious from 'hooks/use_previous'
import {ENV} from 'initializers/api'
import {useSelector} from 'react-redux'
import {init} from 'initializers/eitje_api'
import React, {Fragment, useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import {Provider} from 'react-redux'
import {Router, useHistory, useLocation} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import App from './App'
import './index.css'
import createStore from './store'
import useOnboardingHeader from 'cores/onboarding/hooks/use_onboarding_header'
import utils from '@eitje/web_utils'
import {updateIntercom} from 'lib/intercom'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {DndProvider} from 'react-dnd'
import useScroll from 'hooks/use_scroll'
import {useClassNameObserver} from 'hooks'
import OuterErrorBoundary from 'components/outer_error_boundary'

export const {store, persistor} = createStore()
export {history}
export const persist = persistor
init()

const scrollTopRoutes = ['/news']

const routeMatch = routes => {
	const pn = window.location.pathname
	return routes.includes(pn)
}

const TopScroll = ({children}) => {
	const loc = useLocation()
	const onboardingHeaderVisible = useOnboardingHeader()
	const [scrollTopV, setScrollTopV] = useState(false)

	const handleScroll = () => {
		setScrollTopV(scrollFromTop() > 600)
	}

	const element = document.querySelector('.page-body')

	useEffect(() => {
		updateIntercom()
		setScrollTopV(false)
		ENV === 'production' && ReactGA.pageview(loc.pathname + loc.search)
	}, [loc])

	const classNames = utils.makeCns(
		onboardingHeaderVisible && 'has-onboarding-header',
		'scroll-to-top',
		'fRow',
		'border',
		'aCen',
		'jCen',
		'scrollToTop',
		'pointer',
	)

	return (
		<Fragment>
			{scrollTopV && (
				<div
					onClick={() => {
						element?.scrollTo({top: 0})
					}}
					className={classNames}
					style={{
						width: 32,
						height: 32,
						borderRadius: 8,
						left: 'calc(50% - 375px)',
						zIndex: 1000,
						position: 'fixed',
						backgroundColor: 'white',
						boxShadow: '0px 2px 8px 0px #aaaaaa25',
					}}
				>
					<img src="/images/scrollToTop.png" style={{height: 36, width: 36, objectFit: 'cover'}} />
				</div>
			)}

			{routeMatch(scrollTopRoutes) && <ScrollTopWatcher handleScroll={handleScroll} />}

			{children}
		</Fragment>
	)
}

const ScrollTopWatcher = ({handleScroll}) => {
	useClassNameObserver('page-body')
	useScroll(handleScroll)
	return null
}

const Effects = () => {
	const loc = useLocation()
	const prevLoc = usePrevious(loc)

	const errors = useSelector(state => state.recordErrors.errors)
	useEffect(() => {
		const isModalRoute = !!loc?.state?.background || !!prevLoc?.state?.background
		if (!isModalRoute && prevLoc) {
			utils.exists(errors) && store.dispatch({type: 'CLEAR_RECORD_ERRORS'})
		}
	}, [loc])

	return null
}

const render = () => {
	return ReactDOM.render(
		<OuterErrorBoundary>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					{/* <AppContainer> */}
					<Router history={history}>
						<TopScroll>
							<DndProvider backend={HTML5Backend}>
								<App />
							</DndProvider>
						</TopScroll>
						<Effects />
					</Router>
					{/* </AppContainer> */}
				</PersistGate>
			</Provider>
		</OuterErrorBoundary>,
		document.getElementById('root'),
	)
}

window.onload = () => {
	render()
}

if (module.hot) {
	module.hot.accept('./App', () => {
		render()
	})
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
