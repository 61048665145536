import {EitjeButton as _EitjeButton} from '@eitje/web_components'
import {LinkButton, ModalLinkBtn} from 'components/common'
import {ExternalLinkButton} from './eitje_buttons'

export const EitjeButton = props => {
	let {modalLink, link, externalLink, style} = props
	const linkStyles = {width: props.width?.includes?.('full') && '100%', ...style}

	if (modalLink) return <ModalLinkBtn {...props} style={linkStyles} />
	if (link) return <LinkButton {...props} style={linkStyles} />
	if (externalLink) return <ExternalLinkButton {...props} style={linkStyles} />

	return <_EitjeButton {...props} />
}

export default EitjeButton
