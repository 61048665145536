import {Text} from 'common/components'

export * from './checkbox'

export const TableText = ({isHeader, ...rest}) => {
	return <Text className="advanced-table-text" truncate darkGrey={isHeader} {...rest} />
}

export const BasicCell = ({getValue}) => getValue() || null

export const CellPlaceholder = () => null
