import I18n, {t} from 'initializers/i18n'
import {RawDatePicker, TimePicker} from '@eitje/form-fields-web'
import {DefaultListPickerTrigger} from '@eitje/web_components'
import {NewFormTrigger} from 'lib/form/fields/trigger'
import {useFormData} from 'hooks'
import {date} from 'initializers/date'
import utils from '@eitje/web_utils'
import {ListPicker} from 'common/components'
import moment from 'moment'
import './styles/export_period_picker.less'

const makeItems = items => items.map(i => ({name: t(`common.${i}`), id: i}))
const periods = ['day', 'week', 'month', 'flex_wage_period', 'hours_wage_period', 'quarter', 'year']
const freqProps = {
	single: true,
	noSort: true,
}

const weekdayProps = {
	items: utils.weekdayObj,
	single: true,
	noSort: true,
}

const getOrdinal = num => moment.localeData(I18n.language).ordinal(num)

export const monthdayFormObj = () => [...Array(28).keys()].map(i => ({id: i + 1, name: getOrdinal(i + 1)})) // days start at 1

export const quarterFormObj = () => [...Array(90).keys()].map(i => ({id: i + 1, name: getOrdinal(i + 1)}))

const sharedProps = {label: null, placeholder: '...', required: true, dropdown: true, trigger: 'hover', icon: null}

const buildMonthTriggerLabel = ({value}) => `${moment.localeData().ordinal(value)} ${t('day')}`

const TestTimePicker = props => <TimePicker {...props} raw />

const SentenceTrigger = props => <DefaultListPickerTrigger {...props} bold />

export const ExportPeriodPicker = () => {
	const quarterProps = {
		items: quarterFormObj(),
		single: true,
		noSort: true,
	}

	const monthdayProps = {
		items: monthdayFormObj(),
		single: true,
		noSort: true,
	}

	const periodItems = makeItems(periods)

	const {cron_period, day_of_week, day_of_month, day_of_year} = useFormData()
	const showAt = cron_period == 'day' || utils.exists(day_of_week) || day_of_month || day_of_year

	const isPeriod = cron_period == 'flex_wage_period' || cron_period == 'hours_wage_period'

	return (
		<div className="exports-subscription-frequency-picker">
			<h5 className="exports-subscription-picker-label"> {t('form.export_subscriptions.fields.cron_period.label')} </h5>
			<div className="exports-subscription-picker-sentence">
				<span> {t('common.every')} </span>
				<ListPicker
					{...sharedProps}
					{...freqProps}
					field="cron_period"
					items={periodItems}
					label={null}
					placeholder={t('exports.placeholders.select_period')}
					required
					small
					title={({value}) => value && t(`common.${value}`)}
					Trigger={SentenceTrigger}
				/>

				{cron_period == 'week' && (
					<ListPicker
						{...sharedProps}
						{...weekdayProps}
						field="day_of_week"
						small
						title={({value}) => {
							const val = value + 1
							return date().day(val).format('dddd')
						}}
						Trigger={SentenceTrigger}
					/>
				)}

				{cron_period == 'month' && (
					<ListPicker
						{...sharedProps}
						{...monthdayProps}
						field="day_of_month"
						small
						title={buildMonthTriggerLabel}
						Trigger={SentenceTrigger}
					/>
				)}

				{cron_period == 'quarter' && (
					<ListPicker
						{...sharedProps}
						{...quarterProps}
						title={buildMonthTriggerLabel}
						field="day_of_quarter"
						small
						Trigger={SentenceTrigger}
					/>
				)}

				{cron_period == 'year' && (
					<NewFormTrigger
						{...sharedProps}
						buildTriggerLabel={value => `${date(value).format('DD-MM')}`}
						field="day_of_year"
						Element={RawDatePicker}
						elementProps={{...weekdayProps, dropdownClassName: 'cron_period-sentence-date-picker'}}
					/>
				)}

				{isPeriod && (
					<ListPicker
						{...sharedProps}
						{...monthdayProps}
						title={buildMonthTriggerLabel}
						field="day_of_period"
						small
						Trigger={SentenceTrigger}
					/>
				)}

				{/*{isPeriod && <span> {t('common.after_end_of_period')}, </span>}*/}

				{showAt && (
					<>
						<span> {t('common.at')} </span>
						<NewFormTrigger placeholder="..." required field="time" Element={TestTimePicker} visible />
					</>
				)}
			</div>
		</div>
	)
}
