import {TableText} from 'common/components/advanced_table'
import {PopoutCard} from '@eitje/web_components'

const formatItems = items => items.join(', ')

export const ShowMoreTooltip = ({items, showAmount = 1}) => {
	if (items.length <= showAmount) return <TableText>{formatItems(items)}</TableText>

	return (
		<PopoutCard
			className="show-more"
			title={formatItems(items)}
			layoutProps={{width: '100%', overflow: 'hidden'}}
			wrapperLayoutProps={{width: '100%'}}
		>
			{items.length}
		</PopoutCard>
	)
}
