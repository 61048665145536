import {chatClient, i18n, usersWithoutOneToOne} from 'actions/chat'
import useAsyncEffect from 'hooks/use_async_effect'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {usersSelector} from 'selectors/entities'
import {Chat} from 'stream-chat-react'
import {ChatStateContext} from '../providers/chat_provider'
import ChannelContainer from './containers/ChannelContainer'

const ChatClientWrapper = props => {
	const userIDString = useSelector(state => state.auth.user.id)
	const users = useSelector(usersSelector)
	const usersWithoutUser = users.filter(user => user.id !== userIDString).map(user => user.id)
	const {channelFilter, isChatDropdown, modular, setOneToOneUsers} = useContext(ChatStateContext)

	useAsyncEffect(async () => {
		if (userIDString) {
			const users = await usersWithoutOneToOne(usersWithoutUser, userIDString)
			setOneToOneUsers(users)
		}
	}, [channelFilter])

	return (
		<Chat client={chatClient} i18nInstance={i18n}>
			{(isChatDropdown || !modular) && <ChannelContainer />}
			{props.children}
		</Chat>
	)
}

export default ChatClientWrapper
