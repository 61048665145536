import {useState} from 'react'
import {mdT, t} from 'initializers/i18n'
import {ContextForm} from '@eitje/form'
import {Input, Switch} from '@eitje/form-fields-web'
import {EitjeModal} from 'components/common'
import FormRow from 'components/form_row'
import {useFormData, useParams} from 'hooks'
import {Team} from 'models'
import ChatImagePicker from 'lib/form/fields/chat_image_picker'
import {newImagePicker as ImagePicker} from 'lib/form/fields/image_picker'
import {finishSubStep} from 'actions/onboarding'
import {history} from 'index'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'
import {EitjeButton, EitjeDropdown, TeamColorPicker} from 'common/components'
import './styles/team_form.less'

const mapping = [
	{
		translation: 'selectStdAvatar',
		parameter: 'default',
	},
	{
		translation: 'selectAttachDesktop',
		parameter: 'remote',
	},
]

const FormBody = ({team}) => {
	const def = team?.avatar ? 'remote' : 'default'
	const [imgVisible, setImgVisible] = useState(def)
	const {chatgroep_actief, default_avatar} = useFormData()

	const elements = mapping.map(el => <div onClick={() => setImgVisible(el.parameter)}>{t(el.translation)}</div>)

	const dropdownButton = (!team.id || !team.avatar) && (
		<div className="team-avatar-dropdown">
			<EitjeDropdown elements={elements}>
				<EitjeButton t="teamAvatar" />
			</EitjeDropdown>
		</div>
	)

	return (
		<>
			<FormRow>
				<Input required field="naam" />
				<TeamColorPicker />
			</FormRow>
			<FormRow>
				<Switch label={t('team.team_settings.active_schedule.label')} field="schedulable" />
				<Switch field="chatgroep_actief" />
			</FormRow>
			{chatgroep_actief && (
				<FormRow>
					{imgVisible == 'default' && <ChatImagePicker label={t('selectStdAvatar')} field="default_avatar" icon={null} />}
					{imgVisible == 'remote' && <ImagePicker icon={null} required={!!chatgroep_actief && !default_avatar} field="remote_avatar_url" />}
					{dropdownButton}
				</FormRow>
			)}
		</>
	)
}

const TeamForm = () => {
	const {id, envId} = useParams()
	const team = Team.find(id)
	const teams = useSelector(state => inEnv(state, 'teams'))

	const handleSubmit = data => Team.create({...data, id, onroosterbaar: !data.schedulable, environment_id: team.environment_id || envId})

	const afterSubmit = res => {
		finishSubStep('teammembers', 'team')
		const backgroundRouteIsTeams = history?.location.state?.background.pathname.includes('/teams')
		backgroundRouteIsTeams ? history.replace(`/teams/${res.item.id}`) : history.goBack()
	}

	const onDel = () => {
		Team.destroy(id)
		history.goBack()
	}

	return (
		<ContextForm
			onSubmit={handleSubmit}
			afterSubmit={afterSubmit}
			initialValues={{
				...team,
				schedulable: !team.onroosterbaar,
				color: team.color || '#E8F5FF',
				remote_avatar_url: team.avatar,
			}}
			transNamespace="team_settings"
		>
			<EitjeModal name="team_settings" form onDel={id && onDel} className="eitje-form-2 eitje-form-2-use-borders">
				{teams.length === 1 && (
					<div className="flex bg5 borderBot pad24">
						<h5 style={{textAlign: 'center'}}>{mdT('firstTeamMessage')}</h5>
					</div>
				)}
				<FormBody team={team} />
			</EitjeModal>
		</ContextForm>
	)
}

export default TeamForm
