import React from 'react'
import {t as baseT} from 'initializers/i18n'
import {usePeriods} from 'cores/time_registration/index'
import EitjeButton from 'common/components/eitje_button'
import {Text, ListPicker, Layout} from 'common/components'
import './styles.less'

export const PeriodPicker = ({onChange, height}) => {
	const {periods, period = {}} = usePeriods()
	const orderedPeriods = periods.reverse()
	const periodIdx = orderedPeriods.indexOf(period)

	const _onChange = periodId => {
		const _period = periods.find(p => p.id == periodId)
		return onChange(_period.start, _period.eind)
	}

	const decrementDate = () => {
		const period = periods[periodIdx + 1]
		if (period) return _onChange(period.id)
	}

	const incrementDate = () => {
		const period = periods[periodIdx - 1]
		if (period) return _onChange(period.id)
	}

	const isFirstPeriod = periodIdx == periods.length - 1
	const isLastPeriod = periodIdx == 0
	return (
		<Layout gap={0} className="hours-period-picker">
			{!isFirstPeriod && <EitjeButton height={height} iconLeft="arrow-small-left" padding={4} onClick={decrementDate} />}
			<ListPicker
				raw
				dropdown
				single
				onChange={_onChange}
				noSort
				small={height === 'small'}
				value={period.id}
				items={orderedPeriods}
				Trigger={PeriodTrigger}
				triggerVisible
			/>
			{!isLastPeriod && <EitjeButton height={height} iconLeft="arrow-small-right" padding={4} onClick={incrementDate} />}
		</Layout>
	)
}

const PADDING = [4, 8]

const PeriodTrigger = ({selectedItems, small}) => {
	const item = selectedItems[0]
	return (
		<Layout hasHover colorSet gap={8} padding={PADDING} type="card" height={small ? __SMALL_BUTTON_HEIGHT : __LARGE_BUTTON_HEIGHT}>
			<Text>
				{baseT('common.period')} {item.period}
			</Text>
		</Layout>
	)
}

export default PeriodPicker
