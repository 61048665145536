import {checkAuth} from 'actions/auth'
import {useSelector} from 'react-redux'
import {Redirect, Route as BaseRoute} from 'react-router-dom'
import {getEnvSetting} from 'selectors/new_settings'
import {useHasRoleInAnyEnv, useHasRole} from 'hooks'

export const Route = props => {
	const {role, setting, publicRoute} = props
	if (role || setting) return <PrivateRoute {...props} />
	if (publicRoute) return <PublicRoute {...props} />
	return <PrivateRoute {...props} />
}

export const PrivateRoute = ({component: Component, ...rest}) => {
	const hasAuth = useSelector(state => !!state.auth.refresh_token)
	if (hasAuth) return <BaseRoute {...rest} component={Component} />
	return <Redirect to="/login" />
}

export const ProtectedRoute = ({component: Component, fbPath, role, roleInAnyEnv, setting = {}, ...rest}) => {
	let hasSetting = useSelector(state => getEnvSetting(state, setting.key, setting.innerKey))
	const noRolesGiven = !role && !roleInAnyEnv
	let hasRoleInCurrentEnv = useHasRole(role)
	let hasRoleInAnyEnv = useHasRoleInAnyEnv(roleInAnyEnv)
	const mayPass = (noRolesGiven || hasRoleInCurrentEnv || hasRoleInAnyEnv) && (!setting?.innerKey || !!hasSetting)

	return (
		<BaseRoute
			{...rest}
			render={props => {
				if (!checkAuth()) {
					return <Redirect to="/login" />
				} else if (!mayPass) {
					return <Redirect to={fbPath || '/news'} />
				} else {
					return <Component {...props} />
				}
			}}
		/>
	)
}

export const PublicRoute = ({component: Component, ...rest}) => (
	<BaseRoute
		{...rest}
		render={props => {
			const hasAuth = checkAuth()
			return !hasAuth ? <Component {...props} /> : <Redirect to="/news" />
		}}
	/>
)
