import {t} from 'initializers/i18n'
import React, {useContext} from 'react'
import {ChatStateContext} from '../providers/chat_provider'
import {Tabs} from 'common/components'

const typeOfChannels = [
	{name: t('all_single'), id: 'all'},
	{name: t('team_title'), id: 'team'},
	{name: t('personal'), id: 'personal'},
]

const ChannelTypeSelector = () => {
	const {setChannelFilter, setSearchUserQuery, channelFilter} = useContext(ChatStateContext)
	return (
		<Tabs
			items={typeOfChannels}
			raw
			value={channelFilter}
			onChange={value => {
				setSearchUserQuery([])
				setChannelFilter(value)
			}}
		/>
	)
}

export default ChannelTypeSelector
