import {backend} from '@eitje/easy_api'
import {history, store} from 'index'
import {isChrome} from 'react-device-detect'

export async function saveOneSignalId(userId, id) {
	const res = await backend.post(`users/${userId}/device`, {
		uuid: id,
		doNotLoad: true,
	})
	if (res.status === 302) {
		store.dispatch({type: 'ONE_SIGNAL_DONE'})
	}
}

export const notificationRouting = data => {
	switch (data.type) {
		case 'chat':
			store.dispatch({type: 'OPEN_TO_ME', openToMe: data.channel_id})
			return history.push('/chat')
		case 'nieuws':
			return history.push('/news')
		case 'shift':
			return history.push('/planning')
	}
	return
}

export const handleUserOneSignalDisconnent = () => {
	window.OneSignal.push(['setSubscription', false]) // unsubscribes but still allows for us to subscribe without getting browser permission again
	store.dispatch({type: 'ONE_SIGNAL_ID', id: null})
}

export const displayNoticationPrompt = () => {
	return isChrome ? window.OneSignal.showNativePrompt() : window.OneSignal.showSlidedownPrompt()
}
