import {all, backend} from '@eitje/easy_api'
import {Select, Spin} from 'antd'
import VloerSettings from 'components/vloer_settings'
import {searchProps} from 'helpers/search'
import {t} from 'initializers/i18n'
import Settings from 'pages/v3/settings/settings'
import OrgSettings from 'pages/v3/settings/organisation'
import React from 'react'
import {connect} from 'react-redux'
import {envTeamsSelector} from 'selectors/entities'
import {legacyRolesSelector} from 'selectors/legacy'
import {currentOrgSelector} from 'selectors/records'
import {getEnvSetting} from 'selectors/settings'
import {Link} from 'components/routing'
import 'styles/legacy/manual.scoped.css'
import {Layout, PageLayout} from 'common/components'

const RolSelect = ({rol, allUsers, onC, submit}) => (
	<div className="veetwee teamledenToevoegen" style={{marginTop: 24, marginBottom: 40}}>
		<h3 style={{marginBottom: '4px'}}>{t(`role_${rol.naam}`)}</h3>
		<p style={{fontSize: 10}}>{t(`role_${rol.naam}_uitleg`)}</p>
		<div className="teamledenToevoegen instellingen">
			<Select
				mode="multiple"
				style={{width: '100%'}}
				placeholder={t('selectMember')}
				onChange={ids => onC(ids, rol)}
				value={rol.ids}
				{...searchProps}
			>
				{allUsers.map(user => (
					<Select.Option key={user.id}>{user.full_name}</Select.Option>
				))}
			</Select>
		</div>
	</div>
)

class Bedrijf extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			roleUsers: props.roleUsers,
			route: 'setting',
			allowances: props.allowances,
			holidays: props.holidays,
			chosenHolidays: props.chosen_holidays,
		}
		this.alterObj = this.alterObj.bind(this)
		this.submit = this.submit.bind(this)
	}

	alterObj(ids, rol) {
		const newObj = {...this.state.roleUsers}
		newObj[rol.naam].ids = ids
		this.setState({roleUsers: newObj}, () => this.submit(rol.naam))
	}

	handleE(e) {
		alert(e)
	}

	loadSettings = async () => {
		const res = await backend.get(`web_settings`)
		if (res.ok) {
			this.setState({...res.data, chosenHolidays: res.data.chosen_holidays})
		}
	}

	componentDidMount() {
		this.loadSettings()
	}

	async submit(naam) {
		const {host} = this.props
		const res = await backend.patch(`permissions/update_role`, {
			roleUsers: this.state.roleUsers[naam],
		})
	}

	render() {
		const {allUsers, settings, groep_done, streef_done, n_u_done, payroll_done, uren_done, maaltijdOpts, maaltijdSelected} = this.state
		const {roles, org, envId, koppeling} = this.props
		const {roleUsers, route} = this.state

		const header = <Layout initialBackground={Colors.white} relative height={56} borderBottom width="100%" />

		return (
			<PageLayout name="settings" headers={header} bodyLayoutProps={{block: true}}>
				{settings ? (
					<div>
						<div class="leftContainer floatL" style={{borderRight: '1px solid #eee'}}>
							<div className="handboek veedree">
								{roles['admin'] && (
									<div className="cardHandboek" onClick={() => this.setState({route: 'setting'})}>
										<div style={{backgroundColor: route == 'setting' && '#e8f5ff'}} className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('compSett')}</p>
											<p style={{fontSize: 10}}>{t('compSett2')}</p>
										</div>
									</div>
								)}

								{org.id && roles['admin'] && (
									<div className="cardHandboek" onClick={() => this.setState({route: 'organisation'})}>
										<div style={{backgroundColor: route == 'organisation' && '#e8f5ff'}} className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('orgSett')}</p>
											<p style={{fontSize: 10}}>{t('orgSett2')}</p>
										</div>
									</div>
								)}

								{roles['admin'] && (
									<div className="cardHandboek" onClick={() => this.setState({route: 'vloer'})}>
										<div style={{backgroundColor: route == 'vloer' && '#e8f5ff'}} className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('eitjeopdevloer')}</p>
											<p style={{fontSize: 10}}>{t('eitjeopdevloer2')}</p>
										</div>
									</div>
								)}

								{roles['admin'] && (
									<div className="cardHandboek" onClick={() => this.setState({route: 'root'})}>
										<div style={{backgroundColor: route == 'root' && '#e8f5ff'}} className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('roles')}</p>
											<p style={{fontSize: 10}}>{t('roles2')}</p>
										</div>
									</div>
								)}

								{roles['admin'] && (
									<Link to="/integrations/my" className="cardHandboek">
										<div className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('common.integration', {count: 2})}</p>
											<p style={{fontSize: 10}}>{t('integration2')}</p>
										</div>
									</Link>
								)}

								{roles['admin'] && (
									<Link to="/billing/invoices" className="cardHandboek">
										<div style={{backgroundColor: route == 'invoices' && '#e8f5ff'}} className="paddLarge">
											<p style={{fontWeight: '600'}}>{t('common.invoice_overview')}</p>
											<p style={{fontSize: 10}}>{t('common.invoice_overview_subtext')}</p>
										</div>
									</Link>
								)}
							</div>
						</div>
						{route == 'invoices' ? (
							<Invoices />
						) : (
							<div class="containerShow" style={{marginTop: 40, paddingBottom: 80}}>
								{route == 'organisation' ? (
									<OrgSettings />
								) : route == 'vloer' ? (
									<VloerSettings envId={envId} />
								) : route == 'root' ? (
									Object.keys(roleUsers).map(rol => (
										<RolSelect submit={() => this.submit(rol)} onC={this.alterObj} allUsers={allUsers} rol={roleUsers[rol]} />
									))
								) : (
									<Settings
										{...this.state}
										chosenHolidays={this.state.chosenHolidays}
										allowances={this.state.allowances}
										parent={this}
										koppeling={koppeling}
										place={this.state.place}
										maaltijdOpts={maaltijdOpts}
										maaltijdSelected={maaltijdSelected}
										n_u_done={n_u_done}
										uren_done={uren_done}
										payroll_done={payroll_done}
										load={this.loadSettings}
										groep_done={groep_done}
										streef_done={streef_done}
										settings={settings}
									/>
								)}
							</div>
						)}
					</div>
				) : (
					<div>
						<Spin style={{position: 'absolute', top: 60, left: '50%'}} spinning={true}></Spin>
					</div>
				)}
			</PageLayout>
		)
	}
}

export default connect(state => ({
	envId: state.environment.active,
	users: all(state, 'users'),
	user: state.auth.user,
	roles: legacyRolesSelector(state),
	teams: envTeamsSelector(state),
	org: currentOrgSelector(state),
	koppeling: getEnvSetting(state, 'uren_reg', 'koppeling'),
}))(Bedrijf)
