import {useForm, NewForm} from '@eitje/form'
import {useModalContext} from 'components/common/index'
import {FormSubmitButtonInner} from 'components/form'
import {useEffect} from 'react'
import {EitjeModal} from 'components/common'

const Inner = ({modalProps = {}}) => {
	const {okButtonProps = {}, ...rest} = modalProps
	const {setModalPropsRaw} = useModalContext()
	const {submit, formTouched, getData, form, ...formRest} = useForm()
	useEffect(() => {
		setModalPropsRaw({
			form: true,
			SubmitButtonOuter: FormSubmitButtonInner,
			okButtonProps: {...okButtonProps, form, submit, formTouched, getData},
			...rest,
		})
	}, [])

	return null
}

export const ModalWithNewForm = ({name, modalProps, ...props}) => {
	return (
		<EitjeModal name={name} {...modalProps} form>
			<NewModalForm {...props} modalProps={modalProps} />
		</EitjeModal>
	)
}

export const NewModalForm = ({children, modalProps, ...props}) => {
	const {name} = useModalContext()

	return (
		<NewForm name={name} {...props}>
			<Inner modalProps={modalProps} />
			{children}
		</NewForm>
	)
}
