import {Affix as AntAffix} from 'antd'
import useOnboardingHeader from 'cores/onboarding/hooks/use_onboarding_header'

const onboardingHeaderHeight = 48

export const Affix = ({offsetTop = 0, ...rest}) => {
	const headerVisible = useOnboardingHeader()
	let _offsetTop = offsetTop
	if (headerVisible) _offsetTop += onboardingHeaderHeight // 48
	return <AntAffix offsetTop={_offsetTop} {...rest} />
}

export default Affix
