import useSelector from 'hooks/use_selector'
import utils from '@eitje/web_utils'
import {figureOutActive, isDone, makeSubStep} from 'actions/onboarding'

const useOnboarding = () => {
	const steps = useSelector(state => state.settings.taxonomy?.onboarding_steps)
	const activeStep = figureOutActive(steps)
	const stepKeys = Object.keys(steps)
	const doneSteps = Object.values(steps).filter(s => isDone(s))
	const stepIdx = stepKeys.indexOf(activeStep)

	const stepPct = utils.pct({total: stepKeys.length, active: doneSteps.length})
	return {steps, activeStep, stepIdx, stepPct}
}

export default useOnboarding
