import _ from 'lodash'
import {date} from 'initializers/date'
import * as jsUtils from 'utils/jsutils'

const mmt = date()

const initialState = {
	standardDays: [],
	standardTeamDays: [],
	accordShifts: [],
	ignoredAccordShifts: [],
	accordErrors: {},
	verlofVerzoeken: [],
	accordOpt: 3,
	loading: false,
	shifts: [],
	avUsers: [],
	days: [],
	week: mmt.isoWeek(),
	year: mmt.isoWeekYear(),
	shiftModalOpen: false,
	modalShift: {},
	modalDay: {},
	modalTeam: {},
	standardWorkweeks: [],
	workweeks: [],
	planningLoading: false,
	events: [],
	teamDays: [],
	plusMinUsers: {},
	verlofSaldo: {},
	popoverUser: null,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'LOGOUT':
			return initialState

		case 'SHIFTS_LOADING':
			return {
				...state,
				loading: true,
			}

		case 'TOGGLE_PLANNING_POPOVER':
			return {
				...state,
				popoverUser: action.id,
			}

		case 'GET_PLUS_MIN':
			return {
				...state,
				plusMinUsers: action.data,
			}

		case 'GET_VERLOF_SALDO':
			return {
				...state,
				verlofSaldo: action.data,
			}

		case 'PLANNING_LOADING':
			return {
				...state,
				planningLoading: true,
			}

		case 'PLANNING_DONE':
			return {
				...state,
				planningLoading: false,
			}

		case 'OPEN_SHIFT_MODAL': {
			return {
				...state,
				shiftModalOpen: true,
				modalShift: action.shift,
				modalTeam: action.team,
				modalDay: action.day,
			}
		}

		case 'CLOSE_SHIFT_MODAL': {
			return {
				...state,
				shiftModalOpen: false,
				modalShift: {},
				modalTeam: {},
				modalDay: {},
			}
		}

		case 'UPD_PLAN_SHIFT':
			return {
				...state,
				shifts: jsUtils.findAndReplace(state.shifts, action.shift),
			}

		case 'GET_BESCH_PLANNING':
			return {
				...state,
				shifts: action.shifts,
				days: action.days,
				avUsers: action.avUsers,
				teamDays: action.teamDays,
			}

		case 'GET_PLANNING':
			return {
				...state,
				avUsers: action.avUsers,
				shifts: action.shifts,
				days: action.days,
				workweeks: action.workweeks,
				week: action.week,
				year: action.year,
				events: action.events,
				teamDays: action.teamDays,
				planningLoading: false,
			}

		case 'UPDATE_DAYS':
			return {
				...state,
				days: action.days,
			}

		case 'GET_DAYS':
			return {
				...state,
				days: action.days,
			}

		case 'SHIFTS_LOADED':
			return {
				...state,
				loading: false,
			}

		case 'SET_ACCORD_OPT':
			return {
				...state,
				accordOpt: action.accordOpt,
			}

		case 'GET_VERLOF':
			return {
				...state,
				verlofVerzoeken: action.verzoeken,
			}

		case 'SET_ACCORD_ERRORS':
			return {
				...state,
				accordErrors: action.errors,
			}

		case 'GET_SHIFTS':
			const s = action.shifts.filter(s => s.reg && s.published) || []
			const ign = state.ignored || []
			return {
				...state,
				accordShifts: action.shifts,
				ignoredAccordShifts: _.uniq([...ign, ...s.map(s => s.id)]),
			}

		case 'SAVE_SHIFT':
			return {
				...state,
				accordShifts: state.accordShifts.map(s => (s.id == action.shift.id ? action.shift : s)),
			}

		case 'SET_IGNORED_ACC_SHIFTS':
			return {
				...state,
				ignoredAccordShifts: action.ignored,
			}

		case 'TOGGLE_ACCORD_SHIFT':
			return {
				...state,
				ignoredAccordShifts: jsUtils.addOrRemove(state.ignoredAccordShifts, action.shiftId),
			}

		case 'SET_PLANNING_WEEK':
			return {
				...state,
				week: action.week,
				year: action.year,
			}

		case 'STANDARD_DAYS':
			return {
				...state,
				standardDays: action.days,
				standardTeamDays: action.team_days,
				standardWorkweeks: action.wwks,
			}
			break

		default:
			return state
	}
}
