import {EitjeButton, Layout, Text} from 'common/components'
import {ColumnPicker} from 'common/components/advanced_table'

const inlineStyles = {position: 'sticky', display: 'inline-flex', top: 0}

export const AdvancedTableShowMore = ({height, ...rest}) => {
	return (
		<Layout
			className="advanced-table-show-more"
			width={200}
			direction="vertical"
			vertical="center"
			horizontal="center"
			height={height}
			gap={16}
			style={inlineStyles}
		>
			<Text bold t="table.show_more_data" />
			<ColumnPicker {...rest} Trigger={ShowMoreTrigger} />
		</Layout>
	)
}

const ShowMoreTrigger = () => <EitjeButton t="common.column_plural" iconLeft="plus" colorSet="solid" />
