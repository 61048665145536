const initialState = {
	startDate: null,
	endDate: null,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'SET_PLANNING_DATES':
			return {
				...state,
				startDate: action.startDate,
				endDate: action.endDate,
			}
		default:
			return state
	}
}
