import utils from '@eitje/utils'
import {INITIAL_ADVANCED_TABLE_STATE} from 'common/components/advanced_table'

const initialState = {
	advancedTable: {},
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'ADVANCED_TABLE': {
			const {tableName, value, name} = action
			const otherTables = state.advancedTable
			const oldState = state.advancedTable[tableName] || INITIAL_ADVANCED_TABLE_STATE
			const newState = {...oldState, ...action.value}

			return {
				...state,
				advancedTable: {
					...otherTables,
					[tableName]: newState,
				},
			}
		}

		default:
			return state
	}
}
