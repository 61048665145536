import {makeCnVariants} from 'helpers'
import {PopoutPicker} from './popout_picker'
import './styles/color_picker.less'

const ColorBox = ({value: color, onChange, active, small}) => {
	const classNames = makeCnVariants('color-picker-box', small && 'small')
	return (
		<div className={classNames} style={{backgroundColor: color}} onClick={() => onChange?.(color)} key={color}>
			{active && <img className="color-picker-checkmark" src={`/images/web/icons/checkedIcon.png`} />}
		</div>
	)
}

const SelectedEl = props => (
	<div className="color-picker-trigger">
		{<ColorBox value={props.value} small />}
		<img className="color-picker-arrow-down" src="/images/downIcon.svg" />
	</div>
)

const ColorPicker = ({colors, field = 'color', ...rest}) => {
	return (
		<PopoutPicker
			Item={ColorBox}
			SelectedEl={SelectedEl}
			items={colors}
			popoutContainerClass="color-picker-popout"
			field={field}
			{...rest}
		/>
	)
}

export default ColorPicker
