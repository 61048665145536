import React, {useEffect} from 'react'
import {date} from 'initializers/date'
import {t, mdT} from 'initializers/i18n'
import {API, useFind} from '@eitje/easy_api'
import {ContextForm} from '@eitje/form'
import Modal from 'components/general/modal'
import utils from '@eitje/web_utils'
import {RedirectBack} from 'components/routing'
import {history} from 'index'
import {ShiftType, TimeRegistrationShift} from 'models/index'
import Header from './header'
import Body from './body/index'
import UserPicker from './user_picker'
import Footer from './footer'
import {useTeamAvUsers, buildPlanningShift} from 'cores/planning/index'
import {useForm} from '@eitje/form'
import {UserCard} from 'components/shared/index'
import {getTimeShiftUrl, ShiftTimeBlock} from 'cores/time_registration/index'
import {useFormData, useHasRole, usePrevious, useRoleOrgEnvs} from 'hooks/index'
import './styles/index.less'
import './styles/form.less'
import useShared from 'hooks/use_shared'
import User from 'models/user'
import {contractJoins as opts} from 'constants/users'
import {DocsButton, EitjeButton, Message, Layout} from 'common/components'

const PlanningShiftForm = ({title, shift}) => {
	const {setValues} = useForm()
	const {team_id, user_id, env_id, dates = [], ...rest} = useFormData()
	const localFormShift = buildPlanningShift(rest)
	const environment = useFind('environments', env_id)
	const prevEnv = usePrevious(environment)
	const team = useFind('teams', team_id)
	const user = User.where([user_id], opts)?.[0] || {}

	const _date = date(dates[0])
	const users = useTeamAvUsers({team, date: _date, userId: shift?.user_id, shift: localFormShift})
	const hideUserPanel = !team_id || shift?.reg_shift_id || !utils.exists(dates)

	useEffect(() => {
		if (user_id) {
			setValues({amt_shifts: 1})
		}
	}, [user_id])

	useEffect(() => {
		if (!prevEnv?.id) return
		const userExistsInNewEnv = user.environment_ids?.includes(environment.id)
		const teamExistsinNewEnv = team.environment_id === environment.id
		setValues({maaltijd: undefined, user_id: userExistsInNewEnv ? user_id : null, team_id: teamExistsinNewEnv ? team_id : null})
	}, [environment.id])

	const managerOrgEnvs = useRoleOrgEnvs('manager')
	if (!managerOrgEnvs.length) return <RedirectBack />

	return (
		<div className="planning-shift-form">
			<Header shift={shift} title={title} hideUserPanel={hideUserPanel} />
			<Body env={environment} date={_date} shift={shift} user={user} hideUserPanel={hideUserPanel} />

			<UserPicker
				users={users}
				team={team}
				env={environment}
				user_id={user_id}
				hideUserPanel={hideUserPanel}
				placeholderProps={{docsId: null, videoId: null}}
			/>

			{shift?.reg_shift_id && <ShiftUneditable shift={shift} />}

			<Footer env={environment} shift={shift} localFormShift={localFormShift} />
		</div>
	)
}

const MARGIN = [8, 0]

const ShiftUneditable = ({shift}) => {
	const regShift = TimeRegistrationShift.find(shift.reg_shift_id)
	const user = useFind('users', regShift.user_id)
	return (
		<Layout className="shift-uneditable" direction="vertical" padding={16} gap={16} vertical="center">
			<div>
				<Message margin={MARGIN}>{mdT('planning.shift_modal.uneditable')}</Message>
				<DocsButton width="full" to="6209183" height="small" />
			</div>
			{user.id && <RegShift shift={regShift} user={user} />}
		</Layout>
	)
}

const RegShift = ({shift, user}) => {
	const team = useFind('teams', shift.team_id)
	const env = useFind('environments', team?.environment_id)
	const canWrite = useHasRole('uren_schrijven', env.id)
	return (
		<Layout width="full" direction="vertical" type="card" gap={8} padding={8}>
			<UserCard user={user} Subline={() => <ShiftSubline team={team} env={env} />} />
			<ShiftTimeBlock status="approved" shift={shift} />
			{canWrite && env.id && (
				<EitjeButton modalLink={getTimeShiftUrl({shift})} height="small" width="full">
					{t('common.view', {kind: 'time_registration.shift'})}
				</EitjeButton>
			)}
		</Layout>
	)
}

const ShiftSubline = ({team, env}) => {
	const str = env?.id ? `${env?.naam} - ${team?.naam}` : t('common.environment_unknown')
	return <p>{str}</p>
}

const savePlanningShifts = (data, shift_types) => {
	let shifts = []
	const {dates, amt_shifts} = data
	const portableData = _.omit(data, 'dates', 'amt_shifts')
	const shift_type_id = shift_types.ids().find(id => id == data.shift_type_id) || null
	const allowance_ids = [data.allowance_id].filter(Boolean).flat()

	dates.forEach(_date => {
		_(amt_shifts).times(i =>
			shifts.push({...portableData, date: _date, allowance_ids, start: portableData.from, end: portableData.till, shift_type_id}),
		)
	})
	return API.updateMulti('planning_shifts', shifts)
}

const Wrapper = ({initialValues = {}, ...props}) => {
	const {envId} = useShared()
	const {shift} = props
	const shift_types = ShiftType.all()
	return (
		<Modal simpleBack width={840} wrapClassName="shift-modal">
			<ContextForm
				fieldProps={{readOnly: shift?.reg_shift_id}}
				transNamespace="shift_form"
				afterSubmit={history.goBack}
				onSubmit={data => savePlanningShifts(data, shift_types)}
				className="eitje-form-2-use-borders"
				initialValues={{env_id: envId, amt_shifts: 1, pauze_duur: '00:00', typ: 'standaard', ...initialValues}}
			>
				<PlanningShiftForm {...props} />
			</ContextForm>
		</Modal>
	)
}

export default Wrapper
