import {useLocation} from 'react-router-dom'
import QueryString from 'query-string'
import _ from 'lodash'

const commaSeparatedNumbersRegex = /[0-9]+(,[0-9]+)*/

export const useQueryParams = (props = {}) => {
	const location = useLocation()
	const parsed = QueryString.parse(location.search)
	return _.mapValues(parsed, (v, k) => mapValue(v, k, props))
}

const mapValue = (value, key) => {
	if (value == 'false') return false

	if ((key.endsWith('_ids') || key === 'ids') && value.match(commaSeparatedNumbersRegex)) {
		return value.split(',').map(Number)
	}

	if (isNaN(Number(value))) return value

	return Number(value)
}

export default useQueryParams
