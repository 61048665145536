import {WeekTableCell, WeekTableCellItem, Text, Layout} from 'common/components'
import {Icon} from '@eitje/web_components'
import {getCssVariables} from 'helpers'
import {t} from 'initializers/i18n'
import {useUserContext} from 'contexts/user'
import {MultiActionCheckbox} from 'cores/planning/components/multi_action_checkbox'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useRoleMessage, useShared, useLocalValue, useDrag, useHasRole} from 'hooks'
import {navigateModal} from 'actions/routing'
import {useGetPlanningShiftUrlWithEnv} from 'cores/planning/helpers'
import {Environment, ShiftType, SkillSet} from 'models'
import {usePlanAvShift} from 'cores/availability'
import TradeTrigger from 'cores/planning/components/trade/approve_trade/trigger'
import './styles/index.less'

const conflictIconProps = {name: 'exclamation-mark-circled', size: 10}
const unpublishedIconProps = {name: 'letter-p-circled', size: 10}

export const PlanningShiftCell = ({shift}) => {
	return shift.user_id ? <UserPlanningShiftCell shift={shift} /> : <OpenPlanningShiftCell shift={shift} />
}

const OpenPlanningShiftCell = ({shift}) => {
	const {team} = shift
	const initialBackground = useLocalValue('planningPerMemberShowTeamColor') ? team.color : Colors.white
	return <BasePlanningShiftCell shift={shift} initialBackground={initialBackground} baseColor="blue" colorSet="grey-bordered" />
}

const UserPlanningShiftCell = ({shift}) => {
	const {date, user_id, environment_id, team} = shift
	const {hasConflict, avShift} = usePlanAvShift({date, user_id}, {planShifts: shift, envId: environment_id})
	const initialBackground = useLocalValue('planningPerMemberShowTeamColor') ? team.color : hasConflict ? undefined : Colors.white
	const colorSet = hasConflict ? 'color-bordered-outline' : 'grey-bordered'
	const baseColor = hasConflict ? 'red' : 'blue'

	const conflictCellItem = hasConflict && {
		type: 'subtitle',
		text: t('common.conflict_warning'),
		RightContent: Icon,
		rightContentProps: conflictIconProps,
	}

	return (
		<BasePlanningShiftCell
			shift={shift}
			className="conflict-shift"
			initialBackground={initialBackground}
			colorSet={colorSet}
			baseColor={baseColor}
			conflictCellItem={conflictCellItem}
			popoutTitle={avShift.tooltipText}
		/>
	)
}

const BasePlanningShiftCell = ({shift, conflictCellItem, baseColor, ...rest}) => {
	const {
		id,
		pauze_duur,
		environment_id,
		team,
		startTime,
		endTime,
		published,
		hasTradeRequest,
		remarks,
		reg_shift_id,
		shift_type_id,
		trade_request_id,
		skill_set_id,
	} = shift

	const {user} = useUserContext()
	const hasManagerRole = useHasRole('manager', environment_id)
	const breaktime = pauze_duur > 0 ? `(${pauze_duur})` : ''
	const {multiEdit, setSelectedShifts} = useEnvsContext()
	const disableEdit = useRoleMessage({roles: 'manager', env: environment_id})
	const shiftUrl = useGetPlanningShiftUrlWithEnv({user, shift})
	const environment = Environment.find(team.environment_id) // to do: can we solve this with default joins instead? or some other way? (reg has no default joins it seems)
	const {multiEnvOrg} = useShared()

	// Drag and drop
	const {isDragging, dragRef} = useDrag({
		type: `planning-shift`,
		item: {id: shift.id, shift},
		canDrag: hasManagerRole && !reg_shift_id,
	})

	if (!hasManagerRole && !published) return null
	const condOpts = {}
	if (!disableEdit) {
		condOpts.onClick = multiEdit ? () => setSelectedShifts([id]) : () => navigateModal(shiftUrl)
	}

	// Cell items
	const startEndTimes = {type: 'title', text: `${startTime()} - ${endTime()} ${breaktime}`}
	const unpublished = !published && {
		type: 'subtitle',
		text: t('common.unpublished'),
		RightContent: Icon,
		rightContentProps: unpublishedIconProps,
	}
	const tradeRequest = hasTradeRequest() && {
		type: 'subtitle',
		text: t('common.open_trade_request'),
		RightContent: Icon,
		rightContentProps: {name: 'arrow-circle', size: 8},
		textProps: {underline: true},
		Wrapper: TradeTrigger,
		wrapperProps: {id: trade_request_id},
	}
	const shiftEnv = multiEnvOrg && {text: environment.naam, localValue: 'planningPerMemberShowEnvironment'}
	const shiftTeam = {text: team.naam, localValue: 'planningPerMemberShowTeam'}
	const shiftRemark = remarks && {text: remarks, localValue: 'planningPerMemberShowRemarks'}

	return (
		<WeekTableCell
			cellType="card"
			baseColor={baseColor}
			LeftContent={multiEdit && MultiActionCheckbox}
			leftContentProps={{shifts: [shift], disabled: disableEdit}}
			items={[
				startEndTimes,
				conflictCellItem,
				unpublished,
				tradeRequest,
				shiftEnv,
				shiftTeam,
				shift_type_id && <ShiftTypeCellItem id={shift_type_id} baseColor={baseColor} />,
				skill_set_id && <SkillSetCellItem id={skill_set_id} baseColor={baseColor} />,
				shiftRemark,
			]}
			ref={dragRef}
			{...condOpts}
			{...rest}
		/>
	)
}

const SkillSetIcon = ({id}) => {
	// Reuse component once we will add letters on other pages. Eventually completely delete previous, non-Layout component with PNG image.
	// If moving the component, move the LESS file along
	const skillSet = SkillSet.find(id)
	const {abbr, color_code} = skillSet

	return (
		<Layout className="skill-set-icon" width={10} height={10} horizontal="center" initialBackground={color_code}>
			<Text lineHeight={1} fontSize={8} bold white>
				{abbr}
			</Text>
		</Layout>
	)
}

const ShiftTypeCellItem = ({id, ...rest}) => {
	const shiftType = ShiftType.find(id)
	if (!shiftType.id) {
		// this occurs if an environment moves to another org while the shifts already have types.
		// The shift type does exist, but it's not present in the FE because only
		return null
	}
	return <WeekTableCellItem {...rest} text={shiftType.nameWithEmoji()} localValue="planningPerMemberShowType" />
}

const SkillSetCellItem = ({id, ...rest}) => {
	const skillSet = SkillSet.find(id)
	return (
		<WeekTableCellItem
			{...rest}
			text={skillSet.name}
			RightContent={SkillSetIcon}
			rightContentProps={{id}}
			localValue="planningPerMemberShowSkill"
		/>
	)
}
