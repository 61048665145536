import {PrivateRoute} from 'components/private_route'

import {Insert, Settings} from 'cores/revenue/index'

const revenueBackgroundRoutes = [
	<PrivateRoute path="/environments/:envId/revenue/insert" component={Insert} />,
	<PrivateRoute path="/environments/:envId/revenue/settings" component={Settings} />,
]

export {revenueBackgroundRoutes}
