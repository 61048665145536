import utils from '@eitje/web_utils'
import {useBoolState} from '@eitje/react-hooks'
import {PlanningShift} from 'models/index'
import {useEnvTableContext, usePlanningContext, useTeamDayContext, useTeamContext} from 'cores/planning/index'
import {statusColors} from 'cores/planning/index'
import Skills from './skills'
import AmtWorked from './amt_worked'
import TimeBlocks from './time_blocks'
import AvailabilityMenu from './menu'
import './styles/index.less'
import {useDrag, useLocalValue} from 'hooks'

const AvailabilityCard = ({user, color, amtWorkedReplacement, hideTime}) => {
	const {dateRange} = usePlanningContext()
	const {date} = useTeamDayContext()
	const {env} = useEnvTableContext()
	const {team} = useTeamContext()
	const showPlannedExpected = useLocalValue('planningShowPlannedExpected')

	const [dropdownVisible, setDropdownVisible] = useBoolState()
	const planShifts = PlanningShift.where({dateRange: dateRange, user_id: user.id})

	const {isDragging, dragRef} = useDrag({
		type: `availability-card-${team.id}`,
		item: {id: user.id, planShifts, date},
	})

	const {status, avStatus, timeBlocks = []} = user
	if (!color) color = statusColors[avStatus || status]
	const {full_name} = user
	const klassName = `availability-card-${user.id}-${date.format()}`
	const classNames = utils.makeCns('availability-card', klassName, isDragging && 'is-dragging')

	return (
		<div ref={dragRef} onClick={() => setDropdownVisible(!dropdownVisible)} style={{backgroundColor: color}} className={classNames}>
			<div className="availability-card-title">
				<h5 className="username"> {full_name} </h5>
				{showPlannedExpected &&
					(amtWorkedReplacement?.(user) || <AmtWorked date={date} dateRange={dateRange} envId={env.id} user={user} />)}
			</div>
			<Skills user={user} team={team} />
			{status == 'partly_available' && !hideTime && <TimeBlocks timeBlocks={timeBlocks} />}
			{dropdownVisible && <AvailabilityMenu setDropdownVisible={setDropdownVisible} user={user} parentClass={klassName} />}
		</div>
	)
}

export default AvailabilityCard
