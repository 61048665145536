import {API, find, where} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {partition} from 'actions/general'
import {Progress} from 'antd'
import {Layout, ListPicker, Text} from 'common/components'
import {ProgressionBlock} from 'components/ui'
import {getUserSets} from 'helpers/skills'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnv, inEnvTeam} from 'selectors/records'

const SkillRow = ({item, userSkills, user}) => {
	const {hasManagerRole} = useShared()
	const skills = useSelector(state => where(state, 'skills', item.skill_ids))
	const [visible, setVisible] = useState(false)
	const lastUserSkill = userSkills[0]
	const lastSkill = useSelector(state => find(state, 'skills', lastUserSkill?.skill_id))
	const totalCount = _.uniq(item.skill_ids).length
	const pct = (userSkills.length / totalCount) * 100
	const onClick = () => setVisible(!visible)

	return (
		<div className="user-skill-row" onClick={onClick}>
			<div className="information-row">
				<h4 className="skill-title">{item.name}</h4>
				<h5 className="last-achieved-skill"> &nbsp;{lastSkill.name || '-'} </h5>
				<Progress strokeColor={item.color_code} percent={pct} format={() => `${userSkills.length}/${totalCount}`} />
			</div>

			{visible && (
				<div onClick={e => e.stopPropagation()} className="user-skills-list">
					<ListPicker
						raw
						readOnly={!hasManagerRole}
						onChange={(val, {changed, isSelect}) => API.updateAssoc('users', {id: user.id, skill_ids: [changed]}, {add: isSelect})}
						showSearch={false}
						value={user.skill_ids}
						showClearAll={false}
						items={skills}
						bold
					/>
				</div>
			)}
		</div>
	)
}

const SkillHeader = ({items}) => (
	<div className="data-display-header-container">
		{items.map(i => (
			<div className="data-display-header flex-grow-one">
				<p className="bold"> {t(i)} &nbsp; </p>
			</div>
		))}
	</div>
)

const headerItems = ['functie', 'lastSkill', 'progress']

const SkillBlock = ({userSkill}) => {
	const skill = useSelector(state => find(state, 'skills', userSkill.skill_id))

	return (
		<div className="row-with-border-and-padding-sm">
			<div className="flex-grow-one">
				<p className="eitje-text-secondary">{t('newSkillAcqui')}</p>
				<p className="bold">{skill.name}</p>
			</div>
			<p className="eitje-text-secondary">{utils.formatDate(userSkill.created_at, "DD MMM 'YY")}</p>
		</div>
	)
}

const Skills = ({user, userBanner}) => {
	const skillSets = _.sortBy(
		useSelector(state => inEnvTeam(state, 'skillSets')),
		'idx',
	).filter(s => s.skill_ids.length > 0)
	const [userSets, otherSets] = partition(skillSets, {team_ids: user.team_ids})
	const skills = useSelector(state => inEnv(state, 'skills'))
	const skillIds = skills.map(s => s.id)
	const unassignedSkills = skills.filter(s => s.skill_set_ids.length == 0).map(s => s.id)
	const userSkills = _.orderBy(user.user_skills, 'created_at', 'desc').filter(userSkill => skillIds.includes(userSkill.skill_id))
	const featuredSkills = userSkills.filter(s => skillIds.includes(s.skill_id)).slice(0, 5)
	const userSkillSets = getUserSets(user, skillSets)

	return (
		<div className="user-inner-grid">
			<div>
				{userBanner}
				<div>
					<SkillHeader items={headerItems} />
					{userSets.length == 0 && (
						<div className="row-with-border-and-padding-sm justify-content-center">
							<p className="eitje-text-secondary">{t('emptyRowSkills')}</p>
						</div>
					)}

					{userSets.map(s => (
						<SkillRow user={user} userSkills={userSkills.filter(s2 => s.skill_ids.includes(s2.skill_id))} item={s} />
					))}

					{(otherSets.length > 0 || unassignedSkills.length > 0) && (
						<div style={{marginTop: 48}} className="data-display-header-container">
							<div className="data-display-header">
								<p>{t('otherFunctions')}</p>
							</div>
						</div>
					)}

					{otherSets.map(s => (
						<SkillRow user={user} userSkills={userSkills.filter(s2 => s.skill_ids.includes(s2.skill_id))} item={s} />
					))}
					{unassignedSkills.length > 0 && (
						<SkillRow
							user={user}
							item={{name: t('unassignedSkills'), skill_ids: unassignedSkills}}
							userSkills={userSkills.filter(s => unassignedSkills.includes(s.skill_id))}
						/>
					)}
				</div>
			</div>
			<div className="user-details-column">
				{userSkillSets.length > 0 && (
					<Layout direction="vertical" width="100%">
						<Text darkGrey small t="skillsSchedule" />
						<ListPicker
							raw
							dropdown
							maxWidth="100%"
							placeholder={t('functionOnly')}
							single
							allowClear
							onChange={value => API.update('users', {id: user.id, skill_set_ids: !utils.exists(value) ? [] : [value]})}
							value={user.skill_set_ids?.[0]}
							items={userSkillSets}
						/>
					</Layout>
				)}

				<ProgressionBlock total={skills.length} active={userSkills.length} />

				{featuredSkills.map(s => (
					<SkillBlock userSkill={s} />
				))}
			</div>
		</div>
	)
}

export default Skills
