import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {matchPath, useLocation} from 'react-router-dom'
import {PopoutCard} from '@eitje/web_components'
import {makePath, Link} from 'components/routing'
import useSubRoutes from 'hooks/use_sub_routes'
import useSearch from 'hooks/use_search'
import {Layout, Text} from 'common/components'
import {Redirect} from 'components/routing'
import {getStringUpToSubstr} from 'helpers'
import {navigateModal, closeModal} from 'actions/routing'
import {history} from 'index'
import './styles/index.less'

export const DefaultSideMenuItem = ({text}) => (
	<Text truncate className="side-menu-item-text">
		{text}
	</Text>
)

const replaceParams = (route, path) => {
	// this actually is a bit bad.. it's because we've coupled sidemenu/route so frigging tightly that you can't have links that don't have their own routes..

	if (route.id) {
		path = path.replace(':id', route.id)
	}
	return path
}

const SideMenuRoute = props => {
	let {
		loc,
		disabledPopoutTitle,
		className = 'side-menu-group',
		linkDisabled = _.noop,
		extraPath,
		alwaysExpanded,
		children,
		MenuItem = DefaultSideMenuItem,
		route,
	} = props

	let pathOpts = {}
	if (_.has(props, 'exact')) pathOpts['exact'] = props.exact
	if (_.has(route, 'exact')) pathOpts['exact'] = route.exact

	const {ownPath, path} = route

	const allPaths = [path, ownPath]

	const isActive = allPaths.some(p => isPathActive(loc.pathname, p, pathOpts))

	const isHighlighted = isPathActive(loc.pathname, ownPath, {exact: true})

	const layoutClassName = utils.makeCns([isHighlighted && 'active'])

	const disabled = linkDisabled(props)

	return (
		<PopoutCard hidden={!disabled} title={disabledPopoutTitle}>
			<Link className={className} disabled={disabled} to={{pathname: path, state: loc.state}}>
				<Layout
					width="full"
					className={layoutClassName}
					colorSet={disabled ? 'disabled' : 'grey-bordered'}
					height={44}
					vertical="center"
					padding={12}
				>
					<MenuItem {...props} disabled={disabled} text={route.displayName} />
				</Layout>
			</Link>

			{(isActive || alwaysExpanded) && children}
		</PopoutCard>
	)
}

const SideMenuRouteGroup = props => {
	const {route, path, search, searchItems} = props
	const {subRoutes, subLinks} = route
	const allSubs = [...subRoutes, ...subLinks]
	const filteredSubs = searchItems(allSubs)
	const mainPath = makePath(path, route)

	return (
		<>
			<SideMenuRoute alwaysExpanded={search} {...props} exact={subRoutes.length == 0}>
				{filteredSubs
					.filter(r => r.name)
					.map(r => (
						<SideMenuRouteGroup className="side-menu-item" {...props} path={mainPath} route={r} isSub />
					))}
			</SideMenuRoute>
		</>
	)
}

//

const getTranslation = (route, props) => {
	const {name: namespace} = props
	const {name, noTranslate} = route
	if (noTranslate) return name
	const transKey = name || route
	const nameTrans = `modals.${namespace}.sidemenu.${transKey}`
	const normalTrans = `sidemenu.${namespace}.${transKey}`
	return name ? t(nameTrans, t(normalTrans, transKey)) : t(normalTrans, transKey)
}

export const buildRoute = (route, props, basePath) => {
	const displayName = getTranslation(route, props)
	let finalPath = makePath(basePath, route)
	let ownPath = finalPath
	const subRoutes = useSubRoutes(route).map(r => buildRoute(r, props, ownPath))
	const subLinks = useSubRoutes({subRoutes: route.subLinks}).map(r => buildRoute(r, props, ownPath))
	finalPath = replaceParams(route, finalPath)
	if (!route.component) finalPath = makePath(finalPath, route.subRoutes[0])
	const subNames = [...subRoutes._map('name'), subLinks._map('name')]
	return {
		...route,
		subRoutes,
		subLinks,
		displayName,
		subNames,
		path: finalPath,
		ownPath,
	}
}

export const SideMenu = ({routes, children, initialRoute = 0, modal, showSearch = routes.length > 5, ...props}) => {
	const {name} = props
	const loc = useLocation()
	const basePath = getStringUpToSubstr(loc.pathname, name)
	const className = utils.makeCns('side-menu-v2', modal ? 'modal-side-menu' : 'page-side-menu')

	routes = routes.map(r => buildRoute(r, props, basePath))

	const {
		searchInput,
		search,
		searchItems,
		filteredItems = [],
	} = useSearch(routes, {...props, searchField: ['displayName', 'subNames'], showSearch})
	if (loc.pathname == basePath && utils.exists(initialRoute)) {
		const initial = routes[initialRoute] || routes[routes.length - 1]
		// this is to compensate for array indices (starting at 0) not aligning with step progress
		// step progress starts at 0, but if all steps are completed it's array max + 1
		return <Redirect to={initial.path} />
	}

	return (
		<Layout direction="vertical" gap={0} className={className}>
			{showSearch && searchInput}
			{filteredItems.map((r, idx) => (
				<SideMenuRouteGroup
					{...props}
					search={search}
					searchItems={searchItems}
					index={idx}
					route={r}
					loc={loc}
					routes={routes}
					path={basePath}
				/>
			))}
			{children}
		</Layout>
	)
}

const trailingSlash = /\/$/g

export const goToNext = props => {
	if (props?.linkDisabled?.(props)) return
	const {sideMenuRoutes} = props
	let currentIdx = sideMenuRoutes.findIndex(r => r.name == props.name)
	let nextRoute
	if (currentIdx == -1) {
		// it's a subroute
		const subRoutes = sideMenuRoutes._map('subRoutes').flat()
		const currentSubIdx = subRoutes.findIndex(r => r.name == props.name)
		nextRoute = subRoutes[currentSubIdx + 1]
		if (!nextRoute) {
			const currentSub = subRoutes[currentSubIdx]
			currentIdx = sideMenuRoutes.findIndex(r => r.subRoutes.includes(currentSub))

			// sub group is finished, let's see if main group still has a next route
			nextRoute = sideMenuRoutes[currentIdx + 1]
		}
	} else {
		nextRoute = sideMenuRoutes[currentIdx + 1]
	}

	if (nextRoute) {
		navigateModal(nextRoute.path, {replace: true})
	} else {
		closeModal(props.location)
	}
}

const isPathActive = (currentPath, path, {exact = true}) => {
	// we'll remove the trailing slash from the currentPath
	const sanitizedPath = currentPath.replace(trailingSlash, '')
	path = path.replace(trailingSlash, '')
	return matchPath(sanitizedPath, {path, exact})
}

export default SideMenu
