import {useShared} from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import {syncNmbrs, syncVisma} from 'actions/environment'
import {taxonomySelector} from 'selectors/new_settings'
import {useSelector} from 'react-redux'
import {useEnvIntegration} from 'cores/integrations/index'
import {EitjeButton, EitjeDropdown, Text} from 'common/components'
import utils from '@eitje/web_utils'
import {ModalLink} from 'components/routing'

const okIntegs = [
	{name: 'nmbrs', action: syncNmbrs},
	{name: 'visma', action: syncVisma},
]

const HrSyncButton = () => {
	const {envSettings} = useShared()
	const modernInteg = useEnvIntegration({hr: true})
	if (modernInteg.id) return <ModernHrSyncButton integ={modernInteg} />
	const activeInteg = envSettings?.uren_reg?.koppeling
	const integ = okIntegs.find(i => i.name == activeInteg)
	if (!integ) return null
	return (
		<EitjeButton iconLeft="person" onClick={integ.action}>
			{t('syncEmployees')}
		</EitjeButton>
	)
}

export default HrSyncButton

const ModernHrSyncButton = ({integ}) => {
	let elements = [<Text onClick={() => integ.sync()} t="integrations.hr.sync_now" />]
	if (integ.sanitized_type == 'fooks')
		elements.push(
			<ModalLink to={integ.exportUsersUrl()}>
				<Text t={{key: 'integrations.hr.export_users', count: 2}} />
			</ModalLink>,
		)
	return (
		<EitjeDropdown elements={elements}>
			<EitjeButton dropdown iconLeft="arrow-circle" onClick={() => integ.sync()}>
				{t('integrations.sync_provider', {providerName: integ.name})}
			</EitjeButton>
		</EitjeDropdown>
	)
}
