import {backend, find} from '@eitje/easy_api'
import * as DB from 'actions/entities'
import {finishSubStep} from 'actions/onboarding'
import {Button, Input, Modal, Popconfirm, Select, Spin, TimePicker} from 'antd'
import {UserImage} from 'components/ui'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import React from 'react'
import {connect} from 'react-redux'
import {nonLockedTeamsSelector} from 'selectors/entities'
import {getEnvSettings, roleSelector} from 'selectors/new_settings'
import {orgEnvsSelector, queryInEnv} from 'selectors/records'
import * as jsUtils from 'utils/jsutils'
const Option = Select.Option
const OPTS = ['Gewerkte uren', 'now', 'verlof', 'bijzonder verlof', 'ziek'].map(i => jsUtils.capitalize(i))

class RegModal extends React.Component {
	constructor(props) {
		super(props)
		var shift = props.shift
		const vanaf = shift ? shift.from.format('HH:mm') : '09:00'
		const tot = shift ? shift.till.format('HH:mm') : '17:00'
		if (!shift) {
			shift = {reg: true, remarks: '', pauze_duur: 0, maaltijd: []}
		}

		var pauzeduur = jsUtils.minToTimeString(shift.pauze_duur)
		const remarks = shift.remarks
		this.state = {
			formObject: {
				from: vanaf,
				till: tot,
				shift_id: shift.id,
				pauzeduur: pauzeduur,
				environment_id: shift.environment_id || props.envId,
				remarks: remarks,
				maaltijd: shift.maaltijd || [],
				user_id: props.userId,
				date: props.date,
				team_id: shift.team_id,
				typ: OPTS.includes(jsUtils.capitalize(shift.typ)) ? jsUtils.capitalize(shift.typ) : 'Gewerkte uren',
			},
			audits: [],
			valid: true,
			hasPlan: !!shift.plan_from,
		}
	}

	async componentDidMount() {
		const {shift = {}} = this.props
		if (shift.reg) {
			const res = await backend.get(`shifts/${shift.id}/audits`)
			if (res.ok) {
				const json = res.data
				this.setState({
					audits: json.audits,
					checkIns: json.check_ins,
				})
			}
		}
	}

	onTimeChange(time, timeString, which) {
		var tijd = time ? time.format('HH:mm') : undefined
		var obj = {...this.state.formObject}
		obj[which] = tijd
		this.setState({formObject: obj})
	}

	altObj(val, which) {
		var obj = {...this.state.formObject}
		obj[which] = val
		this.setState({formObject: obj})
	}

	validate() {
		if (!this.state.formObject.team_id) {
			this.setState({valid: false})
			var jAs = jsUtils
			jsUtils.errNotif(`${t('oops')}`, `${t('pick1team')}`)
			return false
		}
		if (!this.state.formObject.till) {
			jsUtils.errNotif(`${t('oops')}`, `${t('noEnding')}`)
			return false
		}
		return true
	}

	async sendShift() {
		const formObj = {...this.state.formObject}
		formObj.pauzeduur = jsUtils.timeStringToMin(formObj.pauzeduur)
		formObj.typ = jsUtils.snakeCase(formObj.typ)
		formObj.maaltijd = formObj.typ == 'gewerkte_uren' ? formObj.maaltijd : []
		if (!this.validate()) {
			return
		}
		this.setState({loading: true})
		const res = await backend.post(`reg_shifts`, {
			reg_shift: formObj,
		})
		if (res.ok) {
			this.props.onCancel()
			this.props.handleSuccess(res.data)
			finishSubStep('uren', 'reg_shift_created')
		} else {
			res?.data?.errors && jsUtils.errReport(res.data.errors, true)
		}
		this.setState({loading: false})
	}

	async deleteShift() {
		const {shift, afterDestroy} = this.props
		const res = await backend.delete(`shifts/${shift.id}?reg=${true}`)
		if (res.ok) {
			DB.del('reg_shifts', shift.id)
			this.props.onCancel()
			afterDestroy && afterDestroy()
		}
	}

	render() {
		const {date: _date, orgEnvs, settings, mag_accorderen, user, shift, allowances = []} = this.props
		const {formObject, audits, notTeam, valid, hasPlan, loading, checkIns = []} = this.state
		const teams = this.props.teams.filter(t => !t.onroosterbaar)
		const {pauzeduur, remarks, maaltijd} = formObject
		var sh = formObject
		const shiftFrom = date(sh.from, 'HH:mm')
		const shiftTill = date(sh.till, 'HH:mm')

		var planFrom = shift && shift.plan_from ? date(shift.plan_from) : date(sh.from, 'HH:mm')
		var planTill = shift && shift.plan_till ? date(shift.plan_till) : date(sh.till, 'HH:mm')
		const pauzeTot = date(pauzeduur, 'HH:mm')

		const currentAllowance = shift && shift.allowance_ids && allowances.find(a => a.id === shift.allowance_ids[0])

		const isReg = shift && shift.reg
		let btns = [
			<div>
				<Button style={{minWidth: 110, maxWidth: 110}} key="back" onClick={this.props.onCancel}>
					{t('cancel')}
				</Button>{' '}
				<Button style={{width: 140, minWidth: 140}} type="primary" key="submit" onClick={() => this.sendShift()}>
					{t('submit')}
				</Button>
			</div>,
		]
		if (mag_accorderen && shift) {
			btns.unshift(
				<Popconfirm placement="top" title={t('sureDeleteReg')} onConfirm={() => this.deleteShift()}>
					<Button type="primary" danger style={{minWidth: 120, maxWidth: 120}}>
						{' '}
						{t('delete')}{' '}
					</Button>
				</Popconfirm>,
			)
		}
		return (
			<Modal
				className="modalStyle veetwee noselect modalStyle340"
				onOk={val => {
					this.sendShift()
				}}
				onCancel={this.props.onCancel}
				title={t('approveHours')}
				visible={true}
				footer={<div className="row-with-justify-between">{btns}</div>}
			>
				<Spin spinning={!!loading}>
					<div className="modalOverflow">
						<div className="w100 fRow aSta jBetween marginModal">
							<div>
								<p style={{width: '100%'}}>{t('user')}:</p>
								<div className="floatL modalDagBox modalDagBox336" style={{backgroundColor: '#f5f5f5', height: 40}}>
									<span style={{color: 'rgba(0, 0, 0, 0.65)', fontSize: 14}} className="capitalize">
										{user.full_name}
									</span>
								</div>
							</div>
							<UserImage user={user} divClassName="avaBG40 MTopss" extraStyle={{alignSelf: 'flex-end', marginRight: 4, marginBottom: 4}} />
						</div>
						<div className="w100 fRow aSta jBetween marginModal">
							<div className="w49">
								<p>{t('dayC')}:</p>
								<div className="modalDagBox" style={{backgroundColor: '#f5f5f5', width: '100%'}}>
									<span style={{color: 'rgba(0, 0, 0, 0.65)', fontSize: 14}} className="capitalize">
										{date(_date).format('dddd')}
									</span>
								</div>
							</div>

							<div className="team_id errable" className="w49">
								<p className="team_id errable" style={{}}>
									Team:
								</p>
								<Select
									placeholder={t('pickTeam')}
									className={`team_id errable ${!valid && 'invalid'} `}
									disabled={sh.id && !mag_accorderen}
									value={sh && sh.team_id && (teams.map(t => t.id).includes(sh.team_id) ? sh.team_id : 'Algemeen')}
									defaultValue={t('pickTeam')}
									style={{width: '100%'}}
									onChange={a => this.altObj(a, 'team_id')}
								>
									{teams &&
										_.sortBy(teams, 'rooster_order')
											.reverse()
											.map(team => <Option value={team.id}> {team.naam} </Option>)}
								</Select>
							</div>
						</div>
						<div className="w100 fRow aSta jBetween marginModal">
							<div className="w49">
								<p style={{}}>{t('startTimeS')}:</p>
								<TimePicker
									allowClear={false}
									disabled
									style={{width: '100%'}}
									minuteStep={5}
									format="HH:mm"
									onSelect={(a, b) => this.onTimeChange(a, b, 'from')}
									value={planFrom}
									defaultOpenValue={planFrom}
								/>
							</div>
							<div className="w49">
								<p style={{}}>{t('endTimeS')}:</p>
								<TimePicker
									allowClear={false}
									disabled
									style={{width: '100%'}}
									minuteStep={5}
									format="HH:mm"
									onSelect={(a, b) => this.onTimeChange(a, b, 'till')}
									value={planTill}
									defaultOpenValue={planTill}
								/>
							</div>
						</div>
						<div className="w100 fRow aSta jBetween marginModal">
							<div className="w49">
								<p style={{}}>{t('startShift')}:</p>
								<TimePicker
									allowClear={false}
									style={{width: '100%'}}
									minuteStep={5}
									format="HH:mm"
									onSelect={(a, b) => this.onTimeChange(a, b, 'from')}
									value={shiftFrom}
									defaultOpenValue={shiftFrom}
								/>
							</div>
							<div className="w49">
								<p style={{}}>{t('endShift')}:</p>
								<TimePicker
									allowClear={false}
									style={{width: '100%'}}
									minuteStep={5}
									format="HH:mm"
									onSelect={(a, b) => this.onTimeChange(a, b, 'till')}
									value={shiftTill}
									defaultOpenValue={shiftTill}
								/>
							</div>
						</div>
						<div className="w100 fRow aSta jBetween marginModal">
							<div className="w49">
								<p style={{}}>{t('breakTime')}:</p>
								<TimePicker
									allowClear={false}
									className="pauze_duur errable"
									style={{width: '100%'}}
									minuteStep={5}
									format="HH:mm"
									onSelect={(a, b) => this.onTimeChange(a, b, 'pauzeduur')}
									value={pauzeTot}
									defaultOpenValue={pauzeTot}
								/>
							</div>

							<div className="w49">
								<p style={{}}>{t('typeShift')}:</p>
								<Select
									disabled={!mag_accorderen}
									defaultValue={
										sh && sh.typ && OPTS.includes(jsUtils.capitalize(sh.typ)) ? jsUtils.capitalize(sh.typ) : `${t('hoursWorked')}`
									}
									style={{width: '100%'}}
									onChange={a => this.altObj(a, 'typ')}
								>
									{(mag_accorderen ? OPTS : [t('gewerkte uren')]).map(opt => (
										<Option value={opt}>{t(opt.toLowerCase())} </Option>
									))}
								</Select>
							</div>
						</div>
						{allowances && allowances.length > 0 && (
							<div className="w49">
								<p style={{}}>{t('allowance')}:</p>
								<Select
									disabled={!mag_accorderen}
									style={{width: '100%'}}
									defaultValue={currentAllowance ? jsUtils.capitalize(currentAllowance.name) : 'Geen'}
									onChange={a => this.altObj(a, 'allowance')}
								>
									{[...allowances, 'Geen'].map(opt => (
										<Option value={opt == 'Geen' ? 'Geen' : opt.id}> {jsUtils.capitalize(opt == 'Geen' ? 'Geen' : opt.name)} </Option>
									))}
								</Select>
							</div>
						)}
						{formObject && formObject.typ == 'Gewerkte uren' && (
							<div className="clear MBot">
								{settings['maaltijd']['maaltijd_selected'].length > 0 && <p style={{marginBottom: 2}}>{t('mealAllowance')}:</p>}
								{settings['maaltijd']['maaltijd_selected'].map(m => (
									<span
										style={{
											marginRight: 4,
											fontSize: 9,
											cursor: 'pointer',
											borderRadius: 4,
											padding: '5px 12px',
											border: '1px solid',
											color: (maaltijd || []).includes(m) ? '#0496ff' : '#ddd',
										}}
										onClick={() => this.altObj(jsUtils.addOrRemove(maaltijd || [], m), 'maaltijd')}
									>
										{' '}
										{t(m.toLowerCase())}{' '}
									</span>
								))}
							</div>
						)}
						<div className="clear MBot" style={{width: '100%'}}>
							<div className="marginModal pickShiftTime1" style={{width: '100%'}}>
								<p style={{}}>{t('remarks')}:</p>
								<Input.TextArea
									value={remarks}
									autosize={{minRows: 4, maxRows: 4}}
									style={{width: '100%', maxWidth: '100%'}}
									onChange={e => this.altObj(e.target.value, 'remarks')}
								/>
							</div>
						</div>
						{isReg && mag_accorderen && audits.length > 0 && <p>{t('audits')}:</p>}
						<div className="clear MBot">
							{' '}
							{isReg &&
								audits.length > 0 &&
								audits.map(audit => (
									<p>
										{' '}
										{audit.naam}: {date(audit.tijd).format('DD-MM-YYYY HH:mm')} {audit.changes.published && `${t('approved')}`}{' '}
										{audit.changes.exported_count && audit.changes.exported_count[1] ? `${t('exported')}` : null}{' '}
									</p>
								))}{' '}
						</div>
						{isReg && mag_accorderen && checkIns.length > 0 && (
							<div>
								<p>Check-ins </p>
								{checkIns.map(ci => (
									<p>
										{' '}
										{date(ci.starts_at).format('HH:mm:ss')} - {date(ci.ends_at).format('HH:mm:ss')}{' '}
									</p>
								))}
							</div>
						)}
						<div className="clear"></div>
					</div>
				</Spin>
			</Modal>
		)
	}
}

export default connect((state, ownProps) => ({
	allowances: queryInEnv(state, 'allowances', {active: true}),
	orgEnvs: orgEnvsSelector(state),
	envId: state.environment.active,
	settings: getEnvSettings(state, ownProps.shift && ownProps.shift.environment_id),
	teams: nonLockedTeamsSelector(state),
	mag_accorderen: roleSelector(state, 'uren_accorderen'),
	user: find(state, 'users', ownProps.userId),
}))(RegModal)
