import {UserAvatar, UserImage} from 'components/ui'

const SmartAvatar = ({name, image, online, size, userId}) => {
	return (
		<div style={{position: 'relative'}}>
			{online && <div className="online-chat-circle" />}
			<UserAvatar size={size || 'lg'} className="margin-right-sm" user={{id: userId, name}} userId={userId} avatarImage={image} />
		</div>
	)
}

export default SmartAvatar
