import {stepProps} from 'constants/onboarding'
import {store} from 'index'
import I18n, {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment} from 'react'
import {envSelector} from 'selectors/entities'
import * as jsUtils from 'utils/jsutils'

const getVidUrl = url => {
	return `https://docs.eitje.app/assets/${url}`
}

export const isDone = step => {
	return _.isObject(step) ? _.values(step).every(s => !!s) : !!step
}

export const figureOutActive = steps => {
	let actStep = false
	const keys = Object.keys(steps)
	for (const [index, k] of keys.entries()) {
		if (isDone(steps[k])) {
			actStep = keys[index + 1]
		} else {
			actStep = k
			break
		}
	}

	return actStep
}

export const getSubStepName = (step, subStep) => t(`onb_step_${step}_${subStep}`)

export const finishSubStep = (step, subStep) => {
	const state = store.getState()
	const steps = state.settings.taxonomy?.onboarding_steps
	const env = envSelector(state)
	if (!env || env.status !== 'demo') {
		return
	}
	const oldVal = steps[step][subStep]
	if (!oldVal) {
		const newSteps = {...steps, [step]: {...steps[step], [subStep]: true}}
		store.dispatch({type: 'FINISH_ONBOARDING_SUBSTEP', steps: newSteps})
		jsUtils.openNotif(t('yay'), 'success', I18n.t('onb_substep_completed', {stepTitle: getSubStepName(step, subStep)}))
	}
}
// `${t('onb_substep_completed_1')} '${getSubStepName(step, subStep).toLowerCase()}' ${t('onb_substep_completed_2')}`

export const makeSubStep = (step, subStep) => {
	if (
		!step ||
		!subStep ||
		!stepProps[step] ||
		!stepProps[step]['subSteps'] ||
		!stepProps[step]['subSteps'][subStep] ||
		!stepProps[step]['subSteps'][subStep]['steps']
	)
		return null

	const item = stepProps[step]['subSteps'][subStep]
	return (
		<Fragment>
			<p className="titleSideDrawer">{getSubStepName(step, subStep)}</p>
			{item.steps.map((s, idx) => (
				<p className="sideDrawerStep">
					{' '}
					<b> Stap {idx + 1}: </b> {t(s)}{' '}
				</p>
			))}
			{item.extraStep && (
				<p className="sideDrawerStep" style={{marginTop: 16}}>
					{' '}
					<b>Bonus: </b> {t(item.extraStep)}{' '}
				</p>
			)}

			{item.expLink && (
				<a href={`https://intercom.help/eitje/${item.expLink}`} target="_blank">
					<p className="sideDrawerLink">{t('goToDocs')}...</p>
				</a>
			)}

			{item.videoUrl && (
				<div className="sideDrawerVideo">
					<p className="titleSideDrawer">{t('videoExp')}</p>
					<p className="sideDrawerStep"> {t('videoExpSub')} </p>
					<video key={item.videoUrl} poster={'/images/posterVideo.png'} width="100%" controls>
						<source src={getVidUrl(item.videoUrl)} />
					</video>
				</div>
			)}

			{item.explanation && (
				<Fragment>
					<div className="achtergrondDrawer">
						<p className="titleSideDrawer">Achtergrond</p>
						<p className="sideDrawerStep">{item.explanation()}</p>
					</div>
				</Fragment>
			)}
		</Fragment>
	)
}
