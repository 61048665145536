import {find} from '@eitje/easy_api'
import {PopoutCard} from '@eitje/web_components'
import {ModChatButton} from 'actions/chat'
import {sendInvitation} from 'actions/user'
import {Button, Popover} from 'antd'
import useList from 'components/general/list'
import {ModalLink} from 'components/routing'
import {BackButton, EitjeButton, PageLayout} from 'common/components'
import {DelButton, DelPopOver} from 'components/users'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import {t} from 'initializers/i18n'
import UserProfile from 'pages/v3/user/profile'
import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {Link, Redirect, Route, Switch, useParams, useRouteMatch} from 'react-router-dom'
import {inEnv, isUserRemovedSelector} from 'selectors/records'
import {fixProblem, ManageSkillsBtn, Profile, SubHeader, User} from './components'
import Contracts from './contracts/index'
import Skills from './skills'
import UserContext from 'contexts/user'
import './styles/user.less'
import './styles/show.less'
import 'pages/v3/user/styles/user_layout.less'

const listProps = {
	ListItem: User,
	onClick: item => fixProblem(item.id),
}

const UserIndex = props => {
	const {hasManagerRole, hasContractRole, me, env, envId} = useShared()
	const hasRole = hasManagerRole || hasContractRole
	const match = useRouteMatch()
	const users = useSelector(state => inEnv(state, 'users'))
	const {id} = useParams()
	const user = useSelector(state => find(state, 'users', id))

	const searchProps = {
		searchField: 'full_name',
		placeholder: t('searchTeamMember'),
		className: 'bubble_input',
	}

	const {list, searchInput} = useList({
		items: users,
		virtual: true,
		...listProps,
		searchProps,
		hasSearch: true,
		activeImperative: Number(id),
	})
	const isSelf = me?.id == id
	const isRemoved = useSelector(state => isUserRemovedSelector(state, user.id))
	const hasActions = !isRemoved && (hasManagerRole || !isSelf)
	if (!user.id) return <Redirect to={`/user/${me.id}`} />

	const bannerRouteProps = {hasManagerRole, hasActions, hasContractRole, user, envId, isRemoved, isSelf}

	const userBanner = (
		<div className="user-banner">
			<div className="flex-grow-one">
				<Profile user={user} />
			</div>
			<div>
				{isRemoved ? (
					<div className="column">
						<div className="user-banner-alert">
							<p style={{color: '#ff0020'}}>{t('oldTeammember')}</p>
						</div>
						<DelPopOver user={user} buttonStyle={{width: 160}} />
					</div>
				) : (
					<MenuRoutes routeProps={bannerRouteProps} path={match.path} />
				)}
			</div>
		</div>
	)

	const routeProps = {id, myId: me.id, isSelf, isRemoved, envId, userBanner, ...bannerRouteProps}

	return (
		<UserContext id={id}>
			<PageLayout name="user-settings">
				<div className="user-page user-index">
					<div className="user-navigation-column">
						<div className="navigation-container">
							<div className="back-arrow-container">
								<BackButton width="full" onClick={() => history.replace(`/teams/${env.locked_team_id}`)} />
							</div>
							{(hasRole || isSelf) && <SubHeader user={user} />}
						</div>

						{hasRole && (
							<Fragment>
								<div className="user-search" style={{}}>
									{searchInput}
									<PopoutCard title={t('addNewUserToEnv')}>
										<ModalLink to="/forms/invite" type="primary">
											<img height={18} width={18} src="/images/addButton.png" />
										</ModalLink>
									</PopoutCard>
								</div>

								<div className="user-list-base list-active-right">{list}</div>
							</Fragment>
						)}
					</div>

					<div className="user-information-column">
						<Routes path={match.path} routeProps={routeProps} />
					</div>
				</div>
			</PageLayout>
		</UserContext>
	)
}

export default UserIndex

const ActionsMenu = ({user, showDelete}) => {
	const {hasManagerRole} = useShared()
	return (
		<div className="user-actions-popover-content column gap-xss">
			{!user.confirmed && (
				<Button type="primary" onClick={() => sendInvitation(user.id)}>
					{t('resendInvitation')}
				</Button>
			)}
			{showDelete && hasManagerRole && <DelButton user={user} />}

			<ModChatButton userId={user.id} />
		</div>
	)
}

const UserActions = ({user, envId}) => (
	<Popover trigger="click" content={() => <ActionsMenu showDelete={user?.environment_ids?.includes(envId)} user={user} />}>
		<EitjeButton t="actions" />
	</Popover>
)

const MenuRoutes = ({routeProps = {}, isSelf, path}) => {
	const {hasManagerRole, hasActions, hasContractRole, user} = routeProps

	return (
		<Switch>
			<Route path={`${path}/skills`} exact>
				{hasManagerRole && (
					<div className="column gap-xss">
						<ManageSkillsBtn width={220} />
						<Link to="/skills">
							<Button type="primary" style={{width: 220}}>
								{t('manageSkills')}
							</Button>
						</Link>
					</div>
				)}
			</Route>

			{hasContractRole && (
				<Route path={`${path}/contracts`}>
					<EitjeButton modalLink={`/forms/new_contract/${user.id}`} t="newContract" />
				</Route>
			)}

			{hasActions && (
				<Route path={`${path}/profile`} exact>
					<UserActions {...routeProps} />
				</Route>
			)}
		</Switch>
	)
}

const Routes = ({routeProps, path}) => {
	const {user, hasContractRole, myId, isSelf, hasManagerRole} = routeProps
	const contractsAllowed = user.id == myId || hasContractRole

	return (
		<Fragment>
			<Switch>
				<Route path={`${path}/profile`}>
					<UserProfile {...routeProps} path={path} />
				</Route>

				<Route path={`${path}/skills`} exact>
					<Skills {...routeProps} />
				</Route>

				{contractsAllowed && (
					<Route path={`${path}/contracts`}>
						<Contracts {...routeProps} />
					</Route>
				)}
				<Redirect to={`${path}/profile`} />
			</Switch>
		</Fragment>
	)
}
