import {PopoutCard} from '@eitje/web_components'
import {useSelector} from 'react-redux'
import {channelData} from 'actions/chat'
import React, {useContext, useEffect, useState} from 'react'
import {ChatStateContext} from '../../providers/chat_provider'
import SmartAvatar from '../SmartAvatar'
import RemoveChatButton from './RemoveChatButton'

const ModularAvatarContainer = ({channel}) => {
	const [inHover, setHover] = useState(false)
	const [unreadCount, setUnreadCount] = useState(channel.countUnread())
	const [online, isUserOnline] = useState(0)

	const {cid} = channel
	const {member_count} = channel.data
	const ent = useSelector(state => state.entities.envs)
	const currentUserId = useSelector(state => state.auth.user.id)

	const {displayImage, displayName, teamName} = channelData(channel, currentUserId, ent)
	const {currentChannel, setCurrentChannel} = useContext(ChatStateContext)

	useEffect(() => {
		const unreadCountHandler = () => (currentChannel.cid !== cid ? setUnreadCount(channel.countUnread()) : null)
		const isUserOnlineHandler = e => isUserOnline(e.watcher_count)

		channel.on('message.new', unreadCountHandler)
		channel.on('message.read', unreadCountHandler)
		channel.on('user.watching.start', isUserOnlineHandler)
		channel.on('user.watching.stop', isUserOnlineHandler)
		return () => {
			channel.off('message.new', unreadCountHandler)
			channel.off('message.read', unreadCountHandler)
			channel.off('user.watching.start', isUserOnlineHandler)
			channel.off('user.watching.stop', isUserOnlineHandler)
		}
	})

	const unreadEl = unreadCount > 0 && <div className="chat-unread-circle">{unreadCount}</div>
	const displayOnline = member_count === 2 && online === 2

	const body = (
		<div style={{fontWeight: '500', fontSize: '.8em'}}>
			{displayName}
			{teamName && teamName}
		</div>
	)

	return (
		<div className="active_chat_icon" style={{position: 'relative'}}>
			<PopoutCard placement="right" bodyPadding={0} body={body}>
				<div
					style={{marginTop: '3px'}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					onClick={() => setCurrentChannel(channel)}
				>
					<SmartAvatar name={displayName} size={'xl'} image={displayImage} online={displayOnline} />
				</div>
				{inHover && <RemoveChatButton channel={channel} setHover={setHover} />}
				{unreadEl}
			</PopoutCard>
		</div>
	)
}

const ActiveChats = props => {
	const {activelySelectedChannels, currentChannel} = useContext(ChatStateContext)
	if (activelySelectedChannels.length > 0) {
		const arr = activelySelectedChannels.filter(channel => channel.cid !== currentChannel?.cid)
		return arr.map(channel => <ModularAvatarContainer key={channel.cid} channel={channel} />)
	}
	return null
}

export default ActiveChats
