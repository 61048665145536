import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {EitjeButton, ListPicker} from 'common/components'
import {writeStore, useVisibleColumns, useGroupedColumn, getTableHeaderFieldName} from 'common/components/advanced_table'

export const ColumnPicker = ({fields: allTableColumns, Trigger = ColumnButton, tableName, recordType, ...rest}) => {
	const _name = `${tableName}_table.fields`
	const visibleColumns = useVisibleColumns({tableName, allTableColumns})
	const savedGrouping = useGroupedColumn({tableName})
	const columnItems = allTableColumns.map(f => mapField(f, tableName, recordType, savedGrouping))

	return (
		<ListPicker
			raw
			dropdown
			data-intercom-target="column_picker"
			noSort
			showSearch
			showToggleSelectAll={false}
			min={1}
			value={visibleColumns}
			Trigger={Trigger}
			showClearAll={false}
			onChange={value => writeStore(tableName, {visible: [savedGrouping, ...value]})}
			items={columnItems}
			{...rest}
		/>
	)
}
const ColumnButton = props => {
	return (
		<EitjeButton iconLeft="three-sliders" {...props} padding={8}>
			{utils.capitalize(t('common.column_plural'))}
		</EitjeButton>
	)
}

const mapField = (field, name, recordType, savedGrouping) => {
	const _name = getTableHeaderFieldName({field, name, recordType})
	return {id: field, name: _name, disabled: savedGrouping === field && 'table.cant_hide_grouped'}
}
