import {makeAbbr} from 'helpers/users'
import {InnerAvatar} from '..'
import '../styles/user_avatar.less'
import {Text} from 'common/components/text'

export const UserAvatar = ({user, ...rest}) => {
	if (!user) return null
	const noAvatar = !_.isString(user?.avatar)

	let initials
	if (noAvatar) {
		initials = makeAbbr(user)
	}

	const colorSet = noAvatar ? undefined : 'grey-bordered'
	const body = noAvatar ? <Text semiBold>{initials}</Text> : <img className="eitje-avatar-image" src={user.avatar} />
	return (
		<InnerAvatar colorSet={colorSet} {...rest}>
			{body}
		</InnerAvatar>
	)
}
