import {t} from 'initializers/i18n'
import {Redirect} from 'components/routing'
import useShared from 'hooks/use_shared'
import useOrgLocked from 'cores/billing/hooks/use_org_locked'
import {EitjeButton, PageLayout} from 'common/components'
import './expired.less'

const Page = () => {
	const {locked} = useOrgLocked()
	const {hasAdminRole} = useShared()
	if (!locked) return <Redirect to="/" />
	return (
		<PageLayout name="billing-expired">
			<div className="billing-expired-container">
				<div className="billing-expired-container-left-side">
					<h3>{t('common.sadly')} </h3>
					<h1> {t('billing.titles.organisation_locked')} </h1>
					<div className="horizontal-divider" />
					<p className="explanation">
						{t('billing.texts.organisation_locked')}
						{!hasAdminRole && ` ${t('billing.texts.organisation_locked_users')}`}
					</p>
					{hasAdminRole && <EitjeButton modalLink="/billing/sign" t="billing.buttons.activate_now" />}
				</div>
				<div className="billing-expired-container-right-side">
					<img src="/billing/locked_billing_illustration.png" className="locked-billing-img" />
				</div>
			</div>
		</PageLayout>
	)
}

export default Page
