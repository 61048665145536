import {Fragment} from 'react'
import {Icon} from '@eitje/web_components'
import {Text, EitjeDropdown, Layout} from 'common/components'
import utils from '@eitje/utils'
import {makeCnVariants} from 'helpers'
import {useHeaderTax} from './taxonomy'
import {t} from 'initializers/i18n'
import {useHasRole, useHasRoleInAnyEnv} from 'hooks'
import {pathActive} from 'helpers'
import {ModalLink, Link, ExtLink} from 'components/routing'
import {NAVBAR_HEIGHT} from '.'
import {ROLE_TRANSLATIONS} from 'cores/firm'
import 'styles/app/styles/elements/state_effects.less'

export const TRIGGER_STYLING = {
	offset: [0, 2],
	layoutProps: {height: 'full', vertical: 'center'},
	wrapperLayoutProps: {height: 'full'},
}

function getWrapper(url, link, modalLink, fallbackWrapper) {
	if (link) return Link
	if (url) return ExtLink
	if (modalLink) return ModalLink
	return fallbackWrapper
}

export const titleWithCount = (title, count) => {
	if (!count) return title
	return `${title} (${count})`
}

export const NavItem = ({group, ...rest}) => {
	let {key, items, link, count, url, dropdownProps, onClick, icon} = useHeaderTax().find(item => item.key === group)
	items = items.filter(Boolean)
	const title = titleWithCount(t(`header.${key}`), count)
	const first = items._filter('!hide')[0]
	const _link = link || first.link
	const disabled = !!(first.link && first.disabled)
	const activeItem = items.find(item => pathActive(item.link, false))
	const subtitleKey = activeItem && (activeItem.abbreviatedLabel ? `${activeItem.key}_abbreviation` : activeItem.key)
	const subtitle = subtitleKey && t(`header.${subtitleKey}`)
	const _icon = icon && <Icon name={icon} />

	let elements = items.map(item => DropdownItemWrapper(item)).filter(Boolean)
	elements = elements.map(el => ({element: el, ...el.props, onClick: el.props.originalOnClick}))
	return (
		<EitjeDropdown elements={elements} {...TRIGGER_STYLING} strategy="fixed" {...dropdownProps}>
			<NavItemLayout
				icon={_icon}
				title={title}
				subtitle={subtitle}
				link={_link}
				url={url}
				disabled={disabled}
				onClick={onClick}
				{...rest}
			/>
		</EitjeDropdown>
	)
}

export const NavItemLayout = ({title, subtitle, link, modalLink, icon, url, onClick, support, count, disabled, layoutProps = {}}) => {
	const Wrapper = getWrapper(url, link, modalLink, Fragment)
	const disabledCn = disabled && 'not-allowed-cursor-important'
	const wrapperClassNames = utils.makeCns(disabledCn)
	const _subtitle = !icon && subtitle
	const classNames = utils.makeCns(
		makeCnVariants('nav-item', _subtitle && 'subtitle', icon && 'has-icon', disabled && 'disabled'),
		disabledCn,
	)
	const active = _subtitle // infer if item is active, potentially add more inferences in future
	// Firefox requires centers to prevent going bonkers...
	const textStyles = {bold: active || support, mediumBlue: active, darkPink: support, center: true}

	return (
		<Wrapper to={!disabled && (link || modalLink || url)} style={{height: '100%'}} className={wrapperClassNames}>
			<Layout direction="vertical" vertical="center" className={classNames} onClick={onClick} {...layoutProps}>
				{icon || <Text {...textStyles}>{titleWithCount(title, count)}</Text>}

				{_subtitle && (
					<Text mini className="subtitle" truncate={84} truncateTooltip={false} {...textStyles}>
						{subtitle}
					</Text>
				)}
			</Layout>
		</Wrapper>
	)
}

const DropdownItem = ({transKey, rawText, link, modalLink, url, count, Wrapper, ...rest}) => {
	const title = titleWithCount(t(`header.${transKey}`), count)
	const active = pathActive(link)

	return (
		<Wrapper to={link || modalLink || url} data-header-item-active={!!active} {...rest}>
			{rawText || title}
		</Wrapper>
	)
}

function useItemDisabled(item) {
	const {role, role_in = 'any_env', disabled} = item

	const currentEnvRole = useHasRole(role)
	const anyEnvRole = useHasRoleInAnyEnv(role)
	const roleMessage = t(`header.disabled.${ROLE_TRANSLATIONS[role]}_role`)

	if (disabled) return t(`header.disabled.${disabled}`)
	if (role && role_in === 'any_env' && !anyEnvRole) return roleMessage
	if (role && role_in === 'current_env' && !currentEnvRole) return roleMessage
}

const DropdownItemWrapper = item => {
	const {link, modalLink, hide, url, onClick} = item
	const active = pathActive(link, false)
	const disabled = useItemDisabled(item)
	const Wrapper = getWrapper(url, link, modalLink, Text)
	const classNames = utils.makeCns(active && 'active')
	if (!item || hide) return null
	return (
		<DropdownItem
			{...item}
			Wrapper={Wrapper}
			onClick={null}
			originalOnClick={onClick}
			transKey={item.key}
			disabled={disabled}
			className={classNames}
		/>
	)
}

const PADDING = [0, 24]

export const Logo = () => {
	return (
		<Link to="/news">
			<Layout {...TRIGGER_STYLING} padding={PADDING} height={NAVBAR_HEIGHT}>
				<img className="eitje-logo" src="/images/brand/logo-with-text.png" />
			</Layout>
		</Link>
	)
}
