import {humanReadableList} from 'helpers/array'
import {useHasRoleInAnyEnv, useShared} from 'hooks'
import {t} from 'initializers/i18n'

export const useRoleMessage = props => {
	const {orgEnvs} = useShared()

	let propsObject
	// Allow a string to be passed for a quick any env check, or an object for more complex checks
	if (_.isString(props)) propsObject = {roles: props}
	// allow undefined being passed for easy access, simply return
	if (!props) propsObject = {roles: null}
	// fallback to accept the passed object, if above conditions are untrue
	if (!propsObject) propsObject = props
	const {env = 'any', roles} = propsObject

	// Figure out what the envIds are, so that we may pass environments to this function in many different
	// ways for easy access.
	let envIds
	const array = _.isArray(env)
	const item = env[0]

	if (_.isNumber(env)) envIds = [env]
	if (!array && env.tableName === 'environments') envIds = [env.id]
	if (array && _.isNumber(item)) envIds = env
	if (array && item?.tableName === 'environments') envIds = env._map('id')

	// Simply return nothing if the item shouldn't be disabled
	const anyEnvRole = useHasRoleInAnyEnv(roles, envIds)
	if (anyEnvRole || !roles) return null

	// Explain why the item is disabled
	return formatRoleMessage({roles, env, envIds, orgEnvs})
}

const formatRoleMessage = ({roles, env, envIds, orgEnvs}) => {
	const missingRole = t('role_message.missing_role')
	const showEnvNames = env !== 'any' && env !== 'current'

	// Filter envIds on org to dump any possible incorrect arguments
	const orgEnvIds = orgEnvs.ids()
	const envIdsInOrg = envIds?.filter(id => orgEnvIds.includes(id))

	// Translate and format the roles
	const rolesArray = _.isArray(roles) ? roles : roles?.split(' ')
	const translatedRoles = rolesArray.map(r => `${t(`rolesEnum.${r}`)}`)
	const roleNames = humanReadableList(translatedRoles)

	// Show "in current", "in any" or "in ..." environments
	const envTKey = showEnvNames ? '' : `${env}_`
	const inEnvs = t(`role_message.be_in_${envTKey}env`)

	// If multiple envs but not any, show in which envs the role is missing
	const orgEnvsObject = _.keyBy(orgEnvs, 'id')
	const envNames = envIdsInOrg?.map(id => _.get(orgEnvsObject[id], 'naam', null))
	const envDescription = envNames && showEnvNames ? humanReadableList(envNames) : ''

	return `${missingRole}${roleNames} ${inEnvs}${envDescription}`
}
