import {t} from 'initializers/i18n'

export const getTableHeaderFieldName = ({name, recordType, field}) =>
	t(`table.${name}.headers.${field}`, t(`records.${recordType}.fields.${field}`))

export const getSelectedRows = tableData => {
	const rows = tableData.getRowModel().flatRows
	const selectedRows = _.uniqBy(
		rows.filter(r => r.getIsSelected() && !r.groupingValue),
		'id',
	)
	return selectedRows
}
