import utils from '@eitje/utils'
import {initConversation, modularClick, userSearcher} from 'actions/chat'
import {t} from 'initializers/i18n'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {orgUsersSelector} from 'selectors/entities'
import {ChatContext} from 'stream-chat-react'
import {ChatStateContext} from '../providers/chat_provider'
import SmartAvatar from './SmartAvatar'

const WithoutOneToOne = () => {
	const currentEnv = useSelector(state => state.environment.active)
	const {oneToOneUsers, searchUserQuery, modular, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown, setChannelFilter} =
		useContext(ChatStateContext)
	const team = useSelector(orgUsersSelector)
	const userIDString = useSelector(state => state.auth.user.id.toString())

	const boxCn = utils.makeCns('channel-box', modular && 'modular')

	if (Array.isArray(oneToOneUsers)) {
		const usersWithoutChatArr = [...oneToOneUsers.map(num => num.toString())]
		const users = userSearcher(team, searchUserQuery).filter(user => usersWithoutChatArr.includes(user))
		const newTeam = team.filter(user => users.includes(user.id.toString()))

		if (newTeam.length === 0) {
			return null
		}
		return (
			<div style={{marginBottom: 40}}>
				<div className="beginChat">
					<div style={{margin: '0px 0px 12px 12px'}}>
						<p className="bold">{t('teamMembers')}</p>
					</div>

					<ChatContext.Consumer>
						{chat => {
							return newTeam.map(user => {
								return (
									<div
										className={boxCn}
										onClick={async () => {
											const newChannel = await initConversation([userIDString, user.id], user.id, currentEnv)
											setChannelFilter('all')
											return modular
												? modularClick(newChannel, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown)
												: chat.setActiveChannel(newChannel)
										}}
									>
										<SmartAvatar name={user.full_name} image={user.avatar} />

										<div className="channel-box-text-container">
											<p className="channel-box-title">{user['full_name']}</p>
											<p className="eitje-text-secondary nowrap">{t('noMessagesYet')}</p>
										</div>
										<div className="channel-box-end">
											<p className="eitje-text-secondary">{t('start')}</p>
										</div>
									</div>
								)
							})
						}}
					</ChatContext.Consumer>
				</div>
			</div>
		)
	}
}

export default WithoutOneToOne
