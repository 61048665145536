import {all} from '@eitje/easy_api'
import _ from 'lodash'
import createCachedSelector from 're-reselect'
import createSmartCachedSelector from 'initializers/selectors'
import {createSelector} from 'reselect'
import {currentEnvSelector, envIdSelector, inEnv, orgEnvsSelector} from 'selectors/records'
import utils from '@eitje/web_utils'

const settingsSelector = state => state.settings.environments
const userSettingsSelector = state => state.settings.user
const tax = state => state.settings.taxonomy || {}

export const taxonomySelector = createSelector(
	tax,
	(state, key) => key,
	(tax, key) => tax[key],
)

export const hasVloerSelector = createSelector(
	state => taxonomySelector(state, 'vloer'),
	(vloerTax = {}) => vloerTax.activated,
)

const allRolesSelector = state => state.settings.roles

const filterRecordsOnRole = (records = [], roleEnvs) => {
	if (records.length == 0) return
	return records.filter(r => _.intersection(r.environment_ids, roleEnvs).length > 0)
}

export const allRole = createCachedSelector(
	all,
	allRolesSelector,
	(state, kind, role) => role,
	(records, roles, role) => filterRecordsOnRole(records, getRoleEnvs(roles, role)),
)((state, key, role) => `${key}-${role}`)

export const roleEnvSelector = createCachedSelector(
	state => state.settings.roles,
	(state, key) => key,
	(roles, key) => getRoleEnvs(roles, key),
)((state, key) => key)

export const roleOrgEnvsSelector = createSmartCachedSelector(
	orgEnvsSelector,
	state => state.settings.roles,
	(state, kind) => kind,
	(envs, roles, kind) => envs.filter(e => hasRole(roles[e.id], kind)),
)

const allRoles = ['manager', 'contracten', 'financieel', 'verlof', 'uren_schrijven', 'uren_accorderen', 'admin']

export const getRoleEnvs = (roles, kind) => {
	return Object.keys(roles)
		.filter(envId => {
			const envRoles = roles[envId]
			return Array.isArray(envRoles) && getRoles(envRoles).includes(kind)
		})
		.map(i => Number(i))
}

export const rolesSelector = createCachedSelector(
	state => state.settings.roles,
	(state, env_id) => env_id || envIdSelector(state),
	(roles, envId) => {
		const _roles = roles[envId] || []
		if (_roles.includes('admin')) return allRoles
		if (_roles.includes('uren_accorderen')) return [..._roles, 'uren_schrijven']
		return _roles
	},
)((state, envId = state.environment.active) => envId)

export const getRoles = roles => {
	if (roles.includes('admin')) return allRoles
	if (roles.includes('uren_accorderen')) return [...roles, 'uren_schrijven']
	return roles
}

export const anyEnvRoleSelector = createSmartCachedSelector(
	state => state.settings.roles,
	(state, roles) => roles,
	(state, roles, envIds) => envIds,
	orgEnvsSelector,
	(roleObj, roles = [], envIds, orgEnvs) => {
		if (!envIds) envIds = orgEnvs.map(e => e.id)
		return hasRoleInAnyEnv(roleObj, roles, envIds)
	},
)

const hasRoleInAnyEnv = (roleObj, neededRoles, envIds) => {
	neededRoles = utils.alwaysDefinedArray(neededRoles)
	if (!roleObj) roleObj = {}
	const relevantEnvs = _.pick(roleObj, envIds)
	const rolesInEnvs = Object.values(relevantEnvs).flat().uniq()
	const roles = getRoles(rolesInEnvs)
	return neededRoles.some(role => roles.includes(role))
}

export const roleSelector = createCachedSelector(
	(state, key, env_id) => rolesSelector(state, env_id),
	(state, key) => key,
	(state, key, env_id) => env_id || envIdSelector(state),
	(roles = [], key, envId) => hasRole(roles, key),
)((state, key, envId = state.environment.active) => `${envId}-${key}`)

const hasRole = (roles, key) => {
	if (!roles || !Array.isArray(roles)) return false
	if (roles.includes('admin')) return true
	const requiredRoles = utils.alwaysDefinedArray(key)
	return requiredRoles.some(r => _hasRole(roles, r))
}

const _hasRole = (roles, role) => {
	if (role === 'uren_schrijven') {
		return roles.includes('uren_accorderen') || roles.includes(role)
	}
	return roles.includes(role)
}

export const schedulableUsers = createSelector(
	state => inEnv(state, 'users'),
	currentEnvSelector,
	(users, env) => users.filter(u => !env.onroosterbaar_user_ids.includes(u.id)),
)

export const hrIntegrationSelector = state => getEnvSetting(state, 'uren_reg', 'koppeling')

export const getEnvSetting = createCachedSelector(
	settingsSelector,
	(state, kind) => kind,
	(state, kind, key) => key,
	(state, kind, key, env_id) => env_id || envIdSelector(state),
	(settings, kind, key, envId) => (settings[envId] && settings[envId][kind] ? settings[envId][kind][key] : null),
)((settings, kind, key, envId) => `${kind}-${key}-${envId}`)

export const orgEnvsSettingsSelector = createSelector(orgEnvsSelector, settingsSelector, (orgEnvs, settings) => {
	const ids = orgEnvs.map(e => e.id)
	const envSettings = _.pick(settings, ids)
	return envSettings
})

export const orgEnvsSettingGroupSelector = createSmartCachedSelector(
	orgEnvsSettingsSelector,
	(state, kind) => kind,
	(settings, kind) => _.mapValues(settings, kind),
)

export const orgEnvSettingSelector = createSmartCachedSelector(
	orgEnvsSettingGroupSelector,
	(state, kind) => kind,
	(state, kind, key) => key,
	(settings, kind, key) => _.map(settings, key),
)

export const orgEnvSettingKeyValueSelector = createSmartCachedSelector(
	orgEnvsSettingGroupSelector,
	(state, group) => group,
	(state, group, key) => key,
	(settings, group, key) => {
		let obj = {}
		Object.keys(settings).forEach(envId => (obj[envId] = settings[envId] && settings[envId][key]))
		return obj
	},
)

export const getEnvSettingGroup = createCachedSelector(
	settingsSelector,
	(state, kind) => kind,
	(state, kind, env_id) => env_id || envIdSelector(state),
	(settings, kind, envId) => (settings[envId] ? settings[envId][kind] : null),
)((settings, kind, envId) => `${kind}-${envId}`)

export const getEnvSettings = createSelector(settingsSelector, envIdSelector, (settings = {}, envId) => settings[envId] || {})

export const getUserSetting = createCachedSelector(
	userSettingsSelector,
	(state, key) => key,
	(settings, key) => settings[key],
)((state, key) => key)
