import {API, find} from '@eitje/easy_api'
import useList from 'components/general/list'
import {contractFields, managerFields, tableFields} from 'constants/users'
import useAdminTeams from 'hooks/use_admin_teams'
import {useContainerDimensions} from 'hooks/use_container_dimensions'
import useParams from 'hooks/use_params'
import {useShared} from 'hooks/use_shared'
import useUserTable from 'hooks/use_user_table'
import _ from 'lodash'
import React, {useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {whereInc} from 'selectors/records'
import Header from '../users/header'
import {NewTeam, Team, TeamControls} from './components'
import './styles/teams.less'
import './styles/teams_footer.less'
import {PageLayout} from 'common/components'

const listProps = {
	ListItem: Team,
}

const Show = () => {
	const {hasManagerRole, hasContractRole, env, orgEnvIds} = useShared()
	const {id} = useParams()
	const tableContainerRef = useRef()
	const {height} = useContainerDimensions(tableContainerRef)
	const team = useSelector(state => find(state, 'teams', id))
	const teamEnv = useSelector(state => find(state, 'environments', team.environment_id))
	const teams = useAdminTeams({environment_id: team.environment_id})

	const items = _.sortBy(teams, t => !t.locked)

	const users = useSelector(state => whereInc(state, 'users', {team_ids: [team.id]}))

	const {list, searchInput: teamSearchInput} = useList({
		items,
		searchProps: {
			searchField: 'naam',
			placeholder: 'Filter team',
			hideEmptyPlaceholder: true,
		},
		hasSearch: true,
		...listProps,
		activeImperative: team.id,
		multiple: false,
	})

	let fields = [tableFields, hasManagerRole && managerFields, hasContractRole && contractFields].filter(Boolean).flat()

	const {searchInput, columnPicker, table} = useUserTable({
		fields,
		envId: team.environment_id,
		items: users,
		style: {maxHeight: height, minHeight: height, height},
		alwaysShowPlaceholder: users.length < 2,
	})

	useEffect(() => {
		API.index('users')
	}, [])

	if (!team.id || !orgEnvIds.includes(teamEnv.id)) return <Redirect to={`/teams/${env.locked_team_id}`} />

	const footer = (
		<div className="teams-footer-container">
			<div className="team-list-row">
				<div className="title-and-search">
					<h4>Teams</h4>
					<h5>&nbsp;({teams.length})</h5>
					<div>{teams.length >= 7 && teamSearchInput}</div>
				</div>

				{list}

				{hasManagerRole && <NewTeam envId={team.environment_id} />}
			</div>

			{!team.locked && hasManagerRole && <TeamControls id="modal" teamId={id} item={team} />}
		</div>
	)

	return (
		<PageLayout name="team" headers={<Header searchInput={searchInput} columnPicker={columnPicker} />} footers={footer}>
			<div ref={tableContainerRef} className="team-members-table">
				{table}
			</div>
		</PageLayout>
	)
}

export default Show
