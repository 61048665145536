import {Text} from 'common/components'
import {EitjeModal} from 'components/common'
import {Association, Operation, BaseConnection} from 'models'
import {InboxList} from 'cores/firm'
import {useGet} from 'hooks'
import {useOperations} from 'cores/firm/components/inbox/components/inbox_list/operations'
import {SyncButton} from 'cores/integrations/pages/index/my_integrations/integration'

export const SyncHistory = () => {
	const item = BaseConnection.show()
	const {data} = useGet(`integration_base_connections/${item.id}/operations`)
	const items = data.items || []
	const rawOps = new Association(items.map(i => new Operation(i)))
	const operations = useOperations({rawOps})

	return (
		<EitjeModal name="provider_syncs" width={450} height={500} insertAtIndex={{1: <SyncButton item={item} colorSet="solid" />}}>
			<InboxList name="provider_syncs" data={operations} height={357} placeholderAnimation="cloud-network" />
		</EitjeModal>
	)
}
