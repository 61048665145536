export const statusColors = {
	// New, overwritten for planning
	available: Colors.lightMediumGreen,
	unavailable: Colors.lightRed,
	partly_available: Colors.lightMediumOrange,
	conflict: Colors.lightRed,

	// Below does not seems to take effect, how to style open shifts white?
	scheduled: Colors.white,
}

export const planningEmojis = {
	sick: '🤒',
	leave_accepted: '🛩️',
}
