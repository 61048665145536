import {addRemoveUsers, updateChannelImage, updateChannelName} from 'actions/chat'
import {Input, Select} from 'antd'
import {ChatStateContext} from 'components/providers/chat_provider'
import {BackButton} from 'common/components'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'
import {useChatContext} from 'stream-chat-react'
import {ImagePicker, UserList} from './ConversationCreatorContainer'
import {makeEitjeUserObj} from './rhs_chat_container/components'

const Option = Select.Option

const useStreamChatContext = () => {
	const {channel = {}} = useChatContext()
	const {me} = useShared()
	const availableUsers = useSelector(state => inEnv(state, 'users', me.environment_ids))
	const {state = {}, data = {}} = channel
	const {name, image} = data
	const {members = {}} = state
	const _members = Object.values(members).map(({user}) => makeEitjeUserObj(user))
	const _membersIds = _members.map(user => user.id)
	const otherUsers = availableUsers.filter(user => !_membersIds.includes(user.id))
	return {users: _members, name, image, otherUsers, channel}
}

export const GroupEditContainer = () => {
	const {users: _users, name, image, otherUsers, channel} = useStreamChatContext()
	const {toggleConversationMaker, setGroupEdit} = useContext(ChatStateContext)
	const [users, setUsers] = useState(_users)
	const [channelName, setChannelName] = useState(name)
	const [chatImageUrl, setChatImageUrl] = useState(image)
	const [usersToAdd, setUsersToAdd] = useState([])

	useEffect(() => {
		if (!_.isEmpty(channel)) {
			channel.on(event => {
				if (event.type === 'channel.updated') {
					setChatImageUrl(event.channel.image)
					setChannelName(event.channel.name)
					setUsers(event.channel.members.map(user => makeEitjeUserObj(user.user)))
				}
			})
		}
	}, [channel?.cid])

	if (_.isEmpty(channel)) return null

	const handleConversationClose = () => {
		toggleConversationMaker(false)
		setGroupEdit(false)
	}

	const handleUserRemove = async user => {
		await addRemoveUsers(channel, 'remove', [user])
	}

	const handleUserAdd = async () => {
		if (usersToAdd.length > 0) {
			const users = otherUsers.filter(user => usersToAdd.includes(user.id))
			await addRemoveUsers(channel, 'add', users)
			setUsersToAdd([])
		}
	}

	const handleNameChange = async () => {
		await updateChannelName(channel, channelName)
	}

	const handleImageChange = async url => {
		setChatImageUrl(url)
		updateChannelImage(channel, url)
	}

	return (
		<div className="channel-details-container edit">
			<div className="channel-details-header">
				<div className="channel-details-title">
					<div className="eitje-heading-xl">{t('editGroup')}</div>
				</div>
				<BackButton onClick={handleConversationClose} />
			</div>

			<div className="flex-grow-one">
				<UserList
					heading={t('groupMembers', {count: users.length})}
					fullUserObj
					users={users}
					disableRemove={users.length <= 3}
					length={users.length}
					onRemove={user => handleUserRemove(user)}
				/>

				<div style={{display: 'flex', width: '100%', padding: '24px 12px', alignItems: 'center', borderBottom: '1px solid #eee'}}>
					<Select
						mode="multiple"
						style={{width: '100%'}}
						showSearch
						placeholder={t('addUserToGroup')}
						value={usersToAdd}
						filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						onChange={setUsersToAdd}
						onBlur={handleUserAdd}
					>
						{otherUsers.map(user => {
							return (
								<Option key={user.id} value={user.id}>
									{user.full_name}
								</Option>
							)
						})}
					</Select>
				</div>
				<div className="input_row">
					<p className="eitje-text-secondary">{t('groupName')}</p>
					<Input bordered={false} value={channelName} onChange={e => setChannelName(e.target.value)} onBlur={handleNameChange} />
				</div>
				<p className="eitje-text-secondary" style={{padding: '12px 0px 0px 12px'}}>
					{t('selectAvatar')}
				</p>
				<ImagePicker url={chatImageUrl} setUrl={handleImageChange} />
			</div>
		</div>
	)
}
export default GroupEditContainer
