import {AuthPageLayout} from 'cores/auth'
import {Input} from '@eitje/form-fields-web'
import {backend} from '@eitje/easy_api'
import {navigate} from 'components/routing'
import {setArbitrary} from 'actions'
import {useShared} from 'hooks'

export const CreateEnvironment = () => {
	const {env, me} = useShared()
	const alreadyInEnv = !_.isEmpty(env)

	// Make inaccessible for authed users that have already created their environment
	if (alreadyInEnv) {
		navigate('/')
		return null
	}

	const onSubmit = data => {
		return backend.post(`/create_trial_environment`, {...data, skipSuccessNotif: true})
	}
	const afterSubmit = () => {
		setArbitrary('createdTrialEnvironment', true)
		navigate('/')
	}

	return (
		<AuthPageLayout name="create_environment" subtitle currentStep={2} steps={2} showSupport onSubmit={onSubmit} afterSubmit={afterSubmit}>
			<Input field="company_name" required />
		</AuthPageLayout>
	)
}
