import {useVisibleColumns, useGroupedColumn, usePinnedColumns, useUnpinnedColumns} from 'common/components/advanced_table'
import {getCoreRowModel, getFilteredRowModel, getExpandedRowModel, getGroupedRowModel, getSortedRowModel} from '@tanstack/react-table'

export const useCachedUserPreferences = TABLE_INFO => {
	const {tableName, fields: allTableColumns} = TABLE_INFO

	const visibleColumns = useVisibleColumns({tableName, allTableColumns})
	const pinnedColumns = usePinnedColumns({tableName})
	const unpinnedColumns = useUnpinnedColumns({tableName})
	const groupedColumn = useGroupedColumn({tableName})

	return {visibleColumns, pinnedColumns, unpinnedColumns, groupedColumn}
}

export const columnVisibilityObject = ({allTableColumns, visibleColumns}) => {
	// Create an object which defines each column as being or not being visible
	let object = {}
	allTableColumns.each(column => (object[column] = visibleColumns.includes(column)))
	return object
}

export const getRowId = (shift, idx, parent) => {
	if (!parent) return `${shift.id}-${shift.user_id}`
	return shift?.id?.toString() || idx
}

export const SETTINGS = {
	getExpandedRowModel: getExpandedRowModel(),
	getGroupedRowModel: getGroupedRowModel(),
	getCoreRowModel: getCoreRowModel(),
	getFilteredRowModel: getFilteredRowModel(),
	getSortedRowModel: getSortedRowModel(),
}
