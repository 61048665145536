import {TimeRegistrationShift} from 'models/index'
import {useShiftsByIds} from 'cores/time_registration/index'
import useQueryParams from 'hooks/use_query_params'
import _MultiEdit from 'common/components/multi_edit_modal'
import {BreaktimePicker, MealPicker, RegTypePicker} from 'components/shared'
import {makeField} from '@eitje/form'
import {useWhere} from '@eitje/easy_api'
import {DeleteCross} from 'components/common/index'
import {t} from 'initializers/i18n'

import {Input, TimePicker} from '@eitje/form-fields-web'
import {useBoolState} from '@eitje/react-hooks'
import {transformShiftData} from 'shared/helpers'
import {useShared} from 'hooks'
import {ListPicker} from 'common/components'

const TeamPicker = () => {
	const {envId} = useShared()
	const teams = useWhere('teams', {environment_id: envId})
	return <ListPicker single dropdown required title="teams" items={teams} labelField="naam" field="team_id" />
}

export const MultiEdit = () => {
	const {ids} = useQueryParams()
	const shifts = useShiftsByIds(ids)
	const [startPlanning, setStartPlanning] = useBoolState(false)
	const [endPlanning, setEndPlanning] = useBoolState(false)
	const {isMultiOrg} = useShared()

	const teamField = {
		field: 'team_id',
		Component: <TeamPicker />,
	}

	// TODO: refactor once planning allows team general

	const formFields = () => {
		return [
			{
				field: 'from',
				condition: !startPlanning,
				Component: (
					<TimePicker
						hideClearIcon={false}
						field="from"
						renderExtraFooter={prop => <ExtraFooter {...prop} setPlanning={setStartPlanning} />}
					/>
				),
			},
			{
				field: 'from',
				condition: startPlanning,
				Component: <FormSkeleton field="from" unset={setStartPlanning} />,
			},
			{
				field: 'till',
				condition: !endPlanning,
				Component: <TimePicker field="till" renderExtraFooter={props => <ExtraFooter {...props} setPlanning={setEndPlanning} />} />,
			},
			{
				field: 'till',
				condition: endPlanning,
				Component: <FormSkeleton field="till" unset={setEndPlanning} />,
			},
			{
				field: 'pauze_duur',
				Component: <BreaktimePicker />,
			},
			{
				field: 'maaltijd',
				Component: <MealPicker />,
			},
			{
				field: 'typ',
				Component: <RegTypePicker />,
			},
			{
				field: 'remarks',
				Component: <Input textarea field="remarks" autoSize={{minRows: 1, maxRows: 6}} />,
			},
			!isMultiOrg && teamField,
		].filter(Boolean)
	}

	return <_MultiEdit shifts={shifts} type="time_registration_shift" submit={data => handleSubmit({shifts, data})} formFields={formFields} />
}

const ExtraFooter = ({setPlanning}) => {
	return (
		<div onClick={setPlanning}>
			<p className="use-planned-time-text"> {t('modals.multi_edit.use_planning_time')} </p>
		</div>
	)
}

const Skeleton = ({unset}) => {
	return (
		<div className="use-planned-time">
			<p className="use-planned-time-text"> {t('modals.multi_edit.use_planning_time')} </p>
			<DeleteCross confirm={false} onDel={unset} />
		</div>
	)
}

export const FormSkeleton = makeField(Skeleton)

const handleSubmit = ({shifts, data}) => {
	const {columns = []} = data
	if (columns.find(c => c == 'from') && !data['from']) data['start_planning'] = true
	if (columns.find(c => c == 'till') && !data['till']) data['end_planning'] = true

	transformShiftData(data)

	const items = shifts.map(s => {
		if (s.isPlan()) return {plan_shift_id: s.id, ...data}
		return {id: s.id, ...data}
	})
	return TimeRegistrationShift.multiUpdate(items)
}
