import {t as baseT} from 'initializers/i18n'
import {usePerMemberContext} from 'cores/time_registration/index'
import {useArbitrary} from 'hooks/index'
import {ListPicker} from 'common/components'

const periods = ['day', 'week', 'month', 'wage', 'custom']
const wagePeriodItems = ['uren', 'nul_uren']

export const PeriodTypePicker = ({height, ...rest}) => {
	const wagePeriods = useArbitrary('periods')

	const {period, setRawDates, startDate, setPeriod} = usePerMemberContext()

	const changePeriod = newPeriod => {
		if (newPeriod == 'wage') newPeriod = 'wage_uren'
		if (period == newPeriod) return
		const startOfWeek = startDate.clone().startOf('week')

		if (newPeriod == 'week') return setRawDates(startOfWeek, startDate.clone().endOf('week'))
		if (newPeriod == 'day') return setRawDates(startOfWeek, startOfWeek)
		if (newPeriod == 'month') return setRawDates(startDate.clone().startOf('month'), startDate.clone().endOf('month'))

		if (newPeriod == 'wage_uren' || newPeriod == 'wage_nul_uren') {
			const oldWagePeriods = wagePeriods[period.replace('wage_', '')]
			const newWagePeriods = wagePeriods[newPeriod.replace('wage_', '')]
			let currentPeriodIdx = newWagePeriods.length - 2 // we have next, current, and 23 before

			if (oldWagePeriods) {
				currentPeriodIdx = oldWagePeriods.findIndex(per => per.start == startDate.format())
			}

			const periodObj = newWagePeriods[currentPeriodIdx]
			return setRawDates(periodObj.start, periodObj.eind, newPeriod)
		}

		if (newPeriod == 'custom') {
			setPeriod(newPeriod)
			setTimeout(() => {
				const el = document.querySelector('.eitje-range-picker')
				el?.click()
			}, 10)
		}
	}

	const periodChildren = {
		wage: wagePeriodItems.map(name => ({value: `wage_${name}`, label: baseT(`common.periods.${name}`)})),
	}

	const formattedPeriods = periods.map(p => ({value: p, label: baseT(`common.periods.${p}`), children: periodChildren[p]}))

	return (
		<ListPicker
			noSort
			single
			raw
			dropdown
			height={height}
			labelField="label"
			valueField="value"
			items={formattedPeriods}
			value={period}
			onChange={changePeriod}
			{...rest}
		/>
	)
}
