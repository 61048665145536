import {ColorBox} from 'common/components'
import {ModalLink} from 'components/routing'
import {t} from 'initializers/i18n'
import pluralize from 'pluralize'
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {whereInc} from 'selectors/records'
import './styles/teams.less'

export const Team = ({item, isActive}) => {
	const users = useSelector(state => whereInc(state, 'users', {team_ids: item.id}))
	const teamColor = {...(item?.color && isActive && {style: {backgroundColor: item.color}})}
	return (
		<Link to={`/teams/${item.id}`} {...teamColor}>
			<div className="team-card list-item-text-container">
				<p className="team-member-count list-item-sub">{pluralize(t('teamMember'), users.length, true)}</p>
				<p className="team-name list-item-title">
					<ColorBox marginRight color={item.color} />
					{item.naam}
				</p>
			</div>
		</Link>
	)
}

export const NewTeam = ({onClick, envId}) => {
	return (
		<ModalLink data-intercom-target="new-team" to={`/teams/${envId}/new`}>
			<div className="dashed-boxed-item" onClick={onClick}>
				<h5 className="bold"> + {t('addTeam')} </h5>
			</div>
		</ModalLink>
	)
}

const TeamManageButton = ({inactive, active, text}) => {
	const [mouseIn, setMouseIn] = useState(false)
	const handleMouseMove = () => setMouseIn(s => !s)

	return (
		<div onMouseOver={() => handleMouseMove()} onMouseOut={() => handleMouseMove()} className="action-hover-button">
			<img className="margin-right-sm" style={{width: 28}} src={mouseIn ? active : inactive} />
			<p className="eitje-text-secondary">{text}</p>
		</div>
	)
}
// TODO - turn this into an array of link objs

export const TeamControls = ({item = {}}) => {
	return (
		<div className="team-controls" style={{marginBottom: 8}}>
			<p className="team-name" style={{width: 160}}>
				{item.naam}
			</p>

			<div className="team-action-container">
				<ModalLink to={`/teams/${item.id}/add_member`}>
					<TeamManageButton
						text={t('addToTeam')}
						inactive={'/images/web/icons/addTeamMemberGrey.png'}
						active={'/images/web/icons/addTeamMember.png'}
					/>
				</ModalLink>

				<ModalLink to={`/teams/${item.id}/edit`}>
					<TeamManageButton
						text={t('teamSettings')}
						inactive={'/images/web/icons/teamSettingsGrey.png'}
						active={'/images/web/icons/teamSettings.png'}
					/>
				</ModalLink>

				<ModalLink to={`/teams/${item.id}/remove_member`}>
					<TeamManageButton
						text={t('delFromTeam')}
						inactive={'/images/web/icons/removeTeamMemberGrey.png'}
						active={'/images/web/icons/removeTeamMember.png'}
					/>
				</ModalLink>
			</div>
		</div>
	)
}
