import {API, backend} from '@eitje/easy_api'
import * as DB from 'actions/entities'
import {store} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import * as jsUtils from 'utils/jsutils'

export const onPopoverShow = id => {
	store.dispatch({type: 'TOGGLE_PLANNING_POPOVER', id: id})
}

export const postShift = async (prms, {afterSucc = () => {}} = {}) => {
	const res = await backend.post(`shifts`, prms)
	if (res.ok && !res.data.errors) {
		initPlanning()
		return true
	} else {
		jsUtils.errNotif(t('wrong'), res.data.errors ? res.data.errors : res.data[0])
		return false
	}
}

export const weekPickerSwitch = date => {
	const week = date.isoWeek()
	const year = date.isoWeekYear()
	initPlanning({week: week, year: year})
}

export const weekSwitch = (amt, callback = initPlanning) => {
	const state = store.getState()
	const {week, year} = state.rooster
	const d = jsUtils.getDateFromWeek(0, week, year)
	d.add(amt, 'w')
	initPlanning({week: d.isoWeek(), year: d.isoWeekYear()})
}

export const initPlanning = async ({week, year} = {}) => {
	const state = store.getState()
	week = week || state.rooster.week
	year = year || state.rooster.year
	getPlanning({week, year})
}

export const updateDay = (date, params) => {
	const state = store.getState()
	const days = state.rooster.days
	const newDays = days.map(d => (d.date === date ? {...d, ...params} : d))
	store.dispatch({type: 'UPDATE_DAYS', days: newDays})
}

export const getDays = async ({week, year}) => {
	const res = await backend.get(`new_days`, {week, year})
	if (res.ok) {
		const json = res.data
		const days = jsUtils.getWholeWeek(week, year)
		const final = _.sortBy(_.uniqBy([...res.data.days, ...days], 'dateString'), 'dateString')
		store.dispatch({type: 'GET_DAYS', days: final})
	}
}

const planningMapper = s => {
	const team = DB.fetchEntity('team', s.team_id) || {}
	return {...s, environment_id: team.environment_id}
}

const avMapper = av => {
	const user = DB.fetchEntity('user', av.user_id) || {}
	return {...av, full_name: user.full_name}
}

export const getBeschPlanning = async ({start, eind}) => {
	const res = await backend.get(`new_besch_planning`, {start, eind})
	if (res.ok) {
		const json = res.data
		const shifts = json.shifts.map(s => planningMapper(s))
		store.dispatch({
			type: 'GET_BESCH_PLANNING',
			shifts: shifts,
			days: json.days,
			avUsers: json.avs.map(a => avMapper(a)),
			teamDays: json.team_days,
		})
	}
}

export const getPlanning = async ({week, year}) => {
	const state = store.getState()
	if (week != state.rooster.week || year != state.rooster.year) {
		store.dispatch({type: 'PLANNING_LOADING'})
	}

	const res = await backend.get(`new_planning`, {week, year})
	if (res.ok) {
		const json = res.data
		const days = jsUtils.getWholeWeek(week, year)
		const final = _.sortBy(_.uniqBy([...res.data.days, ...days], 'dateString'), 'dateString')
		const shifts = json.shifts.map(s => planningMapper(s))
		API.createMultiLocal('events', json.events)
		store.dispatch({
			type: 'GET_PLANNING',
			shifts: shifts,
			week: week,
			year: year,
			days: final,
			workweeks: json.workweeks,
			avUsers: json.avs.map(a => avMapper(a)),
			events: json.events,
			teamDays: json.team_days,
		})
	}

	store.dispatch({type: 'PLANNING_DONE'})
}
