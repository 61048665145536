import {useUserContext} from 'contexts/user'
import {ShiftAvatar} from 'cores/planning/components/shift_avatar'
import './styles/index.less'
import ReactHoverObserver from 'react-hover-observer'
import Dropdown from 'components/dropdown'
import {useRef} from 'react'
import {makeCnVariants} from 'helpers'
import utils from '@eitje/web_utils'

// make distinction between usercard & userhovercard?

export const UserCard = ({
	sublineProps = {},
	Subline,
	dropdownProps = {},
	user,
	name,
	size = 'small',
	dropdownElements = [],
	isHovering,
	RightComponent,
	noBorders,
}) => {
	const dropdownRef = useRef()
	const {user: contextUser} = useUserContext()
	const classNames = utils.makeCns(
		makeCnVariants('user-card', size, dropdownElements.length && 'with-dropdown'),
		noBorders && 'user-card-without-borders',
	)
	user = user?.id ? user : contextUser

	const handleClick = () => dropdownRef.current?.toggle?.()

	const _RightComponent = () =>
		RightComponent ? (
			<RightComponent isHovering={isHovering} dropdownRef={dropdownRef} />
		) : (
			<>
				{dropdownElements.length && isHovering ? (
					<Dropdown {...dropdownProps} ref={dropdownRef} elements={dropdownElements}>
						<div onClick={handleClick} className="user-dropdown-trigger">
							...
						</div>
					</Dropdown>
				) : null}
			</>
		)

	return (
		<div className={classNames}>
			<div className="inner-cell-contents-wrapper">
				<div className="user-avatar-wrapper">
					<ShiftAvatar size={size} user={user} />
				</div>
				<div className="user-card-sub-wrapper">
					<h5 className="user-name"> {name || user?.full_name} </h5>
					{Subline && (
						<h5 data-intercom-target="user-card-subline">
							<Subline {...sublineProps} />
						</h5>
					)}
				</div>
				<_RightComponent />
			</div>
		</div>
	)
}

export const UserHoverCard = ({delay, ...rest}) => {
	return (
		<ReactHoverObserver hoverOffDelayInMs={delay} className="user-hover-card">
			<UserCard {...rest} />
		</ReactHoverObserver>
	)
}
