import {channelData} from 'actions/chat'
import {useSelector} from 'react-redux'
import {whereInc} from 'selectors/records'
import {useChannelStateContext} from 'stream-chat-react'
import SmartAvatar from './SmartAvatar'

const onlineMessage = (channel, modular, user) => {
	if (Object.keys(channel.state.watchers).length === 1) return ''

	const names = Object.values(channel.state.watchers)
		.filter(onlineU => Number(onlineU.id) !== user.id)
		.map(user => user.name)
		.join(', ')

	return channel.data.member_count > 2 && !modular ? `Online - ${names}` : 'Online'
}

const ChannelHeader = () => {
	const {channel} = useChannelStateContext()
	const user = useSelector(state => state.auth.user)
	const ent = useSelector(state => whereInc(state, 'environments', {user_ids: user.id}))
	const {displayImage, displayName, teamName, otherUser = {}} = channelData(channel, user.id, ent)
	const online = channel.state.watcher_count > 1 && channel.type === 'messaging'

	return (
		<div className="row padding-left-sm border-bottom" style={{height: '56px'}}>
			<SmartAvatar size="md" userId={otherUser.id} name={displayName} image={displayImage} online={online} />
			<div>
				<p className="bold">{displayName}</p>
				<p className="eitje-text-secondary">{teamName}</p>
			</div>
		</div>
	)
}

export default ChannelHeader
