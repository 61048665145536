import {InvoicesAdvancedTable} from 'common/components/advanced_table/instances/invoices'
import {FilterFooter, FilterFooterProvider, PageLayout} from 'common/components'
import {t} from 'initializers/i18n'
import {Invoice} from 'models'
import {PageHeader} from './page_header'

const invoiceStatusFilterObject = {
	name: 'status',
	items: ['paid', 'unpaid', 'in_transaction', 'chargeback', 'external', 'zero_ignore'],
	labelT: label => t(`billing.statusses.${label}`),
	filterFunction: (invoice, statusses) => {
		const status = invoice.status
		return !statusses.length || statusses.includes(status)
	},
}

export const Invoices = ({}) => {
	const items = Invoice.doIndex()
	const filters = [invoiceStatusFilterObject]

	return (
		<FilterFooterProvider id="billing" items={items} filters={filters}>
			<PageLayout name="invoices" headers={<PageHeader />} footers={<FilterFooter />}>
				<InvoicesAdvancedTable />
			</PageLayout>
		</FilterFooterProvider>
	)
}
