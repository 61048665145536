import {where} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {PopoutCard} from '@eitje/web_components'
import {initConversation, modularClick, userSearch} from 'actions/chat'
import {Button, Checkbox, Input, Select} from 'antd'
import useList from 'components/general/list'
import {UserAvatar} from 'components/ui'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {inEnv} from 'selectors/records'
import {Avatar, ChatContext} from 'stream-chat-react'
import {useDebouncedCallback} from 'use-debounce/lib'
import * as jsUtils from 'utils/jsutils'
import {errNotif} from 'utils/jsutils'
import {ChatStateContext} from '../../providers/chat_provider'
import SmartAvatar from '../SmartAvatar'
import GroupEditContainer from './ChatGroupEditContainer'
import '../styles/chat.less'

const Option = Select.Option

const UserSelector = ({setSelectedUsers, usersList, selectedUsers, modular}) => {
	return (
		<>
			{usersList.map(user => {
				return (
					<div
						className="checkbox-row-lg align-items-center"
						key={user.id}
						onClick={() => setSelectedUsers(jsUtils.addOrRemove(selectedUsers, user.id, !user.id))}
					>
						<SmartAvatar userId={user.id} name={user.full_name} image={user.avatar} size={'lg'} />
						<div className="flex-grow-one">
							<p className="bold">{user['full_name']}</p>
						</div>
						<div className="margin-right-sm">
							<Checkbox value={user.id} type={'radio'} checked={selectedUsers.includes(user.id)} />
						</div>
					</div>
				)
			})}
		</>
	)
}
const selectAvatarImageUrl = '/images/selectAvatar.png'
const imageURLs = [...Array(12).keys()].map((_, i) => `https://dh4gz9vp93q2o.cloudfront.net/images/teamAva${i + 1}.png`)

const formValidator = (selectedUsers, selectedURL, groupName) => {
	if (selectedUsers.length === 1) {
		return true
	}
	if (selectedUsers.length > 1) {
		if (selectedURL.length === 0 || groupName.length === 0) {
			errNotif(t('groupErrorHead'), t('groupErrorBody'))
			return false
		}
		return true
	}
}

export const ImagesSelection = ({selectedUrl, setUrl}) => {
	return (
		<div className="chat-image-box avatarPopOverSelectorSpace" style={{display: 'flex', flexWrap: 'wrap', width: '156px'}}>
			{imageURLs.map(url => (
				<div
					className="avatarPopOverSelectorSpace"
					onClick={() => {
						setUrl(url)
					}}
					key={url}
				>
					<SmartAvatar size="lg" selected={selectedUrl === url} image={url} />
				</div>
			))}
		</div>
	)
}

export const ImagePicker = ({url, setUrl, border = true, defaultUrl = selectAvatarImageUrl, setAvatarPopRef}) => {
	const cN = utils.makeCns('chat-image-picker-container avatarPopOverSelectorSpace', border && 'bordered')
	return (
		<div className={cN}>
			<PopoutCard
				body={<ImagesSelection setAvatarPopRef={setAvatarPopRef} selectedUrl={url} setUrl={setUrl} />}
				trigger="click"
				placement="right"
			>
				<div>
					<Avatar image={url ? url : defaultUrl} size={100} />
				</div>
			</PopoutCard>
		</div>
	)
}

const ConversationCreatorContainer = () => {
	const [isChannelSetupMenu, setChannelSetupMenu] = useState(false)
	const [searchCharacters, setSearchCharacters] = useState('')
	const [selectedUsers, setSelectedUsers] = useState([])
	const [groupName, setGroupName] = useState('')
	const [selectedURL, setSelectedURL] = useState('')
	const {me} = useShared()
	const outsideAreaRef = useRef()

	const {
		modular,
		setCurrentChannel,
		showConversationMaker,
		setActivelySelectedChannels,
		isGroupEdit,
		toggleChatDropdown,
		setChannelFilter,
		toggleConversationMaker,
	} = useContext(ChatStateContext)

	const currentEnv = useSelector(state => state.environment.active)
	const currentUserId = useSelector(state => state.auth.user.id)

	const isSingleCreator = selectedUsers.length === 1
	const isGroupCreator = selectedUsers.length > 1
	const hasSelectedusers = selectedUsers.length > 0

	const usersWithoutUser = useSelector(state => inEnv(state, 'users', me.environment_ids)).filter(user => user.id !== me.id)
	const nonSelectedUsers = usersWithoutUser.filter(user => !selectedUsers.includes(user.id))

	useEffect(() => {
		return () => {
			setSelectedUsers([])
			setSearchCharacters('')
			setGroupName('')
		}
	}, [])

	const debounce = useDebouncedCallback(async chat => {
		const newChannel = await initConversation([...selectedUsers, currentUserId], currentUserId, currentEnv, groupName, selectedURL)
		modular
			? modularClick(newChannel, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown)
			: chat.setActiveChannel(newChannel)
		toggleConversationMaker()
	}, 400)

	const handleCreateClick = async chat => {
		if (isSingleCreator || isChannelSetupMenu) {
			//create a single channel or the final groupd menu is finished
			if (formValidator(selectedUsers, selectedURL, groupName)) {
				debounce.callback(chat)
			}
			return
		}
		if (isGroupCreator) {
			// pass through to the second channel create screen for more options to create a group
			setChannelSetupMenu(true)
		}
		return
	}

	if (isGroupEdit) return <GroupEditContainer />

	const formButtonHighlight = !isChannelSetupMenu ? hasSelectedusers : groupName.length > 0 && selectedURL.length > 0

	return (
		<div className="channel_list create_channel" style={{position: 'absolute'}}>
			<div className="container_header">
				<div className="row-with-justify-between">
					<h3>{t('startNewChannel')}</h3>

					<div className="back_button" onClick={() => toggleConversationMaker()}>
						<img src="/images/web/icons/left_return_arrow.png" style={{width: 14, height: 14}} />
					</div>
				</div>
			</div>

			{!isChannelSetupMenu && (
				<>
					<div style={{padding: '0px 12px 12px 12px'}}>
						<Input
							id="search-legacy"
							placeholder={t('searchTeamMember')}
							value={searchCharacters}
							onChange={e => {
								setSearchCharacters(e.target.value)
							}}
						/>
					</div>
					<div style={{flex: 1, overflow: 'scroll'}}>
						<UserSelector
							setSelectedUsers={setSelectedUsers}
							usersList={userSearch(usersWithoutUser, searchCharacters)}
							selectedUsers={selectedUsers}
							modular={modular}
						/>
					</div>
				</>
			)}

			{isChannelSetupMenu && (
				<div className="start_menu_continued" style={{flex: 1, overflow: 'scroll'}}>
					<div>
						<UserList
							heading={t('groupMembers', {count: selectedUsers.length + 1})}
							userIds={[...selectedUsers, me.id]}
							disableRemove={selectedUsers.length <= 2}
							onRemove={id => setSelectedUsers(jsUtils.addOrRemove(selectedUsers, id, !id))}
						/>
						<div style={{display: 'flex', width: '100%', padding: '24px 12px', alignItems: 'center', borderBottom: '1px solid #eee'}}>
							<Select
								ref={outsideAreaRef}
								mode="multiple"
								style={{width: '100%'}}
								showSearch
								placeholder={t('addUserToGroup')}
								value={[]}
								filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								onChange={(user = []) => {
									outsideAreaRef.current.blur()
									setSelectedUsers(curr => [...curr, user[0]])
								}}
							>
								{nonSelectedUsers.map(user => {
									return (
										<Option key={user.id} value={user.id}>
											{user.full_name}
										</Option>
									)
								})}
							</Select>
						</div>
					</div>
					<div className="input_row">
						<p className="eitje-text-secondary">{t('groupName')}</p>
						<Input
							required
							bordered={false}
							placeholder={t('enterGroupName')}
							value={groupName}
							onChange={e => {
								setGroupName(e.target.value)
							}}
						/>
					</div>
					<p className="eitje-text-secondary" style={{padding: '12px 0px 0px 12px'}}>
						{t('selectAvatar')}
					</p>
					<ImagePicker url={selectedURL} setUrl={setSelectedURL} />
				</div>
			)}

			<CreatorFooter highlight={formButtonHighlight} onClick={handleCreateClick} count={selectedUsers.length} />
		</div>
	)
}

const UserItemRow = ({item, onRemove, onRowClick, disableRemove, fullUserObj}) => {
	const {me} = useShared()
	const notCurrentUser = item.id !== me.id

	const handleRemove = e => {
		if (onRemove) {
			e.stopPropagation()
			onRemove(fullUserObj ? item : item.id)
		}
	}

	return (
		<>
			<div className="chat-list-item-content">
				<UserAvatar size="md" user={item} />
				<p className="eitje-text-secondary">{item.full_name}</p>
			</div>

			{notCurrentUser && onRemove && !disableRemove && (
				<div onClick={handleRemove} className="remove-button">
					<img src="/images/web/icons/soleCrossIcon.png" height={8} width={8} />
				</div>
			)}
			{notCurrentUser && onRowClick && <img className="row_icon" src={'images/downIcon.svg'} />}
		</>
	)
}

export const UserList = ({
	users,
	userIds,
	fullUserObj,
	onRemove,
	disableRemove,
	heading,
	onRowClick,
	style = {},
	headingComponent = null,
}) => {
	const foundUsers = useSelector(state => where(state, 'users', userIds))
	const _users = users || foundUsers
	const orderedUsers = _.orderBy(_users, [user => user.full_name.toLowerCase()], ['asc'])

	const {list} = useList({
		items: orderedUsers,
		ListItem: UserItemRow,
		onClick: user => onRowClick && onRowClick(fullUserObj ? user : user.id),
		itemProps: {onRemove, disableRemove, onRowClick, fullUserObj},
	})

	return (
		<div className="small-list-container" style={style}>
			<div className="row margin-bottom-md">
				<p className="bold">{heading}</p>
				{headingComponent}
			</div>
			{list}
		</div>
	)
}

const CreatorFooter = ({highlight, onClick, count}) => {
	return (
		<div className="footer">
			<div style={{flex: 1, alignSelf: 'center'}}>
				{!!count ? (
					<p className="eitje-text-secondary text-multi-line">{t('startConversation', {count})}</p>
				) : (
					<p className="eitje-text-secondary">{t('selectMember')}</p>
				)}
			</div>
			<ChatContext.Consumer>
				{chat => (
					<Button type={highlight && 'primary'} onClick={() => (count ? onClick(chat) : _.noop)}>
						{t('create')}
					</Button>
				)}
			</ChatContext.Consumer>
		</div>
	)
}

export default ConversationCreatorContainer
