import {backend} from '@eitje/easy_api'
import {store} from 'index'
import {date} from 'initializers/date'
import {getTeam} from 'selectors/entities'

export async function getPlusMin() {
	const res = await backend.get(`roosters/new_plus_min_saldo.json`)
	if (res.ok && res.data) {
		const json = res.data
		const obj = {}
		json.users.forEach(u => (obj[u.id] = u.diff))
		store.dispatch({type: 'GET_PLUS_MIN', data: obj})
	}
}

export async function postHours(shifts, exportZzp = false) {
	const json = shifts.map(s => {
		s.start = s.start_date
		s.end = s.end_date
		return s
	})
	const res = await backend.put(`hours/mass_update`, {shifts: json, export_zzp: exportZzp ? exportZzp : null})
	const hasErrors = Object.keys(res.data.errors).length > 0
	if (hasErrors) {
		store.dispatch({type: 'SET_ACCORD_ERRORS', errors: res.data.errors})
	}
	return {errors: res.data.errors, ok: res.ok}
}

const formatShift = (shift, format = 'YYYY-MM-DDTHH:mm:ssZ') => {
	const start = date(shift.start_date)
	const till = date(shift.end_date)
	if (shift.start_date > shift.end_date) {
		shift.end_date = date(shift.end_date).add(1, 'day').format(format)
	}
	if (till.diff(start, 'm') > 1440) {
		shift.end_date = date(shift.end_date).subtract(1, 'day').format(format)
	}

	return shift
}

export async function saveShift(shift) {
	shift = formatShift(shift)
	store.dispatch({type: 'SAVE_SHIFT', shift: shift})
}

export async function getAccordShifts(startDate, endDate, extra = {}) {
	store.dispatch({type: 'SHIFTS_LOADING'})
	const res = await backend.get('new_uren', {from: startDate, till: endDate, ...extra})

	if (res.ok) {
		const shifts = res.data.shifts.map(s => shiftMapper(s))
		store.dispatch({type: 'GET_SHIFTS', shifts: shifts, checkIns: res.data.check_ins})
	}
	store.dispatch({type: 'SHIFTS_LOADED'})
}

export const shiftMapper = s => {
	const state = store.getState()
	const team = getTeam(state, s.team_id)
	if (team) s.environment_id = team.environment_id
	s.original_start_date = s.start_date
	s.original_end_date = s.end_date
	return s
}

export async function getStandardDays() {
	const res = await backend.get(`roosters/standard_days`)
	if (res.ok) {
		store.dispatch({type: 'STANDARD_DAYS', days: res.data.standard_days, team_days: res.data.team_days, wwks: res.data.wwks})
	}
}
