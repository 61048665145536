import React, {Fragment, useRef, useState} from 'react'
import {PopoutCard} from '@eitje/web_components'
import useEnvSetting from 'hooks/use_env_setting'
import {LegacyCheckbox as Checkbox} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import Dropdown from 'components/dropdown'
import {ModalLink} from 'components/routing'
import {startConversation} from 'actions/support'
import {inviteAllUsers} from 'actions/entities'
import {saveSettingGroup} from 'actions/settings'
import useOnboarding from 'cores/onboarding/hooks/use_onboarding'
import './onboarding.less'
import {EitjeButton, Layout} from 'common/components'
import {SubmitPopout} from 'common/components'

const MARGIN = [0, 8]
const PADDING = [4, 8]

const Onboarding = () => {
	return (
		<div className="start-eitje-header-right-content">
			<OnboardingButton />
			<OnboardingInput />
			<NeedHelp />
		</div>
	)
}

const OnboardingButton = () => {
	const {stepPct} = useOnboarding()
	if (stepPct >= 100) return null
	return (
		<ModalLink to="/onboarding/drawer">
			<div className="progression-container">
				<h5 className="progression-text">
					{stepPct}% {t('onboarding.buttons.learning_eitje')}{' '}
				</h5>
				<p className="next-step-text"> {t('common.next_step')} </p>
			</div>
		</ModalLink>
	)
}

const OnboardingInput = () => {
	const [confirmV, setConfirmV] = useState()
	const demoActive = useEnvSetting('onboarding', 'active')
	const Wrapper = demoActive ? SubmitPopout : Fragment
	const saveSetting = () => saveSettingGroup('onboarding', {active: !demoActive})
	const onChange = demoActive ? _.noop : saveSetting
	const onConfirm = () => {
		saveSetting()
		inviteAllUsers()
	}

	const checkbox = useRef(null)
	return (
		<PopoutCard className="onboarding-mode-switch" hidden={confirmV} title={t('onboarding.tooltips.demo_mode')}>
			<Layout type="card" onClick={() => checkbox.current.input.click()} card padding={PADDING} margin={MARGIN}>
				<Wrapper
					onVisibleChange={setConfirmV}
					onSubmit={onConfirm}
					submitText={t('onboarding.buttons.send_invite')}
					cancelText={t('onboarding.buttons.no_invite')}
					title={t('onboarding.title.demo_mode')}
					text={t('onboarding.confirm.demo_mode')}
					buttons={<EitjeButton onClick={saveSetting} t="onboarding.buttons.no_invite" />}
				>
					<Checkbox innerRef={checkbox} defaultValue={demoActive} onChange={onChange} />
				</Wrapper>
				<h4 className="onboarding-mode-switch-text"> {t('onboarding.buttons.demo_mode')} </h4>
			</Layout>
		</PopoutCard>
	)
}

const demoUrl = 'https://calendly.com/eitje-joris/eitje-demo'

const NeedHelp = () => {
	const ddElements = [
		<p onClick={startConversation}> {t('startSupport')} </p>,
		<a target="_blank" href={demoUrl} rel="noreferrer">
			{t('onboarding.texts.schedule_a_demo')}
		</a>,
	]
	return (
		<Dropdown overlayClassName="need-help-dropdown" elements={ddElements}>
			<p className="need-help-text">
				{' '}
				{t('needHelp')}? <img src="/images/web/icons/dropdown_outline.png" className="down-icon" />{' '}
			</p>
		</Dropdown>
	)
}

export default Onboarding
