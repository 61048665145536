import {PopoverContent} from 'components/users'
import {hideHeaders, rowProps, searchProps, showEmpty, stickyFields} from 'constants/users'
import {renderCell} from 'helpers/users'
import {useAllActiveContracts} from 'hooks/use_contract'
import useShared from 'hooks/use_shared'
import {useSelector} from 'react-redux'
import {roleOrgEnvsSelector} from 'selectors/new_settings'
import useTable from './use_table'
import {useNoTeamMembersPlaceholder} from 'shared'
import {Text} from 'common/components'

const getOutConts = (users, contractEnvs) => {
	return users.map(u => {
		const {contract} = u
		const mayPass = !contract?.environment_id || contractEnvs.includes(contract.environment_id)
		if (mayPass) return u
		return {...u, contract: null, salary: null, employmentType: null}
	})
}

const layoutProps = {vertical: 'top', padding: [72, 0, 0, 0]}

export function useUserTable({
	name = 'userTable',
	popoverClassname = 'employed-popover',
	envId,
	actionMenu,
	disableTeams,
	fields,
	items,
	...rest
} = {}) {
	const contractEnvs = useSelector(state => roleOrgEnvsSelector(state, 'contracten')).map(e => e.id)
	const {hasManagerRole, envId: currentEnvId} = useShared()

	const defActionMenu = (item, {...rest}) => (
		<PopoverContent env={envId || currentEnvId} disableTeams={disableTeams} user={item} {...rest} />
	)
	const tableProps = {
		showEmpty,
		renderCell,
		hideHeaders,
		actionTitle: item => item.full_name,
		actionMenu: actionMenu || defActionMenu,
	}

	items = useAllActiveContracts(items)
	items = getOutConts(items, contractEnvs)

	const teamPlaceholderProps = useNoTeamMembersPlaceholder({layoutProps})
	const _rowProps = {...rowProps, showActions: hasManagerRole, popoverClassname}

	return useTable({
		name,
		tableProps,
		rowProps: _rowProps,
		stickyFields,
		searchProps,
		fields,
		items,
		placeholderProps: teamPlaceholderProps,
		searchEmptyProps: {layoutProps},
		...rest,
	})
}

export default useUserTable
