import {useUserContext} from 'contexts/user'
import {t} from 'initializers/i18n'
import {EitjeDropdown, Text, viewOptionsDropdownItem} from 'common/components'
import {usePlanningContext} from 'cores/planning'
import {useGetPlanningShiftUrlWithEnv} from 'cores/planning/helpers'
import {useUserPanelDropdownOptions} from 'common/components'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'
import {useRoleMessage, useShared} from 'hooks'

export const PanelDropdown = ({...rest}) => {
	const {dateRange} = usePlanningContext()
	const {user, shifts} = useUserContext()
	const createShiftUrl = useGetPlanningShiftUrlWithEnv({user})
	const notManager = useRoleMessage({roles: 'manager', env: user.environment_ids})
	const userOptions = useUserPanelDropdownOptions({user, dateRange, createShiftUrl, disableCreateShift: notManager})

	const elements = [
		...userOptions,
		useSelectPlanningShifts({shiftIds: shifts.ids(), disabled: notManager}),
		userViewOptions({disabled: notManager}),
		useShiftViewOptions(),
	]

	return <EitjeDropdown elements={elements} {...rest} strategy="fixed" />
}

export function userViewOptions({disabled}) {
	const buttons = ['UserAvailability', 'UserPlanned', 'ContractHours']
	return viewOptionsDropdownItem({record: 'user', buttons, disabled, namespace: 'planningPerMemberShow'})
}

export function useShiftViewOptions() {
	const {multiEnvOrg} = useShared()
	const buttons = ['TeamColor', multiEnvOrg && 'Environment', 'Team', 'Type', 'Skill', 'Remarks']
	return viewOptionsDropdownItem({record: 'planning_shift', buttons, namespace: 'planningPerMemberShow'})
}

export function useSelectPlanningShifts({shiftIds, disabled}) {
	const {setMultiEdit, setSelectedShifts} = useEnvsContext()
	const disableSelectShifts = shiftIds.length === 0 && t('time_registration.week_table_days_header.disabled.no_shifts_to_select')
	const onClick = () => setSelectedShifts(shiftIds)

	return {
		icon: 'pencil',
		onClick,
		element: <Text t="planning.week_table_days_header.multi_edit_day_shifts" />,
		disabled: disabled || disableSelectShifts,
	}
}
