import {Fragment} from 'react'
import {t} from 'initializers/i18n'
import useSearch from 'hooks/use_search'
import {Skills, AmtWorked, usePlanningDates} from 'cores/planning/index'
import {makeCnVariants} from 'helpers'
import {useNoTeamMembersPlaceholder} from 'shared'
import {ListPicker, Text} from 'common/components'
import './styles/user_picker.less'
import {EitjeAvatar, EitjeButton, Layout} from 'common/components'

const allStatuses = ['available', 'unavailable', 'scheduled', 'improductive', 'pending_improductive']

const searchEmptyProps = {size: 'mini', layoutProps: {height: 'calc(100% - 150px)'}}

const UserPicker = ({env, team, users, hideUserPanel, placeholderProps = {}}) => {
	const statusItems = allStatuses.map(s => ({id: s, name: t(`planning.statuses.${s}`)}))

	const teamPlaceholderProps = useNoTeamMembersPlaceholder({...searchEmptyProps, users})
	const searchProps = {
		searchField: 'full_name',
		searchEmptyProps,
		wrapperClassName: 'search-input-container',
		placeholderProps: {...teamPlaceholderProps, ...placeholderProps},
	}

	const {searchInput, emptyElement, filteredItems} = useSearch(users, searchProps)
	const classNames = makeCnVariants('eitje-user-list', hideUserPanel && 'hidden')
	const innerPostChildren = (
		<Layout width="full">
			<EitjeButton modalLink="/forms/invite" width="full-minus-margin" iconLeft="person" t="invite" margin="8 - 12 -" />
		</Layout>
	)

	return (
		<div className={classNames}>
			<div className="search-input-container">{searchInput}</div>
			{filteredItems.length == 0 && emptyElement}
			<ListPicker
				decorated={false}
				showSearch={false}
				groupField="planningStatus"
				groupItems={statusItems}
				field="user_id"
				ListItemContent={UserRow}
				maxHeight={null}
				single
				noSort
				innerPostChildren={innerPostChildren}
				items={filteredItems}
				listItemProps={{
					layoutProps: {
						height: 60,
						borderBottom: true,
					},
					team,
					env,
				}}
			/>
		</div>
	)
}

const UserRow = ({item, team, env}) => {
	const {dateRange} = usePlanningDates()
	const date = dateRange.start
	const isImprod = item.planningStatus == 'improductive'
	return (
		<Fragment>
			<EitjeAvatar user={item} />
			<div className="user-row-text-container">
				<Text small bold>
					{item.full_name}
				</Text>
				<AmtWorked date={date} user={item} envId={env.id} dateRange={dateRange} />
			</div>
			{isImprod ? (
				<h5 className="planning-day-availability-leave">{t(`planning.statuses.${item.status}`)}</h5>
			) : (
				<Skills user={item} team={team} />
			)}
		</Fragment>
	)
}

export default UserPicker
