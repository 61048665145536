import {useSelector} from 'react-redux'
import {where, all} from '@eitje/easy_api'

const useChatOrphaned = (chan, members) => {
	const memIds = Object.keys(members).map(n => Number(n))
	const allUserIds = _.uniq(_.flatten(useSelector(state => all(state, 'environments')).map(e => e.user_ids)))
	const {type} = chan
	if (type != 'messaging') return
	if (memIds.length === 1) return true
	return !memIds.every(id => allUserIds.includes(id))
}

export default useChatOrphaned
