import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {ModalLink} from 'components/routing'
import {getPlanningShiftUrl, usePlanningContext, useTeamContext} from 'cores/planning/index'
import {useOpenModularChat} from 'actions/chat'
import './styles/menu.less'
import {useNewOutsideClick} from 'hooks/use_outside_click'
import useHasRole from 'hooks/use_has_role'
import useUserOvertime from 'hooks/use_user_overtime'
import {ProfileLink, useStartChatLink} from 'components/shared/index'
import {Layout, Text} from 'common/components'
import {EitjeAvatar} from 'common/components'

const Menu = ({user, setDropdownVisible, parentClass}) => {
	const {dateRange} = usePlanningContext()
	const {team} = useTeamContext()
	const url = getPlanningShiftUrl({user, team})
	const hasContractRole = useHasRole('contracten', team.environment_id)
	const contract = user.contractHolder(dateRange)
	const {openChat} = useOpenModularChat(user.id) || {}

	const {startChat, showChat} = useStartChatLink(user.id)
	useNewOutsideClick({onClick: () => setDropdownVisible(false), parentClass})
	return (
		<div onClick={e => e.stopPropagation()} className="availability-menu">
			<UserInfo user={user} />
			<ModalLink to={url} t="planning.environment_table.user_menu.create_shift" />
			{contract && hasContractRole && (
				<ModalLink to={`/forms/contracts/${contract.id}/details`} t="planning.environment_table.user_menu.open_contract" />
			)}
			<ProfileLink userId={user.id} />
			{/* PR comment: heb deze wel mooi aangepast maar staat toch altijd op false – hotfix waar we nog iets mee moeten, of kan ie helemaal weg? :P  */}
			{false && showChat && startChat}
			{/* implement above before  */}
			<Text className="availability-menu-open-chat" onClick={openChat} t="planning.environment_table.user_menu.send_message" />
		</div>
	)
}

const UserInfo = ({user}) => {
	const {mins, available} = useUserOvertime(user.id)

	return (
		<Layout className="user-info" padding={12} gap={8}>
			<EitjeAvatar user={user} />
			<div className="user-info-details">
				<h5 className="user-info-name"> {user.full_name} </h5>
				{available && (
					<h6 className="user-info-time">
						{t('planning.environment_table.user_menu.time_balance')} {utils.minutesToString(mins, {signed: true})}
					</h6>
				)}
			</div>
		</Layout>
	)
}

export default Menu
