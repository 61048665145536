import {Fragment} from 'react'
import ChannelHeader from 'components/chat/ChannelHeader'
import ChatClientWrapper from 'components/chat/ChatClientWrapper'
import ActiveChats from 'components/chat/modularChat/ActiveChats'
import RemoveChatButton from 'components/chat/modularChat/RemoveChatButton'
import {store} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {Channel, MessageInput, MessageList, Window, useChannelStateContext} from 'stream-chat-react'
import {ChatStateContext} from '../components/providers/chat_provider'
import useChatOrphaned from 'hooks/use_chat_orphaned'
import 'components/chat/styles/chat_modular.less'
import useShared from 'hooks/use_shared'

const ExpandToLargeChat = () => {
	const {currentChannel} = useContext(ChatStateContext)
	return (
		<Link to="/chat">
			<div onClick={() => store.dispatch({type: 'OPEN_TO_ME', openToMe: currentChannel.id})} className="channel_list_icons">
				<img src={'/images/web/icons/enlargeChatIcon.png'} />
			</div>
		</Link>
	)
}

const MinimiseChat = () => {
	const {setCurrentChannel} = useContext(ChatStateContext)
	return (
		<div onClick={() => setCurrentChannel({})} className="channel_list_icons">
			<img src={'/images/web/icons/minimizeChatIcon.png'} />
		</div>
	)
}

const ChatModular = props => {
	const {currentChannel, setCurrentChannel, setModular, setActivelySelectedChannels, activelySelectedChannels, toggleChatDropdown} =
		useContext(ChatStateContext)

	useEffect(() => {
		setModular(true)
		return () => {
			setModular(false)
			toggleChatDropdown(false)
		}
	}, [])

	const chatActive = !_.isEmpty(currentChannel)

	return (
		<div className="chat-modular-overlay-container modularChat">
			<ChatClientWrapper modular={true} isChatDropdown={true}>
				{chatActive && (
					<Channel channel={currentChannel} className="modular">
						<InnerChannel />
					</Channel>
				)}
			</ChatClientWrapper>
			{activelySelectedChannels.length > 0 && (
				<div className="active-mod-chats-container">
					<ActiveChats />
					<div
						className="modular-chat-large-cross"
						onClick={() => {
							setCurrentChannel({})
							setActivelySelectedChannels([])
						}}
					/>
				</div>
			)}
		</div>
	)
}

const _messageActions = ['quote', 'react']

const InnerChannel = () => {
	const {hasAdminRole} = useShared()

	const {channel, members} = useChannelStateContext()

	const {type} = channel
	const isOrphaned1on1 = useChatOrphaned(channel, members)

	let messageActions = [..._messageActions, hasAdminRole && 'delete', hasAdminRole && 'edit'].filter(Boolean)

	const msgActions = isOrphaned1on1 ? [] : messageActions

	return (
		<Fragment>
			<div className="modular_channel_icons">
				<ExpandToLargeChat />
				<MinimiseChat />
				<RemoveChatButton small channel={channel} />
			</div>
			<Window className="modular">
				<ChannelHeader modular />
				<MessageList messageActions={msgActions} />
				{isOrphaned1on1 ? (
					<div style={{width: '100%', paddingBottom: 12, paddingTop: 8, borderTop: '1px solid #eee'}}>
						<div
							style={{width: '96%', marginLeft: '2%', padding: 12, backgroundColor: '#F5F6F7', borderRadius: 4}}
							className="fRow aCen jCen shadow"
						>
							<p style={{textAlign: 'center'}} className="smallP">
								{t('orphanedChat')}
							</p>
						</div>
					</div>
				) : (
					<MessageInput focus={true} />
				)}
			</Window>
		</Fragment>
	)
}

export default ChatModular
