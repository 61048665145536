const initialState = {
	openToMe: '',
	initUnreadCount: null,
}

export default function reduce(state = initialState, action) {
	switch (action.type) {
		case 'OPEN_TO_ME':
			return {
				...state,
				openToMe: action.openToMe,
			}
		case 'INIT_UNREAD_COUNT':
			return {
				...state,
				initUnreadCount: action.initUnreadCount,
			}
		default:
			return state
	}
}
