import {date} from 'initializers/date'
import {Clock} from 'components/common/index'
import utils from '@eitje/web_utils'
import {t, STATUS_NAMES, baseColorMapping} from 'cores/time_registration/index'
import {PopoutCard} from '@eitje/web_components'
import {TimeBlock} from 'cores/time_registration/index'
import {TimeCellWithZero, CellPlaceholder, TimeCell} from 'common/components/advanced_table'
import {Team} from 'models'

export const FullName = ({getValue, row, column}) => {
	const {date: _date, user_id} = row?.original || {}
	if (!user_id) return null

	const userName = getValue()
	const isGroupedColumn = column.getIsGrouped()
	const shiftDate = date(_date).format('dd DD MMM YY')

	const value = isGroupedColumn && row.depth == 1 ? shiftDate : userName
	return value || null
}

export const TotalMins = props => {
	const {original} = props.cell.row
	if (original.isCheckIn()) return <Clock start={original.start_date} />
	return <TimeCellWithZero {...props} />
}

export const PlannedTime = ({getValue, cell, ...rest}) => {
	const item = cell?.row?.original
	const mins = getValue()
	const showTimeBlock = !cell?.subRows && item?.planningShift

	return (
		<PopoutCard {...rest} body={showTimeBlock && <TimeBlock shift={item?.planningShift} />}>
			{utils.minToTimeString(mins)}
		</PopoutCard>
	)
}

export const getDifference = shift => {
	if (!shift.isReg()) return -shift.totalMins()
	return shift.totalMins() - (shift?.planningShift?.totalMins() || 0)
}

export const CheckedInTime = ({row, ...props}) => {
	const {checkIns = []} = row.original

	if (checkIns.length == 0) return <CellPlaceholder />
	return (
		<PopoutCard body={<TimeBlock shift={checkIns} />}>
			<TimeCell {...props} />
		</PopoutCard>
	)
}
