import {useSelector} from 'react-redux'
import {getEnvSettings, roleSelector} from 'selectors/new_settings'
import {currentEnvSelector, currentOrgSelector, orgEnvsSelector, selfSelector} from 'selectors/records'

export function useShared() {
	const env = useSelector(currentEnvSelector)
	const envSettings = useSelector(getEnvSettings)
	const org = useSelector(currentOrgSelector)
	const orgEnvs = useSelector(orgEnvsSelector) || []
	const multiEnvOrg = orgEnvs.length > 1
	const orgEnvIds = orgEnvs.map(e => e.id)
	const me = useSelector(selfSelector)
	const myOrgEnvs = me.environment_ids ? orgEnvs.filter(e => me.environment_ids.includes(e.id)) : []
	const myOrgEnvIds = myOrgEnvs.map(e => e.id)

	const hasManagerRole = useSelector(state => roleSelector(state, 'manager'))
	const hasAdminRole = useSelector(state => roleSelector(state, 'admin'))
	const hasLeaveRole = useSelector(state => roleSelector(state, 'verlof'))
	const hasContractRole = useSelector(state => roleSelector(state, 'contracten'))
	const hasWriterRole = useSelector(state => roleSelector(state, 'uren_schrijven'))
	const hasAccordHoursRole = useSelector(state => roleSelector(state, 'uren_accorderen'))
	const hasFinancialRole = useSelector(state => roleSelector(state, 'financieel'))

	const activeOrgEnvIds = org.active_environment_ids || org.environment_ids || []

	return {
		env,
		myOrgEnvIds,
		envSettings,
		multiEnvOrg,
		org,
		isOrg: orgEnvs.length > 1, // this actually should be 'imPartOfMultipleEnvs'
		isMultiOrg: (activeOrgEnvIds || []).length > 1, // this should be isOrg
		myOrgEnvs,
		orgEnvs,
		orgEnvIds,
		hasManagerRole,
		hasAdminRole,
		me,
		envId: env.id,
		orgId: env.organisation_id,
		hasLeaveRole,
		hasContractRole,
		hasWriterRole,
		hasAccordHoursRole,
		hasFinancialRole,
	}
}

export default useShared
