import {ENV} from 'initializers/api'
export const falsy = [false, null]

const isDev = process.env.NODE_ENV == 'development'
const envStage = process.env.REACT_APP_STAGE
const isProd = !isDev && envStage == 'production'

const isProdClone = ENV == 'mirror' || ENV == 'staging'

const iOSLink = 'https://apps.apple.com/nl/app/eitje/id1268811198'
const androidLink = 'https://play.google.com/store/apps/details?id=com.eitjeapp&hl=nl'
const salesPhoneNumber = '0031202611580'
const languages = ['nl', 'en']

// PR comment: waarom hier een obj exporteren ipv direct de const zelf?
// (voelt voor mij als anti pattern + moet altijd twee plekken editten ipv 1)
export {isDev, isProd, isProdClone, iOSLink, androidLink, salesPhoneNumber, languages}
