import {backend} from '@eitje/easy_api'
import {updateDay} from 'actions/planning'
import {Checkbox, DatePicker, Input, message, Modal, Select, TimePicker, Tooltip} from 'antd'
import {UserImage} from 'components/ui'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {date} from 'initializers/date'
import React from 'react'
import {useSelector} from 'react-redux'
import {composedContractSelector} from 'selectors/legacy'
import * as jsUtils from 'utils/jsutils'
import 'styles/legacy/ant_overwrite.scoped.css'

const Option = Select.Option
const {TextArea} = Input

export class BeschikbaarheidModal extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			day: props.day,
			timersVisible: props.day && props.day.from,
		}
		this.handleChange = this.handleChange.bind(this)
		this.checkValidity = this.checkValidity.bind(this)
	}
	handleChange(value) {
		var day = _.clone(this.state.day)
		var timersVisible
		day.available = value == 'available'
		timersVisible = value == 'part_unavailable'
		this.setState({day: day, timersVisible: timersVisible})
	}

	onTimeChange(time, timeString, which) {
		var tijd = time.format('HH:mm')
		var day = _.clone(this.state.day)
		day[which] = tijd
		this.setState({day: day})
	}

	getdefValue(day) {
		var p
		p = `${t('available')}`
		if (day.from) {
			p = `${t('partlyAv')}`
		} else if (!day.available) {
			p = `${t('unavailable')}`
		}
		return p
	}

	checkValidity() {
		const {day, timersVisible} = this.state
		const {redenVerplicht} = this.props
		if (day.available) {
			return true
		}
		if (redenVerplicht && (!day.remarks || day.remarks.length == 0)) {
			jsUtils.openNotif(`${t('oops')}`, 'error', `${t('reasonMSG')}`)
			return false
		}
		if (timersVisible) {
			if (day.from && day.till) {
				return true
			} else {
				jsUtils.openNotif(`${t('oops')}`, 'error', `${t('startEndMSG')}`)
				return false
			}
		}

		return true
	}

	async submit() {
		const {day, timersVisible} = this.state
		let {available, remarks, from, till, user_id} = day
		let obj = {available, remarks, from, till}
		if (this.checkValidity(day)) {
			if (!timersVisible) {
				obj.till = obj.from = null
			}
			const res = await backend.post(`/users/${user_id}/add_availability`, {
				date: day.date,
				availability: obj,
			})
			if (res.ok && !res.data.errors) {
				this.props.handleSuccess()
				this.props.handleCancel()
			} else if (res.data.errors) {
				jsUtils.errNotif(t('att'), res.data.errors[0])
			}
		}
	}

	textChange(val) {
		var day = _.clone(this.state.day)
		day.remarks = val
		this.setState({day})
	}

	render() {
		const {timersVisible} = this.state
		var day = this.state.day
		var beschDefVal = this.getdefValue(day)
		var defTill = day.till ? date(day.till, 'HH:mm') : date('17:00', 'HH:mm')
		var defFrom = day.from ? date(day.from, 'HH:mm') : date('9:00', 'HH:mm')
		return (
			<Modal
				onOk={() => this.submit()}
				className="modalStyle340"
				onCancel={this.props.handleCancel}
				visible={this.props.visible}
				title={t('insertAvailability')}
			>
				<div className="modalStyle">
					<div className="datumBeschikbaar, marginModal">
						<p>{t('date')}:</p>
						<DatePicker disabled defaultValue={date(day.date)} />
					</div>

					<div className="statusBeschikbaar, marginModal">
						<p>{t('status')}: </p>
						<Select defaultValue={beschDefVal} style={{width: 180}} onChange={this.handleChange}>
							<Option value="available">{t('available')}</Option>
							<Option value="unavailable">{t('unavailable')}</Option>
							<Option value="part_unavailable">{t('partlyAv')}</Option>
						</Select>
					</div>

					<div className="opmerkingBeschikbaar, marginModal">
						<p className={day.available ? 'opacityDisabled' : ''}>{t('note')}</p>
						<TextArea
							value={day.available ? '' : day.remarks}
							style={{minWidth: 180, minHeight: 50, width: 300, borderRadius: 4}}
							onChange={e => this.textChange(e.target.value)}
							disabled={day.available}
							autosize
						/>
					</div>

					<div className="tijdBeschikbaar">
						<p className={!timersVisible ? 'opacityDisabled' : ''}>{t('timeslotUNAV')}?</p>
						<TimePicker
							format="HH:mm"
							style={{width: 120}}
							disabled={!timersVisible}
							onSelect={(a, b) => this.onTimeChange(a, b, 'from')}
							value={day.from && defFrom}
							defaultOpenValue={defFrom}
						/>

						<TimePicker
							format="HH:mm"
							style={{marginLeft: 8, width: 120}}
							disabled={!timersVisible}
							onSelect={(a, b) => this.onTimeChange(a, b, 'till')}
							value={day.from && defTill}
							defaultOpenValue={defTill}
						/>
					</div>
				</div>
			</Modal>
		)
	}
}

const dayStatus = (users, user) => {
	const avUser = users.filter(u => u.user_id == user.id)[0]
	if (!avUser || avUser.available) {
		return <div className="vd groenVD"></div>
	}
	if (!avUser.available && avUser.from) {
		return <div className="vd oranjeVD"></div>
	}

	return <div className="vd roodVD"></div>
}

export const VDUser = ({user, disabled, prefShifts, days, onC}) => (
	<td
		style={{
			opacity: disabled ? 0.2 : 1,
			pointerEvents: disabled ? 'none' : 'auto',
			borderBottom: '1px solid #eee',
			marginLeft: -1,
			paddingLeft: 8,
			cursor: 'pointer',
		}}
		onClick={onC}
	>
		{days.map(day => (
			<span> {dayStatus(day.availability_users, user)} </span>
		))}
		<span style={{fontSize: 10, fontWeight: 500}}> ({prefShifts}) </span>
	</td>
)

const getStatusSpan = status => <span style={{fontSize: 8, color: statusMapText[status], fontWeight: '500'}}>{status}</span>

export const getKaartjeTekst = (obj = {}) => {
	if (!obj) return null
	if (obj.status2) {
		return (
			<p style={{fontSize: 8}}>
				{' '}
				{getStatusSpan(obj.status1)} / {getStatusSpan(obj.status2)}{' '}
			</p>
		)
	} else if (obj.status1) {
		return <p style={{fontSize: 8}}> {getStatusSpan(obj.status1)} </p>
	}
}

export const getKaartjeInfo = (isPlanned, isConflict, isOpgenomen, isAangevraagd) => {
	if (isOpgenomen && isConflict) {
		return {status1: 'opgenomen', status2: 'conflict'}
	}

	if (isAangevraagd && isConflict) {
		return {status1: 'aangevraagd', status2: 'conflict'}
	}

	if (isPlanned && isConflict) {
		return {status1: 'ingepland', status2: 'conflict'}
	}

	if (isOpgenomen) {
		return {status1: 'opgenomen'}
	}

	if (isAangevraagd) {
		return {status1: 'aangevraagd'}
	}

	if (isPlanned) {
		return {status1: 'ingepland'}
	}

	return null
}

const beschColors = {}

export const statusMapBG = {aangevraagd: '#EDEFFF', opgenomen: 'white', conflict: '#FFE8E9', ingepland: '#fff'}
export const statusMapText = {
	partly: 'orange',
	unavailable: 'red',
	aangevraagd: '#9013FE',
	opgenomen: '#9013FE',
	conflict: '#ff0020',
	ingepland: '#0496ff',
}

export const determineBgColor = (obj, isAv, isPart) => {
	if (obj) {
		return statusMapBG[obj.status2] || statusMapBG[obj.status1]
	}
	return isAv ? 'white' : isPart ? 'white' : 'white'
}

export const Users = props => {
	const isSaldo = props.pmSaldo || props.vSaldo
	return (
		<div>
			<table
				className={`noselect borderTop Table ${isSaldo && 'borderBot'} ${!props.plusMin && !isSaldo && 'beschikbaarheidTD'}`}
				style={{marginBottom: isSaldo ? 40 : 28, marginTop: isSaldo ? -1 : -1}}
			>
				{_.sortBy(props.users, 'full_name').map(user => {
					// var prefShifts = user[props.activeYear] && user[props.activeYear][props.activeWeek]['pref_shifts']
					const firstUser = props.users.indexOf(user) == 0
					const lastUser = props.users.indexOf(user) == props.users.length - 1
					return (
						<tbody>
							{props.verlof ? (
								<VerlofUser {...props} picture user={user} />
							) : props.plusMinSaldo ? (
								<PMSaldoUser {...props} lastUser={lastUser} user={user} firstUser={firstUser} currentUser={props.user} />
							) : props.plusMin ? (
								<PlusMinUser {...props} user={user} picture currentUser={props.user} />
							) : (
								<OverzichtUser {...props} picture user={user} currentUser={props.user} />
							)}
						</tbody>
					)
				})}
				{props.total && (
					<tbody>
						{' '}
						<OverzichtUser totaal user={{...props.total, naam: `${t('total')}`}} />{' '}
					</tbody>
				)}
			</table>
		</div>
	)
}

const PMSaldoUser = ({user, ready, max, UPD, firstUser, lastUser}) => (
	<tr className={`saldoRow plusMin`}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					<UserImage user={user} divClassName="avaBGSmall" />
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard breakWord">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.diff >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.diff)}
			</div>{' '}
		</td>
		<td className="saldoGraph">
			{' '}
			<SaldoGraphUser lastUser={lastUser} firstUser={firstUser} max={max} UPD={UPD} ready={ready} user={user} />
		</td>
	</tr>
)

const pmFormula = (hours, max) => {
	const maxU = max
	const maxW = 89.4

	const width = hours * (maxW / maxU)
	return width > maxW ? maxW + 3 : width
}

const SaldoGraphUser = ({user, ready, max, UPD, firstUser, lastUser}) => {
	// uitdaging is om de user-lijntjes zo breed te maken als 't aantal uur // 49.3 % is het magische getal
	// daarbijhorende uitdaging is om het balkje ook altijd zo te positioneren dat-ie vanuit 't midden loopt
	if (!max) max = UPD * 10
	const cont = useSelector(state => composedContractSelector(state, user.id))
	const dataString = `${jsUtils.signedMinTTS(user.diff)} ${t('hoursS')} / ${(user.diff / 60 / UPD).toFixed(1)} ${t('days')}`
	if (!cont) return null

	const tooltipText =
		cont.typ == 'Uren contract' || cont.typ == 'Vaste dienst'
			? `${t('contractHover1')} ${cont.uren} ${t('contractHover2')} ${cont.amt_werkdagen} ${t('days')}. ${dataString}`
			: `Nuluren contract. ${dataString}`
	const color = user.diff < 0 ? '#FC5764' : '#0496FF'
	const uren = user.diff / 60
	var left
	var width = 0
	width = !ready ? 0 : pmFormula(Math.abs(user.diff / 60), max, UPD)
	const amtLijntjes = Math.round(max / UPD) + 1
	const lijntjesLeftInc = 87.2 / (amtLijntjes - 1)
	const lines =
		firstUser || lastUser
			? _(amtLijntjes).times(i => (
					<div className="pmLijntje" style={{left: `${2.2 + i * lijntjesLeftInc}%`}}>
						{lastUser && <span style={{fontSize: 8, cursor: 'default', position: 'relative', top: 39, left: -4}}> {i} </span>}
					</div>
			  ))
			: _(amtLijntjes).times(i => <div className="pmLijntje" style={{left: `${2.2 + i * lijntjesLeftInc}%`}}></div>)
	const imgLeft = amtLijntjes == 5 ? 2.5 : 2.5 - (amtLijntjes - 5) * 0.25
	return (
		<div>
			{lines}
			<Tooltip placement="topRight" title={tooltipText}>
				{' '}
				<div
					className="saldoLijntje"
					style={{
						height: 8,
						left: 0,
						position: 'relative',
						width: `${width}%`,
						left: '2.2%',
						top: 16,
						backgroundColor: `${color}`,
						border: `1px solid ${color}`,
						transition: 'width 2s',
					}}
				>
					{' '}
				</div>{' '}
			</Tooltip>
		</div>
	)
}

const VerlofUser = ({user, onClick, picture, correctieClick, factor}) => (
	<tr className={'tablebody plusMin'}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.verlof_saldo_start >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.verlof_saldo_start)}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.opgebouwd)} </div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.opgenomen)} </div>{' '}
		</td>
		<td onClick={() => correctieClick(user)}>
			{' '}
			<div className="cardUserRooster detailsCard correcties">{jsUtils.signedMinTTS(user.correcties)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.signedMinTTS(user.diff)}</div>{' '}
		</td>

		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.verlof_saldo_end >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.verlof_saldo_end)}
			</div>{' '}
		</td>
	</tr>
)

const PlusMinUser = ({user, totaal, onClick, picture, correctieClick}) => (
	<tr className={'tablebody ' + (totaal && 'total') + ' plusMin'}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.pm_saldo_start >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.pm_saldo_start)}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.contract_mins)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.total_mins)} </div>{' '}
		</td>
		<td onClick={() => correctieClick(user)}>
			{' '}
			<div className="cardUserRooster detailsCard correcties">{jsUtils.signedMinTTS(user.correcties)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.signedMinTTS(user.diff)}</div>{' '}
		</td>

		<td>
			{' '}
			<div className="cardUserRooster detailsCard" style={{color: user.pm_saldo_end >= 0 ? '#0496ff' : '#FC5764'}}>
				{jsUtils.signedMinTTS(user.pm_saldo_end)}
			</div>{' '}
		</td>
	</tr>
)

export class Clock extends React.Component {
	constructor(props) {
		super(props)
		const current = props.start
		const mmt = date()

		this.state = {
			secondsElapsed: date().diff(props.start, 'seconds'),
		}
	}

	componentDidMount() {
		this.timerID = setInterval(() => this.tick(), 1000)
	}

	componentWillUnmount() {
		clearInterval(this.timerID)
	}

	tick() {
		const {secondsElapsed} = this.state
		this.setState({secondsElapsed: secondsElapsed + 1})
	}

	render() {
		const {secondsElapsed} = this.state
		return <p className={`diffTime`}>{jsUtils.secondsToTimeString(secondsElapsed)}</p>
	}
}

const OverzichtUser = ({user, totaal, onClick = _.noop, picture}) => (
	<tr onClick={() => onClick(user)} className={'tablebody ' + (totaal && 'total')}>
		<td>
			<div className="cardUserUren">
				<div className="fRow aCen jSta" style={{height: '100%'}}>
					{picture && <UserImage user={user} divClassName="avaBGSmall" />}
					<p style={{fontSize: 10, paddingLeft: 8}} className="centerUserCard">
						{user.full_name || user.naam}
					</p>
				</div>
			</div>
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">
				{user.shifts_count} / {user.reg_count}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.planned_time)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.reg_time)}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">
				{user.diff_time > 0 ? '+' : '-'}
				{jsUtils.minToTimeString(Math.abs(user.diff_time))}
			</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{user.maaltijden}</div>{' '}
		</td>
		<td>
			{' '}
			<div className="cardUserRooster detailsCard">{jsUtils.minToTimeString(user.improd_total)}</div>{' '}
		</td>
	</tr>
)

export const DagStaven = props => (
	<tbody className={props.urenReg ? 'td9' : null}>
		<tr className="noselect fixme tableHeader daysText">
			<td className="border" style={props.urenReg ? {width: '11.11%', color: '#2b2b2b'} : {width: '12.5%', color: '#2b2b2b', fontSize: 14}}>
				{' '}
				Week {props.week} - {props.year}
			</td>
			{props.days.map(day => {
				return <DayStaaf key={day.dateString} {...props} dag={day.date} dagString={day.dateString} />
			})}

			{props.urenReg && (
				<td className="border">
					<p className="daysText">{t('total')}</p>
				</td>
			)}

			{props.beschikbaarheid && (
				<td className="border">
					<p className="daysText">{t('defaultDays')}</p>
				</td>
			)}
		</tr>

		{props.beschikbaarheid && props.isAdmin && (
			<tr className=" fixme tableHeader daysText">
				<td
					className="borderBot"
					style={
						props.urenReg
							? {width: '11.11%', color: '#2b2b2b'}
							: {width: '12.5%', color: '#2b2b2b', borderBottom: 0, borderRight: '1px solid #eee'}
					}
				>
					<Tooltip title={t('tooltipLock')}>
						<p style={{fontSize: 11}}>{t('autoSlot')}</p>
					</Tooltip>
				</td>
				{props.days.map(d => (
					<td
						className="border"
						style={props.urenReg ? {width: '11.11%', color: '#2b2b2b'} : {width: '12.5%', color: '#2b2b2b', borderBottom: 0}}
					>
						<BeschUnavControl {...props} day={d.date} />
					</td>
				))}
				<td>
					{props.beschikbaarheid && props.isAdmin && (
						<p onClick={props.autoSlotClick} style={{fontSize: 9, color: '#828282', padding: 0, cursor: 'pointer'}}>
							{t('changeDefaultValues')}
						</p>
					)}
				</td>
			</tr>
		)}

		{props.showWeather && (
			<tr className="noselect tableBodyWeather">
				<td className="weatherText border upperCase">{t('weather')}</td>{' '}
				{props.days.map(day => {
					var weatherDay = props.weatherDays.find(w => w?.date == day.dateString)
					weatherDay = weatherDay && weatherDay.data
					const types = [
						'partly-cloudy-day',
						'cloudy',
						'sleet',
						'snow',
						'rain',
						'clear-day',
						'partly-cloudy-night',
						'fog',
						'clear-night',
						'wind',
					]
					var imgSrc
					if (weatherDay && types.includes(weatherDay.icon)) {
						imgSrc = `/images/${weatherDay.icon}.png`
					} else if (weatherDay) {
						imgSrc = `/images/partly-cloudy-day.png`
					}
					return (
						<td key={`weather-${day.dateString}`} className="border weatherText">
							{' '}
							{weatherDay && <img style={{width: 28, height: 28, position: 'relative', left: -10}} src={imgSrc} />}
							{weatherDay &&
								`${Math.round(weatherDay.temperatureLow)}°/
                                                   ${Math.round(weatherDay.temperatureHigh)}°
                                                   ${
																											weatherDay.precipProbability
																												? Math.round(weatherDay.precipProbability * 100).toString() + '%'
																												: ''
																										}`}
						</td>
					)
				})}
			</tr>
		)}
		{props.urenReg && (
			<tr className="tableBodyWeather">
				{' '}
				<td className="weatherText border" style={{backgroundColor: 'white', position: 'relative', top: 0}}>
					{t('hoursC')}
				</td>
				{props.days.map(day => {
					const s = props.shifts.filter(s => s.date == day.dateString)
					const c = props.checkIns.filter(s => s.date == day.dateString)
					const {diff, regTotal, planTotal} = getTotalDiff(s, c)
					return (
						<td className="border" style={{textAlign: 'center'}}>
							{' '}
							{!(regTotal == 0 && planTotal == 0) ? (
								<div style={{alignItems: 'center'}}>
									<p style={{fontSize: 10, fontWeight: 600}}>{jsUtils.minToTimeString(regTotal)}</p>
									<p style={{fontSize: 8, marginTop: -2}}>{jsUtils.signedMinTTS(diff)}</p>
								</div>
							) : (
								<div style={{alignItems: 'center'}}>
									<p style={{fontSize: 8, fontStyle: 'italic'}}>{t('noHoursKnown')}</p>
								</div>
							)}
						</td>
					)
				})}
				{props.urenReg && (
					<td style={{textAlign: 'center'}} className="border">
						<p style={{fontSize: 10, fontWeight: 600}}>{jsUtils.minToTimeString(getTotalDiff(props.shifts, props.checkIns).regTotal)}</p>
						<p style={{fontSize: 8, marginTop: -2}}>{jsUtils.signedMinTTS(getTotalDiff(props.shifts, props.checkIns).diff)}</p>
					</td>
				)}
			</tr>
		)}
	</tbody>
)

export const getTotalDiff = (shifts, checkIns) => {
	let regTotal = 0
	let planTotal = 0
	shifts.forEach(s => {
		if (s.reg) regTotal += s.total_mins
		if (!s.reg) planTotal += s.total_mins
		if (s.reg && s.total_plan) planTotal += s.total_plan
		if (s.pending_check_in) regTotal += checkInCalc(s.pending_check_in)
	})
	checkIns.forEach(c => (regTotal += checkInCalc(c)))
	return {regTotal, planTotal, diff: regTotal - planTotal}
}

const checkInCalc = ci => {
	return date().diff(date(ci.starts_at), 'minutes')
}

class DayStaaf extends React.Component {
	constructor(props) {
		super(props)
		this.locked = this.locked.bind(this)
	}

	locked() {
		const {props} = this
		var locked = false
		var corrdag = _.find(props.days, {dateString: props.dagString})
		var lockVisible = this.lockVisible()
		if (corrdag) {
			locked = corrdag.closed
		}
		return locked
	}

	componentWillReceiveProps(props) {
		var locked = false
		var corrdag = _.find(props.days, {dateString: props.dagString})
		var lockVisible = this.lockVisible()
		if (corrdag) {
			locked = corrdag.closed || !corrdag.available
		}
		this.setState({locked: locked, lockVisible: lockVisible})
	}

	lockVisible = () => {
		const {locked_day, lockedFrom, dag} = this.props
		const lockedBefore = !locked_day || !locked_day.isSameOrAfter(dag)
		const lockedAfter = !lockedFrom || !lockedFrom.isSameOrBefore(dag)
		return lockedBefore && lockedAfter
	}

	lockDay = async (env, day, lock) => {
		updateDay(day, {closed: lock})
		await backend.post(`roosters/lock_day`, {
			env: env,
			day: day,
			lock: lock,
		})

		this.props.onSucc(day, lock)
	}

	render() {
		const {dag} = this.props
		const isToday = date().isSame(dag, 'day')
		var locked = this.locked()
		const lockVisible = this.lockVisible()

		return (
			<td className="border" style={{backgroundColor: isToday ? '#EBF6FF' : 'white'}}>
				<td style={{backgroundColor: isToday ? '#EBF6FF' : 'white'}}>
					<p
						style={{marginBottom: lockVisible && this.props.isAdmin && !this.props.urenReg && -10, color: isToday ? '#0496ff' : '#828282'}}
					>
						{dag.format('dd DD MMMM')}
					</p>

					<p onClick={() => this.lockDay(this.props.env_id, this.props.dag.format('YYYY-MM-DD'), !locked)}>
						{lockVisible && this.props.isAdmin && !this.props.urenReg && (
							<div className="divBeschikbaar">
								<Tooltip title={t('closeAVTT')}>
									<h6 className="beschikbaarheidOpen noCaps">{t('availableOpen')}:</h6>
								</Tooltip>

								<Checkbox checked={!locked}></Checkbox>
							</div>
						)}
					</p>
				</td>
			</td>
		)
	}
}

const BeschUnavTeam = ({dag, amt, team, onC}) => {
	return (
		<div>
			{dag && (
				<div className="selectDays" style={{padding: '2px 4px 2px 0px'}}>
					<span style={{float: 'left', fontSize: 9, marginTop: 5, marginLeft: 3}}>{jsUtils.truncateString(team.naam, 10)}</span>
					<Select style={{float: 'right', width: 48}} defaultValue={''} value={dag.max_count} onChange={val => onC(val, dag)}>
						<Option value={null}> - </Option>
						{_(amt + 1).times(num => (
							<Option value={num}> {num} </Option>
						))}
					</Select>
					<div className="clear"></div>
				</div>
			)}
		</div>
	)
}

export const toIden = td => {
	return `${date(td.date).weekday()}-${td.team_id}`
}

export class BeschUnavControl extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	// per dag, loopen we over teams, per team & dag vinden we een team_dag

	render() {
		const {teams, day, teamDays, onUnavC} = this.props
		const dag = date(day)

		return (
			<td>
				{teams &&
					teamDays &&
					teamDays.length > 0 &&
					teams.map(te => (
						<BeschUnavTeam
							onC={(val, dag) => onUnavC(val, te.id, dag)}
							amt={te.user_ids.length}
							team={te}
							dag={teamDays.find(d => toIden(d) === `${dag.weekday()}-${te.id}`)}
						/>
					))}
			</td>
		)
	}
}
