import utils from '@eitje/utils'
import {PopoutCard} from '@eitje/web_components'
import useSort from 'hooks/use_sort'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import moment from 'moment'
import {date} from 'initializers/date'
import React, {Fragment, useRef, useState} from 'react'
import 'styles/app/components/table.less'

export const likeDate = val => {
	if (!_.isString(val)) return false
	const mmt = moment(val, ['YYYY-MM-DD', 'YYYY-MM-DDThh:mm:ss.SSSZ'], true)
	return mmt.isValid()
}

const renderDefaultCell = (item, field) => {
	const val = _.get(item, field) // we might need nested access
	if (val && likeDate(val)) {
		return date(val).format('DD-MM-YYYY')
	}
	return val
}

const disallowedChildClasses = ['ant-btn', 'actionBTN', 'ant-popover'] // bit hacky, idea is to prevent certrain children from triggering tr's click
// without having to explicitly set stopPropagation in the component. For now only use-case is a popover
// that's why it makes sense to use the offsetParent.

const hasClass = e => disallowedChildClasses.some(c => e.classList.contains(c))

const Row = ({item, content = () => {}, columnProps = {}, onClick = () => {}, title = () => {}, fields, style, placement, showActions}) => {
	const sharedProps = {}
	const [mouseIn, setMouseIn] = useState(false)
	const handleMouseMove = () => setMouseIn(s => !s)
	if (placement) sharedProps['placement'] = placement
	const popover = useRef(null)
	const {originalItem} = item
	const _title = title(originalItem)
	return (
		<tr onClick={e => !hasClass(e.target.offsetParent) && onClick(originalItem)} style={style}>
			{fields.map((f, idx) => {
				const cProps = utils.funcOrObj(columnProps, idx)
				const Component = idx === 0 ? 'th' : 'td'
				const className = idx === 0 ? 'table-cell table-col-fix-l' : 'table-cell'
				return (
					<Component className={className} {...cProps}>
						{' '}
						{item[f]?.label}{' '}
					</Component>
				)
			})}
			{showActions && (
				<PopoutCard
					ref={popover}
					layoutProps={{horizontal: 'center', width: '100%'}}
					body={content(originalItem, {popover: popover.current})}
					title={_title}
					{...sharedProps}
					bodyPadding={0}
				>
					<td onMouseOver={() => handleMouseMove()} onMouseOut={() => handleMouseMove()} className="actionBTN action-td">
						<img className="" src={mouseIn ? '/images/web/icons/dots.png' : '/images/web/icons/dotsInactive.png'} />
					</td>
				</PopoutCard>
			)}
		</tr>
	)
}

const renderHeader = (f, up, active) => {
	return (
		<Fragment>
			{t(`table.${f}`, t(`records.${f}`))}{' '}
			{active ? (
				up ? (
					<img src="/images/web/icons/ascending.png" style={{width: 12}} />
				) : (
					<img src="/images/web/icons/descending.png" style={{width: 12}} />
				)
			) : (
				<img src="/images/web/icons/noOrder.png" style={{width: 12}} />
			)}
		</Fragment>
	)
}

const Headers = ({fields, hideHeaders, showActions, sortField, onClick, up}) => {
	return (
		<thead>
			<tr>
				{fields.map(f => (
					<th className="headers" onClick={() => onClick(f)}>
						{hideHeaders.includes(f) ? '' : renderHeader(f, up, sortField === f)}
					</th>
				))}
				{showActions && <th className="action-td">Actions</th>}
			</tr>
		</thead>
	)
}

const Table = props => {
	const {
		items = [],
		fields = [],
		actionMenu,
		actionTitle,
		rowProps = {},
		style = {},
		showCount,
		hideHeaders = [],
		renderCell = () => null,
		showEmpty = [],
		placeholder,
		emptyPlaceholder = null,
	} = props

	const makeCell = (item, field, idx) => {
		const val = item[field]
		if (!val && !showEmpty.includes(field) && item.hasOwnProperty(field)) return {label: emptyPlaceholder, value: val, field}

		const cellData = renderCell(item, field, idx) || renderDefaultCell(item, field, idx)
		if (_.has(cellData, 'value')) return cellData
		return {label: cellData, value: _.get(item, field)}
	}

	const tableItems = items.map(i => {
		let obj = {}
		fields.forEach(f => (obj[f] = makeCell(i, f)))
		return {...obj, originalItem: i}
	})

	const {changeSort, asc, sortedItems, sortField} = useSort({...props, items: tableItems, nested: true})
	const {showActions} = rowProps

	return (
		<div className="table-container" style={style}>
			<table>
				{showCount && `(${items.length})`}

				<Headers showActions={showActions} up={asc} sortField={sortField} onClick={changeSort} fields={fields} hideHeaders={hideHeaders} />

				<tbody>
					{sortedItems.map(i => {
						const rProps = utils.funcOrObj(rowProps, i)
						return <Row title={actionTitle} content={actionMenu} item={i} fields={fields} {...rProps} />
					})}
				</tbody>
			</table>
			{placeholder}
		</div>
	)
}

export default Table
export {Table}
