import React, {useEffect} from 'react'
import {Input} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {FormRow} from 'components/ui'
import {PlanningTemplate} from 'models/index'
import {useForm} from '@eitje/form'
import {useQueryParams, useRoleOrgEnvs} from 'hooks'
import {usePlanningDates} from 'cores/planning/index'
import {usePlanningTeamFilter} from 'cores/planning/hooks/use_planning_team_filter'
import {labelWithShiftCount} from 'hooks/use_team_filter'
import {useTotalShiftsPerTeam} from 'hooks/use_shifts_per_team'
import {getKeys} from 'helpers/object'
import utils from '@eitje/utils'
import {ListPicker} from 'common/components'
import {Team} from 'models'

const Form = ({periodKind}) => {
	const managerEnvs = useRoleOrgEnvs('manager')
	const {getData, setValues} = useForm()
	const {save_kind, id, kind} = getData()

	const {dateRange} = usePlanningDates()
	let {ids, team_ids: teamIdsParams} = useQueryParams()
	const managerTeams = Team.where({environment_id: managerEnvs._map('id')})

	if (!utils.exists(teamIdsParams)) {
		teamIdsParams = managerTeams._map('id')
	}

	const {shiftsPerTeam, allShifts} = useTotalShiftsPerTeam({team_ids: teamIdsParams, dateRange})
	const okTeamIds = allShifts._map('team_id')
	let templates = PlanningTemplate.all().map(t => t.withEnv())

	if (periodKind) {
		templates = templates.filter(t => (periodKind == 'day' ? t.isDay() : !t.isDay()))
	}

	const teamIdsWithShifts = getKeys(shiftsPerTeam)

	const {teamFilter} = usePlanningTeamFilter({
		team_ids: teamIdsWithShifts,
		buildLabel: (str, item) => labelWithShiftCount(str, item, shiftsPerTeam),
	})
	useEffect(() => {
		if (id) {
			const temp = templates.find(t => t.id == id)
			setValues({kind: temp.kind, team_ids: _.intersection(temp.team_ids, okTeamIds)})
		}
	}, [id])

	const classNames = utils.makeCns('save-template-form eitje-form-2 eitje-form-2-use-borders', ids && 'save-template-form-by-shifts')

	const opts = [
		{name: t('planning.save_template.use_existing'), id: 'update'},
		{name: t('planning.save_template.new_template'), id: 'create'},
	]

	const dayOrWeek = [
		{name: t('common.day_template'), id: 'day'},
		{name: t('common.week_template'), id: 'week'},
	]

	const isCreate = save_kind == 'create'
	const isUpdate = save_kind == 'update'
	const isDay = kind == 'day'
	const showLastFields = ids || isUpdate || kind
	const shiftsByDay = allShifts.groupBy(s => s._date().weekdayName())

	const weekdayItems = utils.weekdays.map((i, idx) => {
		const amtShifts = shiftsByDay[i]?.length || 0
		return {id: idx, disabled: amtShifts == 0 && t('planning.save_template.day_disabled'), name: `${t(i)} (${amtShifts})`}
	})

	return (
		<div className={classNames}>
			<FormRow>
				<ListPicker dropdown single field="save_kind" items={opts} />
			</FormRow>

			{save_kind && (
				<>
					{isCreate && !ids && (
						<FormRow>
							<ListPicker dropdown single field="kind" items={dayOrWeek} />
						</FormRow>
					)}

					{showLastFields && (
						<>
							<FormRow>{isUpdate ? <TemplatesPicker items={templates} /> : <Input required field="naam" />}</FormRow>
							{isDay && (
								<FormRow>
									<ListPicker required noSort dropdown single field="day_of_week" items={weekdayItems} />
								</FormRow>
							)}
							{!ids && <FormRow>{teamFilter}</FormRow>}
						</>
					)}
				</>
			)}
		</div>
	)
}
const TemplatesPicker = ({items}) => {
	return <ListPicker groupField="envStatus" labelField="nameWithPeriod" required dropdown single field="id" items={items} />
}

export default Form
