import {t} from 'initializers/i18n'
import RevenueInfo from './revenue_info'
import useHasRole from 'hooks/use_has_role'
import {useRevenueFooterContext} from './revenue_footer_provider'
import {EitjeButton, SettingsButton, Layout} from 'common/components'
import {Text} from 'common/components'

const Header = () => {
	const {dateArray, featureName} = useRevenueFooterContext()
	const startDate = dateArray[0]
	const {env, isHours} = useRevenueFooterContext()
	const hasAccordHoursRole = useHasRole('uren_accorderen', env.id)
	const hasAdminRole = useHasRole('admin', env.id)

	const suffix = isHours ? 'actual' : 'forecast'

	return (
		<Layout className="revenue-footer-header" horizontal="spaceBetween" padding={16} height={64}>
			<div>
				<Text bold popoutBody={<RevenueInfo />} hoverClassName="revenue-footer">
					{t(`${featureName}.revenue_table.title`)} {env.naam}
				</Text>
				<Text small greyText>
					{' '}
					{t(`${featureName}.revenue_table.subtitle`)}{' '}
				</Text>
			</div>

			<Layout>
				{hasAccordHoursRole && (
					<EitjeButton
						height="small"
						iconLeft="euro"
						modalLink={`/environments/${env.id}/revenue/insert/${suffix}?startDate=${startDate.format()}`}
					>
						{t('planning.revenue_table.revenue')}
					</EitjeButton>
				)}
				{hasAdminRole && <SettingsButton height="small" modalLink={`/environments/${env.id}/revenue/settings`} />}
			</Layout>
		</Layout>
	)
}

export default Header
