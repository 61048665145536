import {find, where} from '@eitje/easy_api'
import {channelData, findAvatarImage, initConversation, isOwner, updateToLatestChannel, useChannelAttachments} from 'actions/chat'
import useList from 'components/general/list'
import Modal from 'components/general/modal'
import {ChatStateContext} from 'components/providers/chat_provider'
import {ModalLink} from 'components/routing'
import {getFileExtenstionColor} from 'actions/files'

import {LightboxImage, UserAvatar} from 'components/ui'
import useShared from 'hooks/use_shared'
import {t} from 'initializers/i18n'
import {useContext, useState} from 'react'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {useChannelStateContext, useChatContext} from 'stream-chat-react'

import {makeArrayOfLength} from 'utils/jsutils'
import {UserList} from '../ConversationCreatorContainer'
import useChatOrphaned from 'hooks/use_chat_orphaned'
import {Tabs, OptionsButton, Text, Layout} from 'common/components'
import {DeletePopout} from 'common/components'

export const makeEitjeUserObj = (streamUser = {}) => {
	const {id, image, name} = streamUser
	return {id: Number(id), full_name: name, avatar: image}
}

export const useChannelWatchingUsers = () => {
	const {watchers, members} = useChannelStateContext()

	// ATTENTION watchers returns a different key for user ids than members
	const online = Object.values(watchers).map((user = {}) => makeEitjeUserObj(user))

	const _onlineKeys = Object.keys(watchers)

	const offline = Object.values(members)
		.filter((user = {}) => !_onlineKeys.includes(user.user_id))
		.map((user = {}) => makeEitjeUserObj(user.user))
	return {online, offline}
}

const ChannelAvatar = () => {
	const {channel} = useChannelStateContext()
	const {me, env} = useShared()
	const {otherUser = {}} = channelData(channel, me.id, env)
	const avatarURL = findAvatarImage(channel, me.id)
	const avatarObj = avatarURL ? {avatar: avatarURL} : otherUser // User Image requires a an object resembling a user, this
	return <UserAvatar size="xxxl" user={avatarObj} />
}

const ChannelSubline = () => {
	const {members: _members, watcherCount, channel} = useChannelStateContext()
	const members = Object.values(_members)
	const env = useSelector(state => find(state, 'environments', channel.data.env_id))
	let text = env.naam
	if (members.length === 2 && watcherCount !== 2) {
		return null
	}
	if (members.length === 2 && watcherCount === 2) {
		text = watcherCount === 2 && 'Is Online'
	}
	return <p className="eitje-text-secondary">{text}</p>
}

const DeleteChannel = () => {
	const {toggleConversationMaker} = useContext(ChatStateContext)
	const {setActiveChannel, channel} = useChatContext()
	const handleDelete = async () => {
		await channel.delete()
		await updateToLatestChannel(setActiveChannel)
	}
	return (
		<div onClick={() => toggleConversationMaker(false)}>
			<DeletePopout onDelete={handleDelete}>
				<p className="pointer delete_pointer text">{t('delete')}</p>
			</DeletePopout>
		</div>
	)
}

const ChannelOptions = (props = {}) => {
	const {setGroupEdit, toggleConversationMaker} = useContext(ChatStateContext)
	const {popover} = props

	const handleEditClick = () => {
		setGroupEdit(true)
		toggleConversationMaker(true)
		popover?.hide()
	}

	return (
		<Layout padding={12} direction="vertical" width={100}>
			<Text onClick={handleEditClick} t="edit" />
			<DeleteChannel />
		</Layout>
	)
}

export const ChannelInfoHeader = () => {
	const {me, env} = useShared()
	const {channel, members, watcherCount} = useChannelStateContext()
	const isOrphaned1on1 = useChatOrphaned(channel, members)
	const {displayName} = channelData(channel, me.id, env)
	if (isOrphaned1on1) return null
	return (
		<div className="channel_info_header">
			<ChannelAvatar />
			<div className="flex-grow-one padding-right-xs" style={{maxWidth: '120px'}}>
				<h3 className="prevent-text-overflow">{displayName}</h3>
				<ChannelSubline />
			</div>
			{isOwner(channel, me.id) && <OptionsButton placement="bottom" body={ref => <ChannelOptions popover={ref} />} />}
		</div>
	)
}

export const SmallUserInfo = ({userId}) => {
	const {email, telefoonnummer = '-'} = useSelector(state => find(state, 'user', userId))
	const _tele = telefoonnummer || '-'

	return (
		<div className="small_user_info">
			<div className="margin-bottom-xl">
				<p className="eitje-text-secondary">Email</p>
				<p>{email}</p>
			</div>
			<div>
				<p className="eitje-text-secondary">Telefoonnummer</p>
				<p>{_tele}</p>
			</div>
		</div>
	)
}

const DefaultImageAsset = ({src = ''}) => {
	const hasImage = src.length > 0
	const className = hasImage ? 'default_image_asset' : 'default_image_asset noPoint'
	return <div className={className}>{hasImage && <LightboxImage src={src} />}</div>
}

const ImageContainer = ({urlArray = [], length = 3}) => {
	const displayArray = makeArrayOfLength(length).map((v, i) => {
		return urlArray[i] ? <DefaultImageAsset src={urlArray[i].image_url} /> : null
	})

	return <div className={'image_container'}>{displayArray}</div>
}

const DefaultFileAssest = ({item}) => {
	const {title, asset_url, file_size = 0} = item

	const _title = title || ''
	const type = _title.split(/\.(?=[^\.]+$)/)[1]
	const _type = type || 'n/a'

	const extensionColor = getFileExtenstionColor(type)

	return (
		<a href={asset_url} className="highlight-effect file_asset">
			<div className="file-item-extention" style={{color: extensionColor}}>
				{_type.slice(0, 3).toUpperCase()}
			</div>

			<div className="flex-grow-one">
				<p className="eitje-text-secondary bold prevent-text-overflow" style={{color: '#2b2b2b', maxWidth: '120px'}}>
					{_title}
				</p>
				<p className="eitje-text-secondary">{(file_size / (1024 * 1024)).toFixed(2)}mb</p>
			</div>
			<div>
				<img className="download_image" src={'/images/web/icons/downloadAssetIcon.png'} />
			</div>
		</a>
	)
}

const FileContainer = ({assets = [], length = 6}) => {
	const items = assets.slice(0, length)
	const {list} = useList({
		items: items,
		ListItem: DefaultFileAssest,
	})
	return <div>{list}</div>
}

export const ChannelAssets = ({type, length}) => {
	const {channel, messages: _messages = []} = useChannelStateContext()
	const {messages = []} = useChannelAttachments(channel.cid, _messages.length)

	const isImages = type === 'image'
	const text = isImages ? t('images') : t('files')

	const attArr = messages.map(messageObj => messageObj.message.attachments).flat()
	const assets = attArr.filter(obj => obj.type === type) //can be image or file
	const hasAssets = assets.length > 0
	const hasMore = assets.length > length
	return (
		<div className={isImages ? 'channel-asset-container image' : 'channel-asset-container file'}>
			<div className="channel-asset-head-container">
				<p className="channel-asset-head-title">{text}</p>
				{hasMore && <ModalLink to={`/chat/attachments`} state={{cid: channel.cid, assetType: type}} t="showMore" />}
			</div>
			{!hasAssets && (
				<div className="channel-asset-placeholder">
					<p className="eitje-text-secondary">{t(isImages ? 'noImages' : 'noFiles')}</p>
				</div>
			)}
			{hasAssets && isImages && <ImageContainer urlArray={assets} length={length} />}
			{hasAssets && !isImages && <FileContainer assets={assets} length={length} />}
		</div>
	)
}

const assetTypes = [
	{name: 'Images', id: 'image'},
	{name: 'Files', id: 'file'},
]

export const ChatAttachmentModal = () => {
	const {state} = useLocation()
	const {cid, assetType = 'image'} = state
	const {messages} = useChannelAttachments(cid)

	const [type, setType] = useState(assetType)
	const attArr = messages.map(messageObj => messageObj.message.attachments).flat()
	const assets = attArr.filter((obj = {}) => obj.type === type) //can be image or file

	const hasAssets = assets.length > 0
	const isImage = type === 'image'

	return (
		<Modal hasCancel width={588} title={'All Media'} className="veedree modalEitje attachmentModal image">
			<div style={{position: 'absolute', left: '191px', top: '17px'}}>
				<Tabs raw items={assetTypes} value={type} onChange={setType} />
			</div>
			<div className="modalOverflow" style={{minHeight: '380px'}}>
				{!hasAssets && (
					<div className="fRow aCen jCen" style={{height: '380px'}}>
						<p className="eitje-text-secondary">{t(isImage ? 'noImages' : 'noFiles')}</p>
					</div>
				)}
				{isImage ? <ImageContainer urlArray={assets} length={assets.length} /> : <FileContainer assets={assets} length={assets.length} />}
			</div>
		</Modal>
	)
}

const ExpandableUserList = props => {
	const {userIds = [], users, closeLength, ...rest} = props
	const localUsers = useSelector(state => where(state, 'users', userIds))
	const _users = users || localUsers
	const orderUsers = _.orderBy(_users, [user => user.full_name.toLowerCase()], ['asc'])
	const [displayLength, setDisplayLength] = useState(4)
	const canExpand = closeLength < orderUsers.length
	const expand = displayLength < orderUsers.length
	const displayList = orderUsers.slice(0, displayLength)

	const textButton = (
		<div
			className="eitje-text-secondary margin-left-sm pointer-cursor"
			onClick={() => setDisplayLength(expand ? orderUsers.length : closeLength)}
		>
			{expand ? t('showMore') : t('showLess')}
		</div>
	)
	return (
		<UserList
			{...rest}
			users={users && displayList}
			userIds={displayList.map(user => user.id)}
			headingComponent={canExpand && textButton}
		/>
	)
}

export const ChannelMembersLists = () => {
	const {me, env} = useShared()
	const {online, offline} = useChannelWatchingUsers()
	const {setActiveChannel} = useChatContext()

	const handleUserRowClick = async otherUserID => {
		if (otherUserID !== me.id) {
			const channel = await initConversation([otherUserID, me.id], env.id)
			setActiveChannel(channel)
		}
	}

	return (
		<>
			<div className="chat-right-container">
				<ExpandableUserList users={online} heading={`Online (${online.length})`} onRowClick={handleUserRowClick} />
			</div>
			<div className="chat-right-container">
				<ExpandableUserList
					users={offline}
					closeLength={4}
					userIds={offline}
					heading={`Offline (${offline.length})`}
					onRowClick={handleUserRowClick}
				/>
			</div>
		</>
	)
}
