import {STATUS_NAMES, baseColorMapping} from 'cores/time_registration/index'
import {DocsButton, Layout, LegendContent} from 'common/components'
import {t} from 'initializers/i18n'

export const StatusLegend = ({...rest}) => (
	<Layout direction="vertical" width="100%">
		<LegendContent items={statusLegendItems()} {...rest} />
		<Layout width="100%" borderTop padding={12}>
			<DocsButton to={8228442} width="full" />
		</Layout>
	</Layout>
)

const hideDescriptions = ['verlof', 'bijzonder_verlof', 'ziek']

export const statusLegendItems = () =>
	STATUS_NAMES.map(status => ({
		title: t(`time_registration.statusses.${status}`),
		description: !hideDescriptions.includes(status) && t(`time_registration.statusses_explained.${status}`),
		color: baseColorMapping[status],
	}))
