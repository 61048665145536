import {Input} from 'antd'
import {t} from 'initializers/i18n'
import React, {useContext, useEffect, useState} from 'react'
import {useDebouncedCallback} from 'use-debounce'
import {ChatStateContext} from '../providers/chat_provider'

const ConversationSearchBox = ({footer}) => {
	const {searchUserQuery, setSearchUserQuery, setChannelFilter, modular} = useContext(ChatStateContext)
	const [search, setSearch] = useState(searchUserQuery)
	const [showClose, setShowClose] = useState(false)

	useEffect(() => {
		return () => {
			setSearchUserQuery('')
		}
	}, [])

	const debounced = useDebouncedCallback(search => {
		setSearchUserQuery(search)
	}, 400)

	return (
		<div className={footer && 'search_footer'} modular={modular}>
			<Input
				id="search-legacy"
				autoComplete="off"
				onFocus={() => {
					setChannelFilter('search')
					setShowClose(true)
				}}
				value={search}
				placeholder={t('searchConversations')}
				onChange={e => {
					debounced.callback(e.target.value)
					setSearch(e.target.value)
				}}
			/>
		</div>
	)
}

export default ConversationSearchBox
