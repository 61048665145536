import {t} from 'initializers/i18n'

export const randomTip = () => tipArray[Math.floor(Math.random() * tipArray.length)]
export const tipArray = [
	{
		title: t('tip1'),
		linkNL: 'articles/6021818-handige-tips-voor-omzetregistratie',
		linkEN: 'articles/6021818-handige-tips-voor-omzetregistratie',
	},

	{
		title: t('tip2'),
		linkNL: 'articles/6010068-chat-berichten',
		linkEN: 'articles/6010068-chat-berichten',
	},

	{
		title: t('tip3'),
		linkNL: 'articles/6021852-beschikbaarheid#h_abd9235c78',
		linkEN: 'articles/6021852-beschikbaarheid#h_abd9235c78',
	},

	{
		title: t('tip4'),
		linkNL: 'collections/3364552-eitjeopdevloer',
		linkEN: 'collections/3364552-eitjeopdevloer',
	},

	{
		title: t('tip5'),
		linkNL: 'articles/6015145-aanmaken-van-functies-en-vaardigheden',
		linkEN: 'articles/6015145-aanmaken-van-functies-en-vaardigheden',
	},

	{
		title: t('tip6'),
		linkNL: 'articles/6028425-open-shifts-publiceren',
		linkEN: 'articles/6028425-open-shifts-publiceren',
	},

	{
		title: t('tip7'),
		linkNL: 'articles/6021818-handige-tips-voor-omzetregistratie',
		linkEN: 'articles/6021818-handige-tips-voor-omzetregistratie',
	},

	{
		title: t('tip8'),
		linkNL: 'articles/6028446-video-s-toevoegen-aan-een-artikel',
		linkEN: 'articles/6028446-video-s-toevoegen-aan-een-artikel',
	},

	{
		title: t('tip9'),
		linkNL: 'collections/3355125-data-exporteren',
		linkEN: 'collections/3355125-data-exporteren',
	},

	{
		title: t('tip10'),
		linkNL: 'articles/6021976-financieel',
		linkEN: 'articles/6021976-financieel',
	},

	{
		title: t('tip11'),
		linkNL: 'articles/6020103-toeslagen-op-shifts',
		linkEN: 'articles/6020103-toeslagen-op-shifts',
	},

	{
		title: t('tip12'),
		linkNL: '/',
		linkEN: '/',
	},
]

export const stepProps = {
	teammembers: {
		mins: 3,
		subSteps: {
			users: {
				steps: ['usersStep1', 'usersStep2', 'usersStep3', 'usersStep4'],
				extraStep: ['usersStepExtra'],
				videoUrl: 'teamledenToevoegenV2.mov',
				expLink: 'nl/articles/5967145-teamleden-toevoegen-aan-je-vestiging',
			},

			team: {
				steps: ['teamStep1', 'teamStep2', 'teamStep3', 'teamStep4'],
				videoUrl: 'teamAanmakenV2.mov',
				expLink: 'nl/articles/5967127-teams-aanmaken',
			},

			user_teams: {
				steps: ['teamUsersStep1', 'teamUsersStep2', 'teamUsersStep3', 'teamUsersStep4', 'teamUsersStep5'],
				extraStep: ['teamUsersStepExtra'],
				videoUrl: 'toevoegenTeamsV2.mov',
				expLink: 'nl/articles/5967160-teamlid-toevoegen-aan-een-team',
			},
		},
	},

	planning: {
		mins: 10,
		subSteps: {
			shift_created: {
				steps: ['shiftCreated1', 'shiftCreated2', 'shiftCreated3'],
				extraStep: ['shiftCreatedExtra'],
				videoUrl: 'aanmakenShiftV2.mov',
				expLink: 'nl/articles/5967178-het-aanmaken-van-een-shift',
			},

			user_shift_created: {
				steps: ['userShiftCreated1', 'userShiftCreated2', 'userShiftCreated3', 'userShiftCreated4'],
				extraStep: ['userShiftCreatedExtra'],
				videoUrl: 'plannenViaBeschikbaarheid.mov',
				expLink: 'nl/articles/6009387-inplannen-van-je-teamleden',
			},

			published: {
				steps: ['plan_published1', 'plan_published2', 'plan_published3'],
				extraStep: ['plan_publishedExtra'],
				videoUrl: 'shiftsPublicerenV2.mov',
				expLink: 'nl/articles/6009484-publiceren-van-je-planning',
			},
		},
	},

	planning_extra: {
		mins: 3,
		subSteps: {
			template_created: {
				steps: ['tempCreate1', 'tempCreate2', 'tempCreate3', 'tempCreate4'],
				extraStep: ['tempCreateExtra'],
				videoUrl: 'newTemplateV2.mov',
				expLink: 'nl/articles/6009519-rooster-opslaan-als-template',
			},

			template_loaded: {
				steps: ['tempLoaded1', 'tempLoaded2', 'tempLoaded3', 'tempLoaded4'],
				extraStep: ['tempLoadedExtra'],
				videoUrl: 'templateInladenV2.mov',
				expLink: 'nl/articles/6028350-het-inladen-van-een-template-in-de-planning',
			},
		},
	},

	inwerken: {
		mins: 15,
		subSteps: {
			sets: {
				steps: ['inwerkenSets1', 'inwerkenSets2', 'inwerkenSets3', 'inwerkenSets4'],
				extraStep: ['inwerkenSetsExtra'],
				videoUrl: 'newTopic.mov',
				expLink: 'nl/articles/6009233-een-nieuw-topic-maken',
			},

			manual: {
				steps: ['inwerkenManual1', 'inwerkenManual2', 'inwerkenManual3', 'inwerkenManual4'],
				extraStep: ['inwerkenManualExtra'],
				videoUrl: 'nieuwArtikel.mov',
				expLink: 'nl/articles/6014511-een-nieuw-artikel-aanmaken',
			},

			skills: {
				steps: ['inwerkenSkills1', 'inwerkenSkills2', 'inwerkenSkills3', 'inwerkenSkills4'],
				extraStep: ['inwerkenSkillsExtra'],
				videoUrl: 'aanmakenVaardigheid.mov',
				expLink: 'nl/articles/6015145-aanmaken-van-functies-en-vaardigheden',
			},
		},
	},

	uren: {
		mins: 10,
		subSteps: {
			actief: {
				steps: ['urenActief1', 'urenActief2'],
				extraStep: ['urenActiefExtra'],
				expLink: 'nl/articles/6018910-de-basis-van-je-urenregistratie',
			},

			reg_shift_created: {
				steps: ['urenReg1', 'urenReg2', 'urenReg3', 'urenReg4'],
				extraStep: ['urenRegExtra'],
				videoUrl: 'snelAccorderenV2.mov',
				expLink: 'nl/articles/6018966-uren-accorderen',
			},

			exported: {
				steps: ['urenExpo1', 'urenExpo2', 'urenExpo3'],
				extraStep: ['urenExpoExtra'],
				expLink: 'nl/articles/6019096-exporteren-van-de-gewerkte-uren',
			},
		},
	},
	inklokken: {
		mins: 5,
		subSteps: {
			devices: {
				steps: ['inkDevice1', 'inkDevice2', 'inkDevice3', 'inkDevice4', 'inkDevice5'],
				extraStep: ['inkDeviceExtra'],
				expLink: 'nl/articles/6022489-eitjeopdevloer',
			},

			check_ins: {
				steps: ['inkCheck1', 'inkCheck', 'inkCheck3', 'inkCheck4'],
				extraStep: ['inkCheckExtra'],
				expLink: 'nl/articles/6022746-inloggen-bij-eitjeopdevloer',
			},
		},
	},
}
