import {t} from 'initializers/i18n'
import React, {useContext} from 'react'
import {LoadingChannels} from 'stream-chat-react'
import {ChatStateContext} from '../../providers/chat_provider'
import WithoutOneToOne from '../WithoutOneToOne'

const Loader = () => {
	return (
		<div>
			<LoadingChannels />
			<LoadingChannels />
			<LoadingChannels />
			<LoadingChannels />
		</div>
	)
}

const EmptyList = () => {
	return (
		<div className="fRow aCen jCen" style={{width: '100%', marginTop: '40px'}}>
			<p className="eitje-text-secondary bold">{t('noConversationsFound')}</p>
		</div>
	)
}

const seperateChannels = channelComponentsArr => {
	if (!Array.isArray(channelComponentsArr)) return null

	const oneToOne = channelComponentsArr.filter(c => c.props.channel.type === 'messaging')
	const many = channelComponentsArr.filter(c => c.props.channel.type !== 'messaging')

	return {oneToOne, many}
}

const Channel = ({components, heading}) => {
	const {modular} = useContext(ChatStateContext)
	return (
		<div style={{marginBottom: 40}}>
			<div style={{margin: '0px 0px 12px 12px'}}>
				<p className="bold">{heading}</p>
			</div>
			{components}
		</div>
	)
}

const Channels = ({type, components}) => {
	if (!components) return <EmptyList />
	return (
		<div>
			{components?.oneToOne.length > 0 && <Channel components={components.oneToOne} heading="Conversations" />}
			<WithoutOneToOne />
			{components?.many.length > 0 && <Channel components={components.many} heading="Groups & Teams" />}
		</div>
	)
}

const ChannelListContainer = props => {
	const {modular, channelFilter, searchUserQuery} = useContext(ChatStateContext)

	if (channelFilter === 'search' && searchUserQuery.length > 0) {
		const channelComponents = seperateChannels(props.children.props.children)

		return (
			<div style={{flex: 1}} modular={modular} search={channelFilter === 'search'}>
				{props.loading ? <Loader /> : <Channels type={channelFilter} components={channelComponents} />}
			</div>
		)
	}

	return (
		<div style={{flex: 1}} modular={modular} search={channelFilter === 'search'}>
			{props.loading ? <Loader /> : props.children}
		</div>
	)
}

export default ChannelListContainer
