import {useMemo} from 'react'
import useSelector from 'hooks/use_selector'
import {date} from 'initializers/date'
import {rangeFromStart, rangeToDateArray, makeRange} from 'helpers/date'
import {store} from 'index'
import Moment from 'moment'

const usePerMemberDates = () => {
	const state = useSelector(state => state.timeRegistration.per_member)
	let {startDate, endDate, period} = state
	startDate = startDate ? date(startDate) : date().startOf('week')
	endDate = endDate ? date(endDate) : date().endOf('week')
	const dateRange = useMemo(() => makeRange(startDate, endDate), [startDate, endDate])

	const dateArray = rangeToDateArray(dateRange)
	const diff = endDate.startOf('day').diff(startDate.startOf('day'), 'days')

	const setDates = date => {
		const newRange = rangeFromStart({start: date, length: diff}) // wrong
		setRawDates(newRange.start, newRange.end)
	}

	const setRawDates = (start, end, period) => {
		store.dispatch({
			type: `SET_REG_PER_MEMBER_DATES`,
			startDate: formatDate(start),
			endDate: formatDate(end),
			period,
		})
	}

	const setPeriod = period => {
		store.dispatch({
			type: `SET_REG_PER_MEMBER_PERIOD`,
			period,
		})
	}

	period = period || getPeriod(startDate, endDate)

	// const rangePickerProps = {length: periodLength, initialValue: startDate, onChange: dates => setDates(dates[0])  }

	return {setDates, setPeriod, setRawDates, period, diff, dateArray, dateRange, startDate: dateRange.start, endDate: dateRange.end}
}

const formatDate = _date => {
	return Moment.isMoment(_date) ? _date.format() : _date
}

const getPeriod = (startDate, endDate) => {
	const diff = endDate.startOf('day').diff(startDate.startOf('day'), 'days')
	if (startDate.clone().startOf('month').format() == startDate.format() && endDate.clone().endOf('month').format() == endDate.format())
		return 'month'

	if (diff == 0) return 'day'
	if (diff == 6) return 'week'
	return 'custom'
}

export default usePerMemberDates
