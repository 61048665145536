import utils from '@eitje/web_utils'
import Table from 'components/general/table'
import useSearch from 'hooks/use_search'
import {store} from 'index'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'
import {EitjeButton, ListPicker} from 'common/components'

const useTable = ({
	items = [],
	name,
	fields = [],
	stickyFields = [],
	searchProps = {},
	rowProps = {},
	tableProps = {},
	placeholderProps = {},
	alwaysShowPlaceholder,
	searchEmptyProps,
	...rest
}) => {
	let filterValue = useSelector(state => state.filters[name])
	const activeFields = !utils.exists(filterValue) ? fields : fields.filter(f => filterValue.includes(f)) // keep sort order of fields order
	const {filteredItems, searchInput, emptyElement} = useSearch(items, {
		...searchProps,
		searchEmptyProps,
		placeholderProps,
		alwaysShowPlaceholder,
	})

	const table = (
		<Table {...tableProps} rowProps={rowProps} fields={activeFields} items={filteredItems} placeholder={emptyElement} {...rest} />
	)

	const columnItems = fields.filter(f => !stickyFields.includes(f)).map(i => ({id: i, name: t(`table.${i}`, t(`records.${i}`))}))

	const columnPicker = (
		<ListPicker
			raw
			dropdown
			noSort
			Trigger={ImageButton}
			showClearAll
			value={filterValue}
			onChange={val => store.dispatch({name, type: 'SET_FILTER', value: [...stickyFields, ...val.uniq()]})}
			items={columnItems}
		/>
	)

	return {searchInput, table, columnPicker}
}

export default useTable

const ImageButton = ({onClick}) => {
	return <EitjeButton iconLeft="three-sliders" onClick={onClick} t="editColumns" />
}
