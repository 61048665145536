import {contractFields, managerFields, tableFields} from 'constants/users'
import {useShared} from 'hooks/use_shared'
import useUserTable from 'hooks/use_user_table'
import {Redirect} from 'react-router-dom'
import Header from '../users/header'
import useActiveUsers from 'hooks/use_active_users'

// DOES THIS GET USED / HOW DOES ROUTING TO ORG USERS WORK?

const Users = () => {
	const {hasManagerRole, hasContractRole, myOrgEnvs, env} = useShared()
	const users = useActiveUsers()

	let fields = [tableFields, hasManagerRole && managerFields, hasContractRole && contractFields].filter(Boolean).flat()

	const {searchInput, columnPicker, table} = useUserTable({fields, items: users, disableTeams: true})
	if (myOrgEnvs.length == 1) return <Redirect to={`/teams/${env.locked_team_id}`} />
	return (
		<div className="veedree">
			<Header searchInput={searchInput} columnPicker={columnPicker} />
			<div children={table} />
		</div>
	)
}

export default Users
