import useSelector from 'hooks/use_selector'
import {date} from 'initializers/date'
import {t} from 'cores/time_registration/pages/add/index'
import useFormData from 'hooks/use_form_data'
import {useSimpleContext} from 'contexts/simple'
import {roleOrgEnvsSelector} from 'selectors/new_settings'
import {EnvFormDropdownPicker} from 'cores/firm/index'
import './styles/header.less'
import {Layout} from 'common/components'

const Header = () => {
	const {isEdit, isPickingUser} = useSimpleContext()
	const {dates = []} = useFormData()
	const _date = dates[0]
	const roleEnvs = useSelector(roleOrgEnvsSelector, 'uren_schrijven')
	const title = isEdit ? 'edit_title' : 'new_title'
	const width = isPickingUser ? 'calc(100% - 240px)' : '100%'

	return (
		<Layout className="time-registration-modal-header" gap={8} horizontal="last-item-end" width={width}>
			<h3>{t(title)}</h3>
			{_date && <h5 className="time-registration-shift-date"> {date(_date).format('DD MMMM YYYY')} </h5>}
			<EnvFormDropdownPicker envs={roleEnvs} />
		</Layout>
	)
}

export default Header
