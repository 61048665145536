import {EitjeDropdown} from 'common/components'
import {useApprovePerEnv} from './approve_per_env'
import {useEditShifts} from './edit_shifts'
import {usePerWeekContext} from 'cores/time_registration'
import {NAMESPACE} from 'cores/time_registration/pages/per_week/week_table_days_header'
import {sharedViewOptions} from 'common/components/week_table'

export const DayDropdown = ({date, ...rest}) => {
	const elements = [...useApprovePerEnv(date), useEditShifts(date), useViewOptions()]
	return <EitjeDropdown elements={elements} {...rest} />
}

export const PanelDropdown = ({...rest}) => {
	const {dateRange} = usePerWeekContext()
	const elements = [...useApprovePerEnv(dateRange), useEditShifts(), useViewOptions()]
	return <EitjeDropdown elements={elements} {...rest} />
}

function useViewOptions() {
	const options = ['hoursShowTotalWorked', 'hoursShowPlannedWorkedDiff']
	return sharedViewOptions(options, NAMESPACE)
}
