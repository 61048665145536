import {find} from '@eitje/easy_api'
import {DelPopOver} from 'components/users'
import {tableFieldsDeleted} from 'constants/users'
import useParams from 'hooks/use_params'
import {useShared} from 'hooks/use_shared'
import useUserTable from 'hooks/use_user_table'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {whereInc} from 'selectors/records'
import Header from '../users/header'

const actionMenu = (item, {...rest}) => <DelPopOver style={{padding: 8}} user={item} {...rest} />

const UnemployedUsers = () => {
	const {id} = useParams()
	const team = useSelector(state => find(state, 'teams', id))
	const env = useSelector(state => find(state, 'environments', team.environment_id))
	const users = useSelector(state => whereInc(state, 'users', {del_env_ids: [env.id]}))
	const {hasManagerRole} = useShared()
	const {searchInput, columnPicker, table} = useUserTable({
		fields: tableFieldsDeleted,
		items: users,
		actionMenu,
	})
	if (!hasManagerRole) return <Redirect to="/users" />

	return (
		<div className="veedree">
			<Header searchInput={searchInput} columnPicker={columnPicker} />
			<div children={table} />
		</div>
	)
}

export default UnemployedUsers
