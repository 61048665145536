import {ModalLink} from 'components/routing'
import {useEnvsContext, usePlanningContext} from 'cores/planning/index'
import {TimeSettingsLink} from 'cores/time_registration'
import {filterActive} from 'helpers'
import {Text, ToolsButton} from 'common/components'
import {useFilterFooterContext} from 'common/components/filter_footer/context'
import {t} from 'initializers/i18n'
import {useRoleMessage} from 'hooks'

export const Tools = props => {
	const {startDate, endDate} = usePlanningContext()
	const {setMultiEdit} = useEnvsContext()
	const {
		filteredItems,
		hasFiltered,
		envs,
		filterValues: {teamIds},
	} = useFilterFooterContext()
	const disabled = useRoleMessage({roles: 'manager', env: envs})
	const state = {start_date: startDate.format(), end_date: endDate.format()}
	const baseStr = 'planning.environment_table.tools_buttons'
	const queryParams = {team_ids: teamIds, ...filterActive(hasFiltered.team)}

	let elements = []
	if (!disabled)
		elements = [
			{icon: 'pencil', element: <Text onClick={() => setMultiEdit(true)} t="planning.multi_edit.title" />},
			{icon: 'download', element: <ModalLink to="/planning_templates/load" queryParams={queryParams} t={`${baseStr}.load_template`} />},
			{
				icon: 'floppy',
				element: <ModalLink to="/planning_templates/save" queryParams={queryParams} t={`${baseStr}.save_template`} />,
				disabled: filteredItems.length == 0 && t('planning.save_template.disabled'),
			},
			{
				icon: 'copy',
				element: <ModalLink to="/planning_templates" t={'planning.save_template.edit_template'} queryParams={queryParams} />,
			},
			{
				icon: 'trash',
				element: <ModalLink to="/planning/clear_week" queryParams={queryParams} t={`${baseStr}.clear_week`} />,
			},
			{
				icon: 'calendar',
				element: <ModalLink to="/forms/events" t={`${baseStr}.add_event`} />,
			},
			{
				icon: 'document-blank',
				element: <ModalLink to="/exports/planning" state={state} t={`${baseStr}.export_planning`} />,
			},
			{
				icon: 'cog-wheel',
				element: <TimeSettingsLink />,
			},
		]

	return <ToolsButton {...props} disabled={disabled} elements={elements} />
}
