import {date} from 'initializers/date'

export const DateCell = ({getValue}) => {
	const val = getValue()
	return date(val).formatDate({year: true})
}

export const DateTimeCell = ({getValue}) => {
	const val = getValue()
	return date(val).formatDate({year: true, time: true})
}
