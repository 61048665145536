import {makeRange} from 'helpers/date'
import ActiveRecord from 'models/active_record/base'
import {t} from 'initializers/i18n'

class Allowance extends ActiveRecord {
	static tableName = 'allowances'

	dateRanges(shift) {
		const {start_time, end_time} = this.week_rule
		const dates = [shift.date, shift.dateAtEnd()].filter(Boolean)
		return dates.map(d => makeRange(`${d} ${start_time}`, `${d} ${end_time}`))
	}

	isFunction() {
		return !this.week_rule && !this.is_holiday
	}

	isIrregular() {
		return !!this.week_rule
	}

	activeOnDay(weekday) {
		if (!this.week_rule) return false
		return this.week_rule.relevant_days.includes(weekday)
	}

	label() {
		if (this.name) return `${t('functionAllowance')} "${this.name}"`
		if (this.isIrregular()) return t('irregAllowance')
		return t('holiAllowance')
	}
}

export default Allowance
