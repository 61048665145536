import {date} from 'initializers/date'
import {ModalLink} from 'components/routing'
import {DurationBlock} from 'components/shared/index'
import {t} from 'initializers/i18n'
import {getPlanningShiftUrl} from 'cores/planning'
import './styles/error_row.less'
import {EitjeAvatar} from 'common/components'

const ErrorRow = ({item}) => {
	const url = getPlanningShiftUrl({shift: item})
	return (
		<ModalLink to={url} className="error-row">
			<UserCard item={item} />
		</ModalLink>
	)
}

const UserCard = ({item}) => {
	const {user = {}, date, error} = item
	const {full_name = t('common.open_shift')} = user
	const avatarProps = user?.id ? {user} : {type: 'open-shift'}
	return (
		<div className="registration-errors-row">
			<EitjeAvatar {...avatarProps} />
			<div className="registration-errors-user-info">
				<h5 className="registration-errors-user-name"> {full_name} </h5>
				<h6 className="registration-errors-shift-date"> {date(date).format("dddd DD MMM 'YY")} </h6>
			</div>
			<DurationBlock item={item} />
			<h6 className="registration-errors-error"> {error} </h6>
		</div>
	)
}

export default ErrorRow
