import React, {useContext} from 'react'
import {ChatStateContext} from '../../providers/chat_provider'

const removeChat = (activelySelectedChannels, setActivelySelectedChannels, currentChannel, setCurrentChannel, channel) => {
	const newArr = activelySelectedChannels.filter(chan => channel.cid !== chan.cid)
	if (currentChannel.cid === channel.cid) {
		setCurrentChannel({})
	}
	setActivelySelectedChannels(newArr)
}

const RemoveChatButton = ({channel, small, setHover}) => {
	const {activelySelectedChannels, setActivelySelectedChannels, currentChannel, setCurrentChannel} = useContext(ChatStateContext)

	const remove = {
		onClick: () => removeChat(activelySelectedChannels, setActivelySelectedChannels, currentChannel, setCurrentChannel, channel),
	}

	return small ? (
		<div className="channel_list_icons" {...remove}>
			<img src={'/images/web/icons/closeChatIcon.png'} />
		</div>
	) : (
		<div className="modular-chat-small-cross" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...remove} />
	)
}

export default RemoveChatButton
