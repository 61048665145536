import utils from '@eitje/web_utils'
import {date} from 'initializers/date'

export const TimeCell = ({getValue}) => {
	const time = getValue()
	if (_.isNumber(time)) {
		return time !== 0 && utils.minutesToString(time)
	} else {
		const dateTime = date(time)
		if (dateTime.isDateTime()) {
			return dateTime.format('HH:mm')
		}
	}
	return null
}

export const TimeCellWithZero = ({getValue}) => {
	const time = getValue()
	return _.isNumber(time) && utils.minutesToString(time)
}
