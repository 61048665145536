import {chatClient} from 'actions/chat'
import {store} from 'index'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'

const unreadCountNotifs = ['message.new', 'notification.mark_read']

const handleUpdate = (event, initUnreadCount) => {
	if (event.total_unread_count == undefined) return
	if (!unreadCountNotifs.includes(event.type)) return

	if (event.total_unread_count == initUnreadCount) return
	store.dispatch({type: 'INIT_UNREAD_COUNT', initUnreadCount: event.total_unread_count})
}

export const useTotalChatUnreadCount = () => {
	const initUnreadCount = useSelector(state => state.chat.initUnreadCount)

	useEffect(() => {
		const subscription = chatClient.on(event => handleUpdate(event, initUnreadCount))
		return () => {
			subscription.unsubscribe()
			chatClient.off()
		}
	}, [])

	return initUnreadCount
}
