import useEnvSetting from 'hooks/use_env_setting'
import useShared from 'hooks/use_shared'
import useBillingDone from 'cores/billing/hooks/use_billing_done'
import {isProd} from 'constants/general'
import {useLocalValue} from 'hooks/use_local'

const useOnboardingHeader = () => {
  const {hasAdminRole, env} = useShared()
  const debug = useLocalValue('debugOnboardingHeader')
  const demoActive = useEnvSetting('onboarding', 'active')
  const billingDone = useBillingDone()

  if (!isProd && debug) return true
  return env.status != 'eitje' && hasAdminRole && (!billingDone || demoActive)
}

export default useOnboardingHeader
