import {EitjeVirtuoso} from '@eitje/web_components'
import {getTaxItem, InboxItem} from 'cores/firm/components/inbox'
import {Placeholder, Layout} from 'common/components'
import {useReminders} from './reminders'
import {useOperations} from './operations'

export * from './operations'

export const InboxList = ({width = 450, height = 450, name, placeholderAnimation, itemSize, data}) => {
	const {placeholder} = getTaxItem(name)
	const animation = placeholderAnimation || placeholder?.animation
	const taxonomyData = useInboxData(name)
	if (!data) data = taxonomyData.sortBy(d => -d.createdAt)
	const hasData = data.length > 0

	return (
		<Layout className="inbox-list">
			{hasData && <EitjeVirtuoso style={{width, height}} data={data} itemContent={(_, row) => <InboxItem size={itemSize} {...row} />} />}

			{!hasData && (
				<Layout height={height} width={width} horizontal="center">
					<Placeholder name={`inbox.${name}`} animation={animation} />
				</Layout>
			)}
		</Layout>
	)
}

export function useInboxData(name) {
	const {groupName, scope} = getTaxItem(name)
	const all = _.flatten([useOperations(), useReminders()])
	const operations = useOperations({scope})
	const reminders = useReminders(name)

	if (groupName == 'all') return all
	if (groupName == 'operations') return operations
	if (groupName == 'reminders') return reminders
}
