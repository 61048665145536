import React, {useEffect, Fragment, useRef} from 'react'
import Form from 'initializers/form'
import {LegacyInput as Input} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API} from '@eitje/easy_api'
import Modal from 'components/general/modal'
import {Button} from 'components/ui'
import utils from '@eitje/web_utils'
import {Redirect} from 'components/routing'
import {history} from 'index'
import {getBillingInfo} from 'actions/billing'
import useBillingInfo from 'cores/billing/hooks/use_billing_info'
import PdfViewer from 'components/pdf_viewer'
import SignatureCanvas from 'react-signature-canvas'
import {Signed} from '../components/index'
import {usePusherUser} from 'hooks/use_pusher'
import {DocsButton} from 'common/components'
import './sign.less'

const createSignedNotification = async () => {
	await history.replace('/news')
	utils.notify({
		title: t('billing.titles.activated_account'),
		icon: 'checked',
		duration: 0,
		content: <Signed />,
		className: 'successfully-signed-notification',
	})
}

const Sign = () => {
	const billingInfo = useBillingInfo()
	const {pdf} = billingInfo
	useEffect(getBillingInfo, [])
	usePusherUser('billing.contract_generated', getBillingInfo)
	const form = useRef(null)
	const canvas = useRef(null)

	if (billingInfo.needs_mandate) return <Redirect to="/billing/setup_mollie" />
	if (billingInfo.signed) return <Redirect to="/" />
	const submit = data => {
		if (canvas.current.isEmpty()) {
			utils.errNotif(t('oops'), t('billing.errors.sign_please'))
			return
		}

		const signature = canvas.current.toDataURL()
		return API.arbitrary('billing_infos', 'sign', {...data, signature})
	}

	return (
		<Modal insertAtIndex={{1: <DocsButton docsId={8765642} />}} onOk={() => form.current.submit()} size="xl" okText={t('common.sign')}>
			<div id="billing-sign-page">
				<div className="billing-sign-modal-left-side">
					<h2 className="billing-sign-modal-title"> {t('billing.titles.almost_done')} </h2>
					<h5 className="billing-sign-modal-text"> {t('billing.texts.sign')} </h5>
					<Form afterSubmit={() => createSignedNotification()} ref={form} onSubmit={submit}>
						<Input
							required
							field="signer_name"
							label={t('form.billing.label.signer_name')}
							placeholder={t('form.billing.placeholder.signer_name')}
						/>
					</Form>

					<Fragment>
						<h5 className="sign-instructions-text"> {t('billing.texts.sign_instructions')} </h5>
						<SignatureCanvas ref={canvas} penColor="blue" canvasProps={{className: 'signature-canvas'}} />
						<Button onClick={() => canvas.current.clear()} className="clear-signature-canvas-button">
							{' '}
							clear{' '}
						</Button>
					</Fragment>
				</div>

				<div className="billing-sign-modal-right-side">
					{pdf ? <PdfViewer modalView pdf={pdf} /> : <p className="waiting-for-pdf-text"> waiting for pdf.. </p>}
				</div>
			</div>
		</Modal>
	)
}

export default Sign
