import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import useShared from 'hooks/use_shared'

export const useActiveUsers = () => {
	const {myOrgEnvIds} = useShared()
	const users = useAll('users').filter(u => utils.intersects(u.environment_ids, myOrgEnvIds))
	return users
}

export default useActiveUsers
