import {Fragment} from 'react'
import utils from '@eitje/web_utils'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {Link as RouterLink, Redirect as RouterRedirect, useHistory, useLocation} from 'react-router-dom'
import {genericHashLink} from 'react-router-hash-link'
import {Route} from 'components/private_route'
import SideMenu from 'components/routing/side_menu'
import useSubRoutes from 'hooks/use_sub_routes'
import {EitjeButton, ListPicker} from 'common/components'
import {navigateModal} from 'actions/routing'
import {date} from 'initializers/date'
import {history} from 'index'
import {childrenWithClonedProps} from 'helpers/children'
import {Text} from 'common/components'
import {useDocsLink} from 'hooks'
import Moment from 'moment'
import './styles/routing.less'

const Redirect = ({keepState = true, to, ...props}) => {
	const actualTo = useActualTo(to)
	const currentState = useLocation().state
	if (keepState && !to.state) {
		actualTo.state = currentState
	}

	return <RouterRedirect to={actualTo} {...props} />
}

const RedirectBack = () => {
	history.goBack()
	return null
}

export function navigate(to) {
	return history.push(to)
}

export {Route, SideMenu, Redirect, RedirectBack}

const fixRelPath = (to, loc) => {
	if (!to) return to
	if (to[0] != '/') {
		const extra = loc.pathname[loc.pathname.length - 1] == '/' ? '' : '/'
		to = `${loc.pathname}${extra}${to}`
	}
	return to
}

const useActualTo = (to, props) => {
	if (_.isNumber(to)) to = to.toString()
	if (_.isString(to)) to = {pathname: to}

	to = {...props, ...to}
	const loc = useLocation()
	let {pathname, search} = to
	if (_.isObject(search)) search = buildSearchParams(search)

	if (!utils.exists(search) && pathname?.split) {
		;[pathname, search] = pathname.split('?')
	}
	to['search'] = search

	to['pathname'] = fixRelPath(pathname, loc)

	return to
}

export const buildSearchParams = obj => {
	let str = ''
	Object.keys(obj).forEach(k => {
		const prefix = utils.exists(str) ? '&' : '?'
		let val = obj[k]
		if (!utils.exists(val)) return
		if (Moment.isMoment(val)) val = val.format()
		str += `${prefix}${k}=${val}`
	})
	return str
}

export const Link = ({children, style, type, to, className, styleType, ...rest}) => {
	const actualTo = useActualTo(to, rest)
	if (actualTo['pathname']) actualTo['pathname'] = actualTo['pathname'].trim()
	className = utils.makeCns(className, 'link', 'eitje-link', styleType)
	// TODO: make relative urls work, also with params
	const _children =
		children &&
		childrenWithClonedProps({
			children,
			props: rest,
		})

	return (
		// style should not be spread or it will end up in the children props
		<RouterLink to={actualTo} {...rest} className={className} style={style}>
			{_children || <Text {...rest} />}
		</RouterLink>
	)
}

export const ExtLink = ({children, to, href, target = '_blank', className, disabled, ...rest}) => {
	const _to = !disabled && (to || href)
	const classNames = utils.makeCns(className, 'external-link')
	return (
		<a target={target} rel="noreferrer" href={_to} className={classNames}>
			{children}
		</a>
	)
}

const HashLink = genericHashLink(Link)

export const RouteSel = ({items, ignorePath, ...rest}) => {
	const loc = useLocation()
	const hist = useHistory()
	const currentVal = loc.pathname.replace(ignorePath, '')

	return <ListPicker dropdown single raw value={currentVal} items={items} {...rest} onChange={val => hist.push(val)} />
}

export const ModalRedirect = () => {
	let pn = '/'
	const loc = useLocation()
	const bg = loc?.state?.background
	if (bg) {
		pn = bg.pathname
	}

	return <Redirect to={pn} />
}

export const ModalLink = ({to, hash, queryParams, state = {}, hidden, children, disabled, ...rest}) => {
	// we used to determine the previousLoc based on the keepLoc prop, but it seems to be simpler than that:
	// keep loc if you're already in a modal and don't if you're not
	const loc = useLocation()
	const previousLoc = loc?.state?.background || loc
	to = disabled ? undefined : to
	if (hidden) return children
	return (
		<HashLink
			className="modalLink"
			to={{pathname: to, search: queryParams, hash, state: {modalRedirect: true, background: previousLoc, ...state}}}
			{...rest}
			disabled={disabled}
			children={children}
		/>
	)
}

export const LinkButton = ({link, exact = true, style, ...rest}) => {
	return (
		<Link {...rest} to={!rest.disabled && link} exact={exact} style={style}>
			<EitjeButton iconLeft="external-link" {...rest} link={false} />
		</Link>
	)
}

export const ModalLinkBtn = ({modalLink, keepLoc, t: T, style, width, children, ...rest}) => {
	return (
		<ModalLink to={!rest.disabled && modalLink} keepLoc={keepLoc} style={style} {...rest}>
			{/* The empty style object is because (as we suspect) genericHashlink clones its props. including 'style',
			 causing 100% to be passed as style, while full-minus-margin is passed as width,  */}
			<EitjeButton test className="modal-link-button" {...rest} width={width} style={{}} modalLink={false} t={T} children={children} />
			{/* modal = false is VERY important, otherwise we're stuck in a loop */}
		</ModalLink>
	)
}

// button-replace

const makeTrans = (key, item) => t(key, {item: ` $t(routing.${item})`})

const getExtra = loc => {
	const last = loc?.pathname?.split('/')
	return last[last.length - 1]
}

export const RouteSelect = ({baseUrl, modal, items, currentVal, shouldTranslate = true, formKey = 'showPer', ...rest}) => {
	const hist = useHistory()
	const mapped = items.map(i => {
		const isObj = _.isObject(i)
		const label = shouldTranslate ? makeTrans(formKey, isObj ? i.label : i) : i.label
		return isObj ? {...i, label} : {value: i, label}
	})
	const currentLabel = getExtra(hist.location)
	currentVal ||= items.find(i => i == currentLabel || i?.label === currentLabel)
	if (_.isObject(currentVal)) currentVal = currentVal.value
	const method = modal ? navigateModal : hist.push

	return (
		<ListPicker
			valueField="value"
			labelField="label"
			dropdown
			raw
			single
			field="route_select"
			value={currentVal}
			items={mapped}
			{...rest}
			onChange={val => method(!baseUrl ? val : `${baseUrl}${val ? `/${val}` : ''}`, {replace: true})}
		/>
	)
}

const RouteContainer = ({route, subRoute, ...props}) => {
	const {path, subRoutePath} = props
	const routeProps = {...(route.routeProps || {}), ...(props.routeProps || {})}

	const subRoutes = useSubRoutes(route)

	const mainPath = makePath(path, route, {subRoutePath: subRoute && subRoutePath})
	let {subPaths = []} = route
	subPaths = utils.alwaysDefinedArray(subPaths).map(p => makePath(path, {path: p}))

	return (
		<Fragment>
			<EitjeRoute {...props} path={[mainPath, ...subPaths]} exact={subRoutes.length > 0} route={route} routeProps={routeProps} />
			{subRoutes.map(r => (
				<RouteContainer {...props} subRoute route={r} path={mainPath} />
			))}
		</Fragment>
	)
}

const EitjeRoute = ({path, exact = true, route, routeProps, ...rest}) => {
	if (_.has(route, 'exact')) exact = route.exact
	if (!route.component) return null
	const props = {exact, path, render: props => <route.component {...rest} {...props} {...routeProps} {...route} />}
	return <Route {...props} />
}

export const Routes = ({routes, ...props}) => {
	// We would prefer to use a switch here, but that only works with flat react-router children.. can always convert all nice components into that, but would prefer not to, waiting for now.
	return (
		<>
			{routes.map(r => (
				<RouteContainer route={r} {...props} />
			))}
		</>
	)
}

export const makePath = (basePath, pathObj, {subRoutePath} = {}) => {
	if (!utils.exists(pathObj)) return `${basePath}`
	if (_.has(pathObj, 'path') && !pathObj.path) return `${basePath}`
	if (!_.isObject(pathObj)) pathObj = {name: pathObj}

	let path = pathObj.path || pathObj.name
	if (!path && subRoutePath) path = subRoutePath

	let fullPath = `${basePath}/${path}/`
	return fullPath.replace('//', '/')
}

export const DocsLink = ({to, collection, ...rest}) => {
	// In some cases we render an anchor with an onClick, to open Intercom articles in the platform
	const condOpts = useDocsLink({collection, to, isLink: true})
	return (
		<a target="_blank" {...condOpts}>
			<Text {...rest} />
		</a>
	)
}
