import {CellTaxonomy, BasicCell, CellPlaceholder, DefaultGroupedRowCell} from 'common/components/advanced_table'
import utils from '@eitje/web_utils'
import {flexRender} from '@tanstack/react-table'
import {t} from 'initializers/i18n'

///////////
// Get cell components through a) lookup with string name, b) from tax as explicit component or c) the fallback compoent
///////////

const taxCell = cell => {
	return (_.isString(cell) && CellTaxonomy[cell]) || cell
}

export const getCell = column => {
	const {cell} = column.columnDef || {}
	return taxCell(cell) || BasicCell || null
}

export const getGroupedRowCell = column => {
	const {groupedRowCell: cell} = column.columnDef
	return taxCell(cell) || DefaultGroupedRowCell
}

export const getHeaderAggregatesCell = column => {
	const {groupedRowCell, cell} = column.columnDef
	return taxCell(groupedRowCell) || taxCell(cell) || null
}

///////////
// Render functions
///////////

export const renderCell = cell => {
	// Generically fallback on `null` instead of values such as `undefined` to prevent React crashes.

	const Component = getCell(cell.column)
	const {customPlaceholder} = cell.column.columnDef
	const translatedPlaceholder = getTranslatedPlaceholder(cell)

	const value = cell.getValue() || null
	const noValue = !utils.exists(value) && !translatedPlaceholder && !customPlaceholder
	if (noValue) return <CellPlaceholder />

	const renderProps = {...cell.getContext(), translatedPlaceholder}
	return flexRender(Component, renderProps)
}

export const getTranslatedPlaceholder = cell => {
	let {translatedPlaceholder} = cell.column.columnDef
	if (!translatedPlaceholder) return null
	return t(translatedPlaceholder)
}
