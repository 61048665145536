import {Horizontal} from 'components/ui'
import {ActivateEitje, EitjeActivated} from './activate'
import Onboarding from './onboarding'
import useOnboardingHeader from 'cores/onboarding/hooks/use_onboarding_header'
import useBillingDone from 'cores/billing/hooks/use_billing_done'
import useOrgLocked from 'cores/billing/hooks/use_org_locked'
import {FixedHeaderWrapper} from 'common/components'

import './index.less'

const Header = () => {
	const visible = useOnboardingHeader()
	const billingDone = useBillingDone()
	const {locked} = useOrgLocked()
	if (!visible) return null
	return (
		<FixedHeaderWrapper height={48}>
			<Horizontal id="start-eitje-header">
				{billingDone ? <EitjeActivated /> : <ActivateEitje />}
				{!locked && <Onboarding />}
			</Horizontal>
		</FixedHeaderWrapper>
	)
}

export default Header
