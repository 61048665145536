import {backend} from '@eitje/easy_api'
import {Button} from 'antd'
import {ChatStateContext} from 'components/providers/chat_provider'
import useAsyncEffect from 'hooks/use_async_effect'
import useShared from 'hooks/use_shared'
import {store} from 'index'
import {t} from 'initializers/i18n'
import {date} from 'initializers/date'
import {useContext, useState} from 'react'
import {StreamChat} from 'stream-chat'
import {Streami18n} from 'stream-chat-react'

const prodUrl = 'hxmdvtyyknmv'
const testKey = '2t8weyarg776'
const i18n = new Streami18n()

let chatClient = new StreamChat(process.env.REACT_APP_CHAT_KEY || prodUrl, {
	timeout: 10000,
})

const allfilter = {type: {$in: ['messaging', 'team', 'group']}}

const lastUpdated = {last_updated: -1}

async function getToken() {
	const res = await backend.post(`chat/stream_token`, {doNotLoad: true})
	if (!res.problem) {
		const {token} = res.data
		return token
	}
}

const chatInit = async userId => {
	try {
		const token = await getToken()
		if (!token) return
		const user = await chatClient.connectUser(
			{
				id: userId.toString(),
			},
			token,
		)
		user.connection_id && store.dispatch({type: 'INIT_UNREAD_COUNT', initUnreadCount: user.me.total_unread_count})
		return
	} catch (err) {}
}

const initConversation = async (memberArr, currentUserId, currentEnv, groupName, imageURL) => {
	const formattedArr = memberArr.map(id => id.toString())
	const type = memberArr.length > 2 ? 'group' : 'messaging'

	if (type === 'group' && groupName.length === 0) {
		groupName = 'New Group Message'
	}

	const distinctName = type === 'group' ? Date.now() : null

	const newChannel = chatClient.channel(type, distinctName, {
		name: groupName,
		members: formattedArr,
		env_id: currentEnv,
		image: imageURL,
	})

	await newChannel.create()
	const final = await chatClient.queryChannels({cid: newChannel.cid})
	return final[0]
}

const updateChannelName = async (channel, newTitle) => {
	const {env_id, image, env_name} = channel.data
	await channel.update(
		{
			name: newTitle,
			env_id,
			image,
			env_name,
		},
		{text: `This Group has been renamed ${newTitle}`},
	)
}

export const updateChannelImage = async (channel, imageUrl) => {
	const {env_id, image, env_name, name} = channel.data

	await channel.update(
		{
			name,
			env_id,
			env_name,
			image: imageUrl,
		},
		{text: `Group image updated`},
	)
}
const updateToLatestChannel = async setActiveChannel => {
	const channels = await chatClient.queryChannels(allfilter, lastUpdated, {
		watch: true,
		state: true,
	})
	await setActiveChannel(channels[0])
}

const removeElement = (arr, ele) => {
	const index = arr.indexOf(ele)
	arr.splice(index, 1)
	return [...arr]
}

const userSearch = (arr, query) => {
	const str = query.replace(/\s+/g, '').toLowerCase()
	const matches = arr.filter(s => s['full_name'].replace(/\s+/g, '').toLowerCase().includes(str))
	return [...matches]
}

const userSearcher = (arr, query) => {
	if (query.length === 0) {
		return []
	} else {
		return userSearch(arr, query).map(user => user.id.toString())
	}
}

const timeOfLastMessage = channelListProvider => {
	if (!channelListProvider || !channelListProvider.channel) return

	return channelListProvider.channel.state.last_message_at === null
		? channelListProvider.channel.data.created_at
		: date(channelListProvider.channel.state.last_message_at)
}

const lastMessageDisplayTime = _date => {
	const dateInput = date(_date)
	if (dateInput.isSame(date(), 'day')) {
		return dateInput.format('HH:mm')
	}
	if (dateInput.isSame(date().subtract(1, 'day'), 'day')) {
		return t('yesterday')
	}
	return dateInput.format('D MMM')
}

const usersWithoutOneToOne = async (xUserIds, myId) => {
	if (!xUserIds || !myId) return null
	const resp = await chatClient.queryChannels({type: 'messaging'}, {last_message_at: -1}, {limit: 30})
	const withChatArr = resp.map(c => Object.keys(c.state.members)).flat()
	return xUserIds.filter(id => !withChatArr.includes(id.toString()))
}

const typeConverterForStream = input => {
	if (typeof input === 'string') {
		return Number(input)
	}
	if (typeof input === 'number') {
		return input.toString()
	}
}

const addRemoveUsers = async (channel, condition, arrUserObjs) => {
	const verb = arrUserObjs.length > 1 ? ' were ' : ' was '
	const namesInStr = arrUserObjs.map(user => user.full_name).join(', ')
	const ids = arrUserObjs.map(user => user.id.toString())

	if (condition === 'add') {
		await channel.addMembers(ids, {text: `${namesInStr} ${verb} added to group`})
	}
	if (condition === 'remove') {
		await channel.removeMembers(ids, {text: `${namesInStr} ${verb} removed from group`})
	}
}

const isOwner = (channel, userId) => {
	if (channel.type !== 'group') return
	const owners = Object.values(channel.state.members).filter(user => user.role === 'owner' || user.role == 'moderator') || []
	const ownerIds = owners
		.map(o => o?.user?.id)
		.filter(Boolean)
		.map(Number)

	return ownerIds.includes(userId)
}

const orderAvatars = (channel, curSelection) => {
	const isPresent = curSelection.map(ch => ch.cid).includes(channel.cid)
	return isPresent ? curSelection : [...curSelection, channel]
}

const modularClick = (channel, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown) => {
	setCurrentChannel(curChannel => (curChannel?.cid === channel.cid ? null : channel))
	setActivelySelectedChannels(state => orderAvatars(channel, state))
	return typeof toggleChatDropdown === 'function'
		? toggleChatDropdown(() => {
				return false
		  })
		: null
}

const getTeamTitle = (channel, env) => {
	if (channel.data.type !== 'team' || env.length === 1) return ''
	return ` - ${channel.data.env_name}`
}

const findAvatarImage = (channel, userId) => {
	if (channel.data.member_count === 2) {
		return Object.values(channel.state.members).filter(user => user.user.id !== userId.toString())[0].user.image
	}
	return channel.data.image
}

const otherUserOneToOne = (channel, curUserId) => {
	const otherID = Object.keys(channel.state.members).filter(id => id !== curUserId.toString())[0]
	return channel.state.members[otherID].user
}
// shoule be a hook
const channelData = (channel, curUserId, ent) => {
	const {member_count, name, image, type} = channel.data
	const otherUser = member_count === 2 && otherUserOneToOne(channel, curUserId)
	const displayname = type === 'messaging' ? otherUser.name : name
	const imageUrl = member_count > 2 ? image : otherUser.image

	return {displayName: displayname, displayImage: imageUrl, teamName: getTeamTitle(channel, ent), otherUser}
}

export const openChannel = async (otherUserId, currentUserId, envId) => {
	const channel = await initConversation([otherUserId, currentUserId], null, envId)
	if (channel.cid) {
		store.dispatch({type: 'OPEN_TO_ME', openToMe: channel.cid})
	}
}

export const useChannelAttachments = (cid, messageCount) => {
	const [messages, setMessages] = useState([])
	useAsyncEffect(async () => {
		if (cid) {
			const res = await chatClient.search({cid}, {attachments: {$exists: true}}, {sort: {updated_at: 1}, limit: 100})
			if (res.results.length > 0) {
				setMessages(res.results)
			}
		}
	}, [cid, messageCount])

	return {messages}
}

export const ModChatButton = ({userId}) => {
	const {openChat} = useOpenModularChat(userId)
	if (!openChat) return null

	return <Button onClick={openChat}>{t('sendMessage')}</Button>
}

export const useOpenModularChat = userId => {
	const {me, envId} = useShared()
	const {setActivelySelectedChannels, toggleChatDropdown, setCurrentChannel} = useContext(ChatStateContext)

	if (userId === me.id) return {}

	const handleButtonClick = async () => {
		const channel = await initConversation([userId, me.id], null, envId)
		if (!_.isEmpty(channel)) {
			modularClick(channel, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown)
		}
	}

	return {openChat: handleButtonClick}
}

export const updateChannelAvatar = async (channel, imageUrl) => {
	const {env_id, image, env_name} = channel.data
	await channel.update({
		name: newTitle,
		env_id,
		image: imageUrl,
		env_name,
	})
}

export {
	findAvatarImage,
	channelData,
	chatInit,
	chatClient,
	addRemoveUsers,
	getToken,
	initConversation,
	removeElement,
	userSearch,
	isOwner,
	timeOfLastMessage,
	lastMessageDisplayTime,
	userSearcher,
	usersWithoutOneToOne,
	updateChannelName,
	updateToLatestChannel,
	getTeamTitle,
	modularClick,
	i18n,
}
