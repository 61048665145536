import {t} from 'initializers/i18n'
import {Icon} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {flexRender} from '@tanstack/react-table'
import {EitjeDropdown, Layout, Text} from 'common/components'
import {HeaderWithCheckbox, Dropdown} from '.'
import {
	GROUPED_CELL_WIDTH,
	DEFAULT_CELL_WIDTH,
	getPinnedColumns,
	getTableName,
	hasAggregates,
	HeaderTitle,
	HeaderAggregates,
} from 'common/components/advanced_table'

export const HeaderCell = ({header, rows = [], multiEdit, ...rest}) => {
	const context = header.getContext()
	const tableName = getTableName(context)
	const pinnedColumns = getPinnedColumns(context)
	const {column, idx, id} = header
	const defaultTooltipTitle = t(`table.${tableName}.tooltips.${id}`, 'untranslated')
	let {getIsSorted, columnDef} = column
	const isGroupedColumn = column.getIsGrouped()
	const aggFn = column.getAggregationFn()
	let {headerAggregates = true, tooltipTitle = defaultTooltipTitle, hideTooltip, tooltipElement, aggregates} = columnDef

	const _rows = rows
		.map(r => (r.getIsExpanded() ? null : utils.exists(r.subRows) ? r.subRows : r))
		.flat()
		.filter(Boolean)
	tooltipTitle = utils.funcOrVal(tooltipTitle)

	const tooltipUnset = tooltipTitle == 'untranslated' && !tooltipElement
	const showTooltip = !hideTooltip && !tooltipUnset
	const tooltipProps = {
		...(showTooltip && tooltipElement && {popoutBody: tooltipElement(), bodyLayoutProps: {padding: 0}}),
		...(showTooltip && !tooltipElement && {popoutTitle: tooltipTitle}),
		maxWidth: 320,
	}
	const sortDirection = getIsSorted()
	const width = header.column.columnDef.width || (isGroupedColumn ? GROUPED_CELL_WIDTH : DEFAULT_CELL_WIDTH)
	const aggregateValue = aggFn && headerAggregates && aggregates && (
		<HeaderAggregates column={column} idx={idx} id={id} fn={aggFn} rows={_rows} />
	)
	const withCheckbox = multiEdit && (isGroupedColumn || pinnedColumns[0] === header.id)

	return (
		<Dropdown header={header} width={width}>
			<Layout className="advanced-table-header-cell" width={width} padding="2 4 2 8" height={32} colorSet>
				<Layout direction="vertical" gap={0} grow height={withCheckbox ? 'unset' : undefined}>
					<HeaderCellContent header={header} aggregateValue={aggregateValue} tooltipProps={tooltipProps} withCheckbox={withCheckbox} />
				</Layout>
				{sortDirection ? (
					<SortIcon sortDirection={sortDirection} />
				) : (
					<Icon name="chevron-small-down" size="14px" initialFill={Colors.darkGrey} />
				)}
			</Layout>
		</Dropdown>
	)
}

const SortIcon = ({sortDirection}) => {
	const name = `arrow-${sortDirection === 'asc' ? 'up' : 'down'}`
	return <Icon name={name} size="14px" />
}

const HeaderCellContent = ({header, aggregateValue, tooltipProps, withCheckbox}) => {
	const {column, getContext} = header
	const context = getContext()

	if (withCheckbox) {
		return flexRender(props => <HeaderWithCheckbox {...context} {...props} aggregates={aggregateValue} />, {
			...context,
			aggregates,
			tooltipProps,
		})
	}

	const {header: headerValue, aggregates} = column.columnDef
	const value = utils.funcOrVal(headerValue, context)

	return (
		<>
			<HeaderTitle value={value} context={context} tooltipProps={tooltipProps} />
			{aggregates && (
				<Text fontSize={8} semiBold truncate darkGrey>
					{aggregateValue}
				</Text>
			)}
		</>
	)
}
