import React, {Fragment} from 'react'
import {EntityCount, Layout, ShowMoreTooltip} from 'common/components'
import {
	TableText,
	CellPlaceholder,
	getCell,
	getGroupedRowCell,
	hasAggregates,
	getTranslatedPlaceholder,
	COLUMNS_WITH_TOTAL_AS_CHILD,
} from 'common/components/advanced_table'
import {flexRender} from '@tanstack/react-table'

// Prevent the grouped column (with its member count) from having its count overlap the chevron icon
const inlineStyles = {maxWidth: 'calc(100% - 52px)'}

export const GroupedRowCell = ({cell, isGroupedColumn}) => {
	const {column} = cell
	const {id: columnName} = column
	const {aggregates} = column.columnDef

	// Always show a value for the grouped column, otherwise it wouldn't be clear what you grouped on.
	if (!aggregates && !isGroupedColumn) return <CellPlaceholder />

	// Some cells which have a more complicated layout (such as Status cells which include a dot) don't render properly when they
	// are siblings with the total count and should themselves decide where to put the count. Eventually we might make the basic cell
	// layout smarter and decide for itself, but for now we have limited use cases and just really want to ship this code...
	const total = <CountAggregate {...cell} name="items" />
	const preferChild = COLUMNS_WITH_TOTAL_AS_CHILD.find(column => _.camelCase(column) === columnName)
	const totalInChild = isGroupedColumn && preferChild ? total : null
	const totalInParent = isGroupedColumn && !totalInChild ? total : null
	const extraProps = totalInChild && {total: totalInChild}

	const Component = getGroupedRowCell(column)
	const props = {...extraProps, ...cell.getContext()}

	return (
		<Layout grow style={inlineStyles}>
			{flexRender(Component, props)}
			{isGroupedColumn && totalInParent && total}
		</Layout>
	)
}

export const DefaultGroupedRowCell = props => {
	const {column, row, cell} = props
	const {subRows} = row
	const {columnDef, getIsGrouped, id: columnName} = column
	const UngroupedCellComponent = getCell(column)
	const isGroupedColumn = getIsGrouped()
	const translatedPlaceholder = getTranslatedPlaceholder(cell)
	const subRowValues = subRows
		.filter(Boolean)
		.map(subRow => subRow.getValue(columnName))
		.uniq()

	// For the grouped column, show the value as how it is shown in a regular, ungrouped row.
	const content = isGroupedColumn ? (
		<UngroupedCellComponent {...props} translatedPlaceholder={translatedPlaceholder} />
	) : (
		<ShowMoreTooltip items={subRowValues} />
	)

	// Add a `nill` fallback for all cells so that always some valid React is returned, to prevent crashes when the grouped
	// logic prevent a specific cell from returning a value.
	return content || null
}

export const CountAggregate = props => {
	const count = props.row.subRows.length
	return ` (${count})`
}
