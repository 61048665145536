import {Icon} from '@eitje/web_components'
import {Layout, Text, useFilterFooterContext} from 'common/components'
import {ColorCircle} from 'components/common'
import {t} from 'initializers/i18n'
import {coreStatusColor} from 'constants/core_colors'

export const StatusCell = ({getValue, total, ...rest}) => {
	const {core} = useFilterFooterContext()
	const status = getValue()
	const label = t(`${core}.statusses.${status}`)
	const color = coreStatusColor({core, status})

	return (
		<Layout gap={8}>
			{<Icon name="dot-small" fill={color} />}
			<Text> {label} </Text>
			{total}
		</Layout>
	)
}
