import {DelPopOver} from 'components/users'
import {tableFieldsDeleted} from 'constants/users'
import {useShared} from 'hooks/use_shared'
import useUserTable from 'hooks/use_user_table'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {whereInc} from 'selectors/records'
import Header from '../users/header'

const actionMenu = (item, {...rest}) => <DelPopOver style={{padding: 8}} user={item} {...rest} />

const UnemployedUsers = () => {
	const {org, env} = useShared()
	const users = useSelector(state => whereInc(state, 'users', {del_env_ids: org.environment_ids}))

	const {searchInput, columnPicker, table} = useUserTable({
		fields: tableFieldsDeleted,
		items: users,
		actionMenu,
	})

	if (!org.id) return <Redirect to={`/teams/${env.locked_team_id}/unemployed`} />
	return (
		<div>
			<Header searchInput={searchInput} columnPicker={columnPicker} />
			<div children={table} />
		</div>
	)
}

export default UnemployedUsers
