import React from 'react'
import {PageHeader as _PageHeader, EitjeButton, useFilterFooterContext} from 'common/components'
import {Publish} from './publish'
import {Tools} from './tools'
import {Help} from './help'
import {ViewOptions} from './view_options'
import {usePlanningContext} from 'cores/planning/index'
import FixedRangePicker from 'components/date/fixed_range_picker'
import RouteToggle from 'shared/components/route_toggle'
import {useRoleMessage} from 'hooks'

const PageHeader = () => {
	return (
		<_PageHeader
			height="small"
			label="common.planning_shifts"
			left={[<RangePicker />, <Tabs />]}
			right={[<ViewOptions />, <Tools />, <TeamPanel />, <Publish />, <Help />]}
		/>
	)
}

const RangePicker = props => {
	const {setDates, startDate} = usePlanningContext()
	return <FixedRangePicker {...props} onChange={dates => setDates(dates[0])} initialValue={startDate} length={7} />
}

const Tabs = props => {
	return <RouteToggle {...props} baseRoute="planning" />
}

const TeamPanel = props => {
	const {envs} = useFilterFooterContext()
	const disabled = useRoleMessage({roles: 'manager', env: envs})

	return (
		<EitjeButton
			{...props}
			disabled={disabled}
			iconLeft="person"
			modalLink={`/planning/team_panel/balance`}
			t="planning.team_panel.title"
		/>
	)
}

export default React.memo(PageHeader)
