import {PageHeader as _PageHeader, HelpButton as _HelpButton} from 'common/components'
import {GroupingPicker, ColumnPicker} from 'common/components/advanced_table'
import {TABLE_INFO} from 'common/components/advanced_table/instances/leave'
import {Tools} from './tools'
import {PeriodPicker} from './period_picker'
import {PeriodTypePicker} from './period_type_picker'

export const PageHeader = () => {
	return (
		<_PageHeader
			height="small"
			label="common.leave_requests"
			left={[<PeriodTypePicker />, <PeriodPicker />]}
			right={[<GroupingPicker {...TABLE_INFO} />, <ColumnPicker {...TABLE_INFO} />, <Tools />, <HelpButton />]}
		/>
	)
}

const docsLinks = [
	{id: 6019593, t: 'leave_requests'},
	{id: 6019044, t: 'register_leave_with_hours'},
	{id: 8773632, t: 'leave_balance'},
	{id: 7857723, t: 'leave_settings'},
]

const HelpButton = props => {
	return <_HelpButton tourId={454631} docsLinks={docsLinks} {...props} />
}
