import {API, find} from '@eitje/easy_api'
import {finishSubStep} from 'actions/onboarding'
import Modal from 'components/general/modal'
import {UserImage} from 'components/ui'
import useParams from 'hooks/use_params'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import React, {useRef} from 'react'
import {useSelector} from 'react-redux'
import {inEnv, queryInEnv} from 'selectors/records'
import {ListPicker, Text, Layout} from 'common/components'
import EitjeButton from 'common/components/eitje_button'
import './styles/members_form.less'

const MARGIN = [4, 0]

const UserRow = ({item}) => {
	const teams = useSelector(state => queryInEnv(state, 'teams', {id: item.team_ids}))
	const teamNames = teams.map(t => t.naam).join(', ')
	return (
		<Layout className="members-form-user-row" gap={16} margin={MARGIN}>
			<UserImage user={item} />
			<Layout className="members-form-user-row-text" direction="vertical" gap={0}>
				<Text bold>{item.full_name}</Text>
				<Text small darkGrey truncate>
					{teamNames}
				</Text>
			</Layout>
		</Layout>
	)
}

const MembersForm = ({isAdd = true}) => {
	const {id} = useParams()
	const form = useRef(null)
	const team = useSelector(state => find(state, 'teams', id))
	const users = useSelector(state => inEnv(state, 'users', team.environment_id))

	const addUsers = users.filter(u => !u.team_ids.includes(id))
	const removeUsers = users.filter(u => u.team_ids.includes(id))

	const relUsers = isAdd ? addUsers : removeUsers
	const hasUsers = relUsers.length > 0
	const titleStart = isAdd ? t('addTeamMembers') : t('removeTeamMembers')

	const title = `${t(titleStart)}: ${team.naam}`

	const afterSubmit = () => {
		history.goBack()
		isAdd && finishSubStep('teammembers', 'user_teams')
	}

	const cn = isAdd ? '' : 'dangerous'

	const handleSubmit = () => form.current.submit()

	const modalProps = {
		onOk: hasUsers && handleSubmit,
		hasCancel: !hasUsers,
		title,
	}

	const addPlaceholderProps = {
		animation: 'waving-people',
		docsId: '5967145',
		name: 'no_team_members',
		children: <EitjeButton modalLink="/forms/invite" colorSet="solid" iconLeft="person" t="add" />,
		size: 'small',
	}

	const removePlaceholderProps = {
		subtitle: t('noTeammembersRemove'),
		title: false,
		size: 'small',
	}

	return (
		<Modal {...modalProps} className={'add-team-members-page'}>
			<div className={`members-form ${cn} `}>
				<Form onSubmit={data => API.updateAssoc('teams', {...data, id}, {add: isAdd})} afterSubmit={afterSubmit} ref={form}>
					<div fieldWrapper className="user-list-xl">
						<ListPicker
							form={false}
							ListItemContent={UserRow}
							listItemProps={{
								layoutProps: {
									height: 60,
								},
							}}
							labelField="full_name"
							field="user_ids"
							searchProps={searchProps}
							items={relUsers}
							showPlaceholder
							placeholderProps={isAdd ? addPlaceholderProps : removePlaceholderProps}
						/>
					</div>
				</Form>
			</div>
		</Modal>
	)
}

export default MembersForm

const searchProps = {
	placeholder: t('searchTeamMember'),
}
