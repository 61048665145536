import {all, API} from '@eitje/easy_api'
import {LegacyMultiForm as MultiForm, FormWrapper} from '@eitje/form'
import {PopoutCard, Icon} from '@eitje/web_components'
import utils from '@eitje/utils'
import {
	LegacyDatePicker as DatePicker,
	LegacyDropdownPicker as DropdownPicker,
	LegacyInput as Input,
	LegacyTimePicker as TimePicker,
} from '@eitje/form-fields-web'
import EmoInput from 'components/form/emo_input'
import {UserAvatar} from 'components/ui'
import {enforcePublish, envTeamName, findPublishType, outsideClickClassNames, submitPost} from 'helpers/posts'
import useAssetPicker from 'hooks/use_asset_picker'
import useOutsideClick from 'hooks/use_outside_click'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import Form from 'initializers/form'
import {t} from 'initializers/i18n'
import {AssetButton, EventButton, ScheduledPostRow, ScheduleOptionButton, TeamButton} from 'pages/v3/news/components'
import React, {forwardRef, useEffect, useRef, useState, Fragment} from 'react'
import {useSelector} from 'react-redux'
import {extendSelector} from 'selectors/records'
import {useAdminOrgTeams} from 'hooks/use_admin_teams'
import {DeleteButton, EitjeButton, ListPicker} from 'common/components'
import './styles/news_form.less'

const NewsForm = forwardRef(
	(
		{
			item = {},
			inEvent,
			setEditPublishType,
			formKey,
			expanded = inEvent,
			hideSubmit = inEvent,
			optionRef = {current: null},
			hideEventButton = item.id || inEvent,
			...rest
		},
		ref,
	) => {
		const [pollV, setPollV] = useState()
		const [orgSelected, setOrgSelected] = useState()
		const {me, org, orgEnvs, hasManagerRole} = useShared()
		const [locked, setLocked] = useState(expanded)
		const [isExpanded, setExpanded] = useState(expanded)
		const inputRef = useRef()
		let mainForm = ref
		if (!mainForm) mainForm = useRef(null)
		const newsForm = useRef(null)
		if (mainForm.current && inputRef.current) {
			mainForm.current.focus = inputRef.current.focus
		}

		const allowOrgPosts = useSelector(state => state.settings?.organisation?.news?.allow_org_post)
		let teams = useAdminOrgTeams()
		teams = useSelector(state => extendSelector(state, 'environments', teams))

		const manualItems = useSelector(state => all(state, 'infos')).filter(article => article.published)

		const formWrapper = useRef(null)

		useOutsideClick(formWrapper, () => setExpanded(false), {
			classNames: outsideClickClassNames,
			checkParents: true,
		})

		const {triggerButton: ImageTrigger, picker: ImagePicker} = useAssetPicker('image', 'remote_avatar_url', isEdit || inEvent)
		const {triggerButton: FileTrigger, picker: FilePicker} = useAssetPicker('file', 'remote_file_url', isEdit || inEvent)
		const {triggerButton: VideoTrigger, picker: VideoPicker} = useAssetPicker('video', '', isEdit || inEvent)

		const isEdit = !!item.id
		const hasPoll = !_.isEmpty(item?.poll)
		const isPublished = item.published
		const pollForm = useRef(null)
		const answersForm = useRef(null)
		const isCreate = !inEvent && !isEdit

		const eventButton = !hideEventButton && <EventButton mainFormRef={ref} isExpanded={isExpanded} />

		// Post Schedule & Draft Status
		const [publishType, setPubilshType] = useState(isEdit ? findPublishType(item) : 'sendNow')

		useEffect(() => {
			newsForm.current.setValues(enforcePublish(publishType))
			if (isEdit) return setEditPublishType(publishType)
		}, [publishType])

		const schedOptions = <ScheduleOptionButton setPubilshType={setPubilshType} active={publishType} />
		optionRef.current = schedOptions

		const handleAfterSubmit = () => {
			setLocked(false)
			setExpanded(false)
			setPollV(false)
			setPubilshType('sendNow')
			setOrgSelected(null)
			if (isEdit) {
				history.push('/news')
			}
		}

		let teamSelectProps = {}
		let preTeamChilds

		const orgPicker = (
			<div
				className={utils.makeCns('custom-list-item', orgSelected && 'selected')}
				onClick={() => {
					newsForm.current.setValues({team_ids: []})
					newsForm.current.setErrors({team_ids: null})
					setOrgSelected(!orgSelected)
				}}
			>
				<Icon name="world" size="16px" />
				<p className="custom-list-item-label">{t('organisation')}</p>
			</div>
		)

		if (org.id && org.environment_ids.length > 1 && allowOrgPosts) {
			preTeamChilds = orgPicker
		}

		if (orgEnvs.length > 1) {
			teamSelectProps = {groupField: 'environment_id', groupItems: orgEnvs, groupLabelField: 'naam', groupReadOnly: true}
		}

		const afterChange = val => {
			newsForm.current.setValues({organisation: false, team_ids: val})
			setOrgSelected(false)
		}

		const postFormClassNames = utils.makeCns('post-form', inEvent && 'has-event', isEdit && 'edit', expanded && 'expanded')
		const expandedRelevant = !inEvent && !isEdit && !locked
		const teamsWithEnv = teams.map(t => ({...t, envTeamName: envTeamName(t)}))

		return (
			<FormWrapper
				formKey={formKey}
				ref={mainForm}
				onSubmit={({post, ...rest}) => {
					if (orgSelected) post = {...post, organisation: true}
					return submitPost({...rest, post})
				}}
			>
				<Form
					fieldProps={{bordered: false}}
					formKey="post"
					afterTouch={() => {
						setLocked(true)
					}}
					afterSubmit={handleAfterSubmit}
					formWrapper
					resetAfterSubmit={!isEdit}
					initialValues={{...item, remote_avatar_url: item.avatar, remote_file_url: item.file}}
					{...rest}
					ref={newsForm}
				>
					<div ref={formWrapper} formWrapper fieldWrapper onFocus={() => setExpanded(true)} className={postFormClassNames}>
						<div className="post-form-header-container" fieldWrapper>
							{!isEdit && !inEvent && <UserAvatar size="lg" className="margin-right-sm" user={me} />}
							<EmoInput ref={inputRef} labelVisible={isEdit} placeholder={t('subject')} required field="title" />
							{hasManagerRole && eventButton}
							{!isPublished && !inEvent && isExpanded && !isEdit && schedOptions}
						</div>

						{inEvent && (
							<div className="post-form-event-date-container form-row" fieldWrapper>
								<DatePicker placeholder={t('selectDate')} label="publishEventNews" pastDisabled field="date" />
								<TimePicker placeholder={t('selectTime')} label="selectTime" defaultOpenValue={'10:00'} field="time" />
							</div>
						)}

						{(isExpanded || !expandedRelevant) && (
							<Fragment fieldWrapper formWrapper>
								<EmoInput
									divClassName="post-form-body-container"
									placeholder={t('newsBody')}
									label="contentNews"
									labelVisible={!!(isEdit || inEvent)}
									rows={(isEdit || inEvent) && 4}
									required
									autoSize={{minRows: 3, maxRows: 7}}
									textarea
									field="content"
								/>

								{pollV && (
									// POLL FORM
									<div className="poll-form-container" formWrapper>
										<Form fieldProps={{bordered: false}} formWrapper ref={pollForm} formKey="poll_attributes">
											<div className="poll-form-header-container" fieldWrapper formWrapper>
												<div className="poll-form-title-container">
													<p className="poll-form-title">{t('createPoll')}</p>
													<p className="poll-form-subtitle">{t('directFeedback')}</p>
												</div>

												<p className="poll-remove-text" onClick={() => setPollV(false)}>
													{t('removePoll')}
												</p>
											</div>

											<div fieldWrapper className="poll-form-question">
												<Input required field="question" labelVisible={false} placeholder={t('pollQuestion')} />
											</div>

											<div fieldWrapper formWrapper className="poll-form-answers-container">
												<MultiForm
													fieldProps={{bordered: false}}
													hideControls
													maxForms={5}
													allowEmpty={false}
													ref={answersForm}
													formKey="answers_attributes"
													amtForms={3}
												>
													<AnswerInput labelVisible={false} required placeholder={t('pollAnswer')} field="answer" />
												</MultiForm>
											</div>

											<div fieldWrapper className="poll-form-duration-container">
												<DropdownPicker
													required
													style={{width: '120px'}}
													placeholder={t('selectDuration')}
													field="duration_days"
													bordered={false}
													buildLabel={count => t('amtDays', {count})}
													items={[1, 2, 3, 7]}
												/>
											</div>
										</Form>
									</div>
								)}

								<div className="post-form-asset-submit-container" fieldWrapper>
									<div className="post-form-asset-picker-container" fieldWrapper>
										<ImageTrigger />
										<VideoTrigger />
										<FileTrigger />

										{isCreate && (
											<AssetButton toolText="attachPoll" onClick={() => setPollV(!pollV)} iconSrc="/images/web/icons/news/pollIcon.png" />
										)}

										{hasManagerRole && !inEvent && (
											<ListPicker
												raw
												dropdown
												showCount
												showSearch
												virtual={manualItems.length > 40}
												Trigger={ManualBtn}
												unassignedLabel={t('looseArtLabel')}
												labelField="title"
												field="info_ids"
												items={manualItems}
												max={2}
											/>
										)}
									</div>
									<div className="post-form-submit-container" fieldWrapper>
										<ListPicker
											raw
											Trigger={TeamButton}
											dropdown
											placeholder={orgSelected ? t('organisation') : t('pickTeams')}
											title={orgSelected ? t('organisation') : t('teams')}
											{...teamSelectProps}
											preChildren={preTeamChilds}
											showCount
											labelField="naam"
											field="team_ids"
											items={teams}
											collapseGroups
											required={!orgSelected}
											afterChange={afterChange}
										/>

										{!hideSubmit && !isEdit && (
											<EitjeButton
												colorSet="solid"
												iconLeft="paper-plane"
												style={{marginLeft: 4}}
												onClick={() => mainForm.current.submit()}
											>
												{t('send')}
											</EitjeButton>
										)}
									</div>
								</div>
								{publishType === 'sendLater' && <ScheduledPostRow required field="sync_moment" setPubilshType={setPubilshType} />}

								<div className="poll-form-asset-details-container default-field" fieldWrapper>
									{hasPoll && !isPublished && <EditPollRow field="poll" />}
									<ImagePicker imageClassName="photoViewerNoShadow" field={'remote_avatar_url'} />
									<VideoPicker mapping={item.video_url_map} field={'video_ids'} assocRecord={isEdit && {post: item.id}} />
									<FilePicker imageClassName="postImageUpload" textClassName={'eitje-text-secondary'} field={'remote_file_url'} />
								</div>
							</Fragment>
						)}
					</div>
				</Form>
			</FormWrapper>
		)
	},
)

export default NewsForm

const getColor = charsLeft => {
	if (charsLeft < 5) return 'red'
	if (charsLeft < 10) return 'orange'
	return 'black'
}

const AnswerInput = props => {
	const {value = '', isLast, mayAdd, amtForms, addForm, removeForm} = props
	const charsLeft = 26 - (value.length || 0)

	const deleteCross = (
		<p className="eitje-text-secondary hRed" style={{transform: 'rotate(45deg)'}} onClick={removeForm}>
			+
		</p>
	)
	const addCross = (
		<p className="eitje-text-secondary hBlue" onClick={addForm}>
			+
		</p>
	)
	const el = isLast ? (mayAdd ? addCross : deleteCross) : deleteCross
	return (
		<div fieldWrapper className="poll-form-answer">
			<Input maxLength={26} hideCharCounter {...props} bordered={false} />

			<div fieldWrapper className="fRow aCen jEnd" style={{paddingRight: 4}}>
				<p className="eitje-text-secondary" style={{paddingRight: 12, borderRight: '1px solid #eee', color: getColor(charsLeft)}}>
					{charsLeft}
				</p>
				<div fieldWrapper className="fRow aCen jCen" style={{with: 30, maxWidth: 30, minWidth: 30}}>
					{el}
				</div>
			</div>
		</div>
	)
}
const ManualBtn = ({onClick, count}) => (
	<>
		<AssetButton toolText={'addArticle'} onClick={onClick} iconSrc={'/images/web/icons/news/trainingIcon.png'} />
		{!!count && (
			<div className="manualCountNews">
				<p>{count}</p>
			</div>
		)}
	</>
)

const handlePollRemoval = async (id, formOnChange) => {
	const res = await API.destroy('polls', id)
	if (res.ok) {
		formOnChange(undefined)
	}
}

const EditPollRow = ({value = {}, onChange}) => {
	const {id, question} = value

	if (!id && !question) return null

	return (
		<div className="eitje-picker-details-container">
			<div className="eitje-text-secondary flex-grow-one">
				Poll {t('question')}: {question}
			</div>
			<PopoutCard title={t('removePoll')}>
				<DeleteButton onDel={() => handlePollRemoval(id, onChange)} />
			</PopoutCard>
		</div>
	)
}
