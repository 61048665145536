import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useFilterFooterContext, Layout, Title} from 'common/components'
import {useShared} from 'hooks'

export const PageHeaderTitle = ({staticLabel, label}) => {
	return <Layout gap={8}>{staticLabel ? <Title weight={400} t={staticLabel} /> : <Label label={label} />}</Layout>
}

const Label = ({label}) => {
	const {envs: filteredEnvs, filteredItems} = useFilterFooterContext()
	const {multiEnvOrg, myOrgEnvs} = useShared()

	// PR comment: eerst werd hier door Chris gecheckt of er wel envs in filter zaten en anders "onbekend" geprint in UI,
	// maar slaat wmb nergens op, kan gwn alsnog de juiste env names tonen. Doe nu dus orgEnvs van de user (gaat iig op in
	// geval van verlof), omdat de envs in FF leeg zijn. Weet niet hoe die precies is bedoeld, maar is het miss. niet logischer
	// als de FF gwn alle orgEnvs van de user returnt als er geen envs geselecteerd zijn in de filter? Dan hebben we hier iig
	// de ternary niet meer nodig
	const envs = utils.exists(filteredEnvs) ? filteredEnvs : myOrgEnvs
	const envName = envs[0].naam
	const otherEnvNames = envs.slice(1)._map('naam').join(', ')

	const recordCount = `${filteredItems.length} ${t(label)}`
	const parenthesisRecordCount = `(${recordCount})`

	let title, popoutTitle, suffix

	// Eg. "12 planning shifts"
	if (!multiEnvOrg) {
		title = recordCount
	}
	// Eg. "Café Eitje (12 planning shifts)"
	else if (envs?.length === 1) {
		title = envName
		suffix = parenthesisRecordCount
	}
	// Eg. "Café Eitje +3 (12 planning shifts)"
	else {
		title = `${envName} +${envs.length - 1}`
		popoutTitle = otherEnvNames
		suffix = parenthesisRecordCount
	}

	return (
		<>
			<Title weight={400} popoutTitle={popoutTitle}>
				{title}
			</Title>
			{suffix && <Title weight={200} darkGrey children={suffix} />}
		</>
	)
}
