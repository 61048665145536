import {HelpButton} from 'common/components'
import {usePathActive} from 'hooks'

const docsLinks = [
	{id: 8228407, t: 'planning_basics'},
	{id: 5967178, t: 'create_shift'},
	{id: 6009519, t: 'create_template'},
	{id: 6009484, t: 'published_icon'},
]

export const Help = props => {
	const perTeam = usePathActive('per_team')
	const tourId = perTeam ? 438668 : 438670

	return <HelpButton {...props} tourId={tourId} tourRole="manager" docsLinks={docsLinks} collection={3355109} />
}
