import {useState, useEffect} from 'react'
import useEventListener from './use_event_listener'
import {useLocation} from 'react-router-dom'
import {useMutationObserver} from './use_mutation_observer'

export const useTopOffset = ref => {
	const [offset, setOffset] = useState(0)
	const location = useLocation()

	const applicationHeaders = document.querySelector('.application-headers')

	const computeOffset = () => {
		const rect = ref.current.getBoundingClientRect()
		setOffset(rect.top + window.scrollY)
	}

	useEventListener('resize', computeOffset)

	// Also recheck the top offset when the height of the headers changes
	useMutationObserver({
		mutationFunction: computeOffset,
		observeOptions: {childList: true, subtree: true},
		element: applicationHeaders,
	})

	useEffect(() => {
		if (ref.current) {
			computeOffset()
		}
	}, [ref, location])

	return Math.round(offset)
}
