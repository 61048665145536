import FixedRangePicker from 'components/date/fixed_range_picker'
import RangePicker from 'components/date/range_picker'
import {usePerMemberContext, PeriodPicker as WagePeriodPicker} from 'cores/time_registration'

export const PeriodPicker = props => {
	const {startDate, diff, endDate, period, setRawDates} = usePerMemberContext()
	if (period == 'custom')
		return (
			<RangePicker
				maxDays={60}
				allowClear={false}
				value={[startDate, endDate]}
				onChange={dates => setRawDates(...dates, 'custom')}
				{...props}
			/>
		)
	if (period.includes('wage')) return <WagePeriodPicker onChange={(start, end) => setRawDates(start, end, period)} {...props} />
	return (
		<FixedRangePicker
			initialLeading
			onChange={dates => setRawDates(dates[0], dates[dates.length - 1])}
			isMonthPicker={period == 'month'}
			initialValue={startDate}
			length={diff + 1}
			{...props}
		/>
	)
}
