import SmartAvatar from 'components/chat/SmartAvatar'
import {Avatar as ChatAvatar} from 'stream-chat-react'
import {PopoutPicker} from './popout_picker'

const selectAvatarImageUrl = '/images/selectAvatar.png'
const imageURLs = [...Array(12).keys()].map((_, i) => `https://dh4gz9vp93q2o.cloudfront.net/images/teamAva${i + 1}.png`)

const RemoteImage = ({value: url, onChange, active}) => {
	return (
		<div className="chat-image-box  avatarPopOverSelectorSpace" onClick={() => onChange(url)} key={url}>
			<SmartAvatar size="lg" selected={active} image={url} />
		</div>
	)
}

const Avatar = ({value, ...rest}) => <ChatAvatar image={value} size={100} {...rest} />

const RemoteImagePicker = props => {
	return <PopoutPicker defaultValue={selectAvatarImageUrl} Item={RemoteImage} SelectedEl={Avatar} items={imageURLs} {...props} />
}

export default RemoteImagePicker
