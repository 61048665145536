import {useRef, useEffect, useContext, createContext} from 'react'
import utils from '@eitje/web_utils'
import {useTopOffset} from 'hooks/use_top_offset'
import {formatCssVars} from 'helpers'
import {Layout, RenderBlocker} from 'common/components'
import './styles/index.less'
import {useHeightObserver} from 'hooks'

const PageContext = createContext({})
const {Provider} = PageContext

export const PageLayout = ({
	children,
	state,
	name,
	footers,
	headers,
	bodyLayoutProps,
	bodyBottomPadding = 48,
	BodyComponent = Layout,
	className,
	...rest
}) => {
	const pageRef = useRef()
	const headerRef = useRef()
	const bodyRef = useRef()
	const footerRef = useRef()

	const topOffset = useTopOffset(pageRef) // causes rerender
	const pageHeight = `calc(100vh - ${topOffset}px`

	const headerHeight = useHeightObserver(headerRef) || 0 // causes rerender
	const footerHeight = useHeightObserver(footerRef) || 0 // causes rerender
	const maxBodyHeight = `calc(100vh - ${topOffset}px - ${footerHeight}px - ${headerHeight}px`

	const layoutClassName = name && _.kebabCase(`${name}-page-layout`)
	const classNames = utils.makeCns('page-layout', layoutClassName, className)

	const value = {
		...state,
		bodyRef,
	}

	const styles = formatCssVars({
		bodyBottomPadding,
		'page-top-offset': topOffset,
	})

	const id = `${name}-page`

	return (
		<Provider value={value}>
			<Layout id={id} ref={pageRef} className={classNames} direction="vertical" height={pageHeight} gap={0}>
				{headers && (
					<Layout ref={headerRef} direction="vertical" className="page-headers" width="100%" gap={0}>
						{headers}
					</Layout>
				)}
				<BodyComponent
					ref={bodyRef}
					grow
					className="page-body"
					direction="vertical"
					gap={0}
					maxWidth="100vw"
					width="100vw"
					maxHeight={maxBodyHeight}
					relative
					style={styles}
					{...bodyLayoutProps}
					{...rest}
				>
					{/* RenderBlocker prevents children from being displayed after reload */}
					{children}
				</BodyComponent>
				{footers && (
					<Layout ref={footerRef} className="page-footers" width="100%" gap={0}>
						{footers}
					</Layout>
				)}
				{state && <StateSaver state={state} />}
			</Layout>
		</Provider>
	)
}

export const usePageContext = () => {
	return useContext(PageContext)
}

const StateSaver = ({state = {}}) => {
	useEffect(() => {
		if (!window.local) window.local = {}
		window.local = {...window.local, ...state}

		return () => {
			window.local = _.omit(window.local, Object.keys(state))
		}
	}, [state])

	return null
}
