import {figureOutActive, isDone, makeSubStep} from 'actions/onboarding'
import {getSettings} from 'actions/settings'
import {Checkbox, Progress, Switch} from 'antd'
import Drawer from 'components/general/drawer'
import {stepProps} from 'constants/onboarding'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {getEnvSetting} from 'selectors/settings'
import * as jsUtils from 'utils/jsutils'

const Block = ({idx, onClick, name, done, active, stepClick, title, body, mins, expLink, activeStep, steps = {}}) => {
	const imgSrc = active ? '/images/howLong.png' : '/images/howLongGrey.png'
	const stepKeys = Object.keys(steps)
	const completedSteps = _.values(steps).filter(Boolean).length
	const nextStep = figureOutActive(steps)
	return (
		<div onClick={onClick} className="cardOnboarding">
			<div style={{display: 'flex', alignItems: 'center'}}>
				{!active && (
					<div className="rowLeftBoard">
						<div className="posBolInrichting">
							{done ? (
								<img src="/images/success.png" />
							) : (
								<div className="centerCenterFlex inrichtingBol blueBG">
									<p>{idx + 1}</p>
								</div>
							)}
						</div>
					</div>
				)}

				<div className="rowCenterBoard" style={{marginLeft: active && '12%'}}>
					<div className="titleOnboarding">
						<p style={{color: active && '#2b2b2b'}}>{t(title)}</p>
						{active && <span>{t(body)}</span>}
					</div>

					<div className={`bottomOnboarding ${active && 'activeOnboarding'}`}>
						<div className="startFlex" style={{width: '82%'}}>
							<Progress
								format={percent => `${completedSteps}/${stepKeys.length}`}
								percent={(completedSteps / stepKeys.length) * 100}
								status="active"
							/>
						</div>

						<div className="startFlex" style={{width: '18%'}}>
							<img src={imgSrc} />
							<span>{mins} min</span>
						</div>
					</div>
				</div>
			</div>

			{active && stepKeys.length > 0 && (
				<div className="progressOnboarding">
					{stepKeys.map(k => (
						<div className={`onboardingStep centerFlex ${nextStep === k && 'onboardingStepActive'}`} onClick={() => stepClick(k)}>
							<p style={{color: active && activeStep === k ? '#0496ff' : steps[k] ? '#82828275' : '#2b2b2b'}}>
								{t(`onb_step_${name}_${k}`)}
							</p>
							<Checkbox disabled checked={steps[k]} />
						</div>
					))}
				</div>
			)}
		</div>
	)
}

const _OnboardingDrawer = ({user, envId, inrichtingsModus, onClose, steps}) => {
	if (!steps || !_.isObject(steps) || !_.isObject(_.values(steps)[0])) {
		return null
	}

	const keys = Object.keys(steps)
	const act = figureOutActive(steps)
	const [active, setActive] = useState()
	const [activeStep, setActiveStep] = useState()
	const [width, setWidth] = useState('100%')
	useEffect(() => {
		getSettings(user.id, envId)
		setActive(act || keys[0] || undefined)
	}, [])

	useEffect(() => {
		const act = active
		if (activeStep && active) {
			setActiveStep(null)
		}
	}, [active])

	let subDrawerStyle = {}

	if (!!activeStep) {
		subDrawerStyle['transform'] = 'translateX(420px'
	}

	debugger
	return (
		<Fragment>
			<Drawer
				className="drawerOnboarding drawerOne"
				width={420}
				title={t('firstAid')}
				placement="left"
				style={{width: activeStep ? 420 : '100%', transform: 'translateX(0px)'}}
				zIndex={999}
			>
				<div>
					<div className="inrichtingMSG" style={{display: 'flex', alignItems: 'center'}}>
						<div className="rowLeftBoard">
							<div className="posBolInrichting">
								<div className="centerCenterFlex inrichtingBol red">
									<p>!</p>
								</div>
							</div>
						</div>

						<div className="rowCenterBoard">
							<div>
								<p>{t('eitjeInrichting')}</p>
							</div>
						</div>
					</div>

					<div className="overFlowY modalOverflowTop" style={{marginBottom: 80}}>
						{keys.map((k, idx) => (
							<Block
								activeStep={activeStep}
								onClick={() => setActive(k)}
								done={isDone(steps[k])}
								idx={idx}
								name={k}
								steps={steps[k]}
								active={active ? active === k : act ? k === act : idx === 0}
								title={`onb_title_${k}`}
								body={`onb_body_${k}`}
								stepClick={stepKey => setActiveStep(stepKey)}
								{...stepProps[k]}
							/>
						))}
					</div>
				</div>

				<Drawer
					visible={!!activeStep}
					className="drawerOnboarding drawerTwo"
					width={360}
					title={t('howTo')}
					placement="left"
					style={subDrawerStyle}
					onClose={() => setActiveStep(null)}
					zIndex={998}
				>
					<div className="sideDrawer">{makeSubStep(active, activeStep)}</div>
				</Drawer>
			</Drawer>
		</Fragment>
	)
}

export default connect(state => ({
	steps: state.settings.taxonomy?.onboarding_steps,
	envId: state.environment.active,
	user: state.auth.user,
	inrichtingsModus: getEnvSetting(state, 'onboarding', 'active'),
}))(_OnboardingDrawer)
