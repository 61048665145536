import {FooterFilters} from './helpers'
import {ClearFiltersButton, Layout} from 'common/components'
import {useSearchContext} from 'contexts/search'
import {useFilterFooterContext} from './context'
import {useShared} from 'hooks'

export const FilterFooter = () => {
	let {clearFilters, rightElements, hasFiltered, filteredOutAmount} = useFilterFooterContext()
	const {searchInput, search} = useSearchContext()
	const {orgEnvs} = useShared()

	if (orgEnvs.length <= 1) hasFiltered.environment = false
	const filteredBySearch = search && filteredOutAmount
	const anyFiltered = Object.values(hasFiltered).some(Boolean) || filteredBySearch

	return (
		<Layout className="filter-footer" horizontal="spaceBetween" padding="12" borderTop colorSet="black-bordered">
			<Layout className="filter-footer-left-items">
				{anyFiltered && <ClearFiltersButton filteredOutAmount={filteredOutAmount} clearFilters={clearFilters} />}
				<FooterFilters />
			</Layout>
			<Layout className="filter-footer-right-items">
				{rightElements}
				{searchInput}
			</Layout>
		</Layout>
	)
}
