import {EitjeDropdown, Text} from 'common/components'
import {ModalLink} from 'components/routing'
import {useSelectPlanningShifts, useShiftViewOptions} from 'cores/planning/components/user_planning_shifts_row/panel_dropdown'
import {usePerMemberContext} from 'cores/planning/pages/index/per_member/per_member_provider'
import {useRoleMessage} from 'hooks'
import {useGetPlanningShiftUrlWithEnv} from 'cores/planning/helpers'
import {t} from 'initializers/i18n'

export const PanelDropdown = ({...rest}) => {
	const createShiftUrl = useGetPlanningShiftUrlWithEnv({})
	const {openShifts} = usePerMemberContext()
	const notManager = useRoleMessage({roles: 'manager'})

	const elements = [
		{icon: 'plus', element: <ModalLink to={createShiftUrl} t={t('add')} />, disabled: notManager},
		useSelectPlanningShifts({shiftIds: openShifts.ids(), disabled: notManager}),
		useShiftViewOptions(),
	]

	return <EitjeDropdown elements={elements} {...rest} strategy="fixed" placement="right" />
}
