import {t} from 'initializers/i18n'
import {useOpenModularChat} from 'actions/chat'

const StartChatLink = ({openChat}) => {
	if (!openChat) return null
	return (
		<p className="start-chat-link" onClick={openChat}>
			{t(`common.start_chat`)}
		</p>
	)
}

const useStartChatLink = userId => {
	const {openChat} = useOpenModularChat(userId) || {}
	const startChat = <StartChatLink openChat={openChat} />
	return {startChat, openChat}
}

export {useStartChatLink}
export default StartChatLink
