import {t} from 'initializers/i18n'

export const pickerMap = array => array.map(v => ({id: v, name: t(v)}))

export const humanReadableList = array => {
	const list = array.map(item => `'${item}'`)
	if (list.length === 0) return ''
	if (list.length === 1) return list[0]
	const lastItem = list.pop()
	return `${list.join(', ')} ${t('common.or')} ${lastItem}`
}
