export const updateUrls = {
	users: id => `users/${id}/new_update`,
	shifts: id => `shifts/${id}/new_update`,
	events: id => `events/${id}/new_update`,
	teams: id => `teams/${id}/new_update`,
}

export const createUrls = {
	shifts: () => `shifts/new_create`,
	events: () => 'events/new_create',
	reg_shifts: () => 'reg_shifts/create',
	teams: () => 'teams/new_create',
}

export const indexUrls = {
	planning: () => 'shifts/month_planning',
	own_reg_shifts: () => 'reg_shifts/new_index',
	reg_shifts: () => 'reg_shifts/all_index',
}

export const stampFields = {
	posts: 'created_at',
}
