import {t} from 'initializers/i18n'
import {PlanningShift} from 'models'
import {ModalLink} from 'components/routing'
import {MultiAction as BaseMultiAction} from 'components/common/index'
import {useFilterFooterContext, Text} from 'common/components'
import {useEnvsContext} from 'cores/planning/pages/index/envs_provider'

const transNameSpace = 'planning.multi_edit.multi_action'

export const MultiAction = () => {
	const {multiEdit, selectedShifts, setMultiEdit, setSelectedShiftsRaw} = useEnvsContext()
	const {filteredItems: shifts} = useFilterFooterContext()

	if (!multiEdit) return null

	const _selectedShifts = shifts.filter(s => selectedShifts.includes(s.id))
	const shiftIds = _selectedShifts.map(s => s.id)
	const count = _selectedShifts.length
	const title = count > 0 ? 'has_selected' : 'select_to_edit'

	const togglePublishedStatus = bool => updateField({shiftIds, field: 'published', value: bool})

	const publishStatus = {
		title: t(`${transNameSpace}.publish_status`),
		children: [
			<Text onClick={() => togglePublishedStatus(true)} t={`${transNameSpace}.publish_selected`} />,
			<Text onClick={() => togglePublishedStatus(false)} t={`${transNameSpace}.depublish_selected`} />,
		],
	}

	const pickupStatus = {
		title: t(`${transNameSpace}.pickup_status`),
		children: [
			<ModalLink t={`${transNameSpace}.allow_pickup`} to={`/confirm?ids=${shiftIds}&type=allow_pickup`} />,
			<ModalLink t={`${transNameSpace}.deny_pickup`} to={`/confirm?ids=${shiftIds}&type=deny_pickup`} />,
		],
	}

	const elements = [
		<ModalLink t={`${transNameSpace}.edit`} to={`/planning_shifts/multi_edit?ids=${shiftIds}`} />,
		<ModalLink t={`${transNameSpace}.save_as_template`} to={`/planning_templates/save?ids=${shiftIds}`} />,
		<ModalLink t={`${transNameSpace}.delete`} to={`/confirm?ids=${shiftIds}&type=planning_shift`} />,
		publishStatus,
		pickupStatus,
	]

	const handleClose = () => {
		setMultiEdit(false)
		setSelectedShiftsRaw([])
	}

	return <BaseMultiAction count={count} elements={elements} close={handleClose} title={t(`${transNameSpace}.${title}`, {count})} />
}

const updateField = ({shiftIds, field, value}) => {
	const items = shiftIds.map(id => ({id, [field]: value}))
	PlanningShift.multiUpdate(items)
}

export default MultiAction
