import {buildColumn, XLARGE_CELL_WIDTH} from 'common/components/advanced_table'

export const columns = [
	{
		id: 'user_id',
		accessorFn: leaveRequest => leaveRequest.user?.full_name,
		width: XLARGE_CELL_WIDTH,
	},
	{
		id: 'status',
		accessorFn: leaveRequest => leaveRequest.status(),
		cell: 'StatusCell',
	},
	{
		accessorKey: 'reden',
		width: XLARGE_CELL_WIDTH,
	},
	{
		id: 'start_datetime',
		accessorFn: leaveRequest => leaveRequest.startDate(),
		cell: 'DateCell',
	},
	{
		id: 'end_datetime',
		accessorFn: leaveRequest => leaveRequest.endDate(),
		cell: 'DateCell',
	},
	{
		accessorKey: 'total_mins',
		cell: 'TimeCell',
		aggregationFn: 'sum',
	},
	{
		id: 'amt_days',
		accessorFn: leaveRequest => leaveRequest.days(),
		aggregationFn: 'sum',
		aggregates: true,
	},
	{
		accessorKey: 'environment_id',
		cell: 'EnvironmentCell',
	},
	{
		accessorKey: 'created_at',
		cell: 'DateCell',
	},
	{
		accessorKey: 'processor_id',
		cell: 'UserCell',
		translatedPlaceholder: 'common.not_processed',
	},
	{
		id: 'processed_at',
		accessorFn: leaveRequest => leaveRequest.processed_at,
		cell: 'DateTimeCell',
	},
].map(buildColumn)
