import {writeStore} from 'common/components/advanced_table'
import utils from '@eitje/web_utils'

export const groupColumn = ({isGroupedColumn, columnName, tableName}) => {
	// If the column is already grouped, it should be ungrouped by means of a null value
	const grouped = isGroupedColumn ? null : columnName
	writeStore(tableName, {grouped})
}

export const onGroupingChange = ({
	groupedColumn,
	setGrouping,
	onSortingChange,
	setSort,
	instanceTaxonomy,
	advancedTableInstance,
	setColumnPinning,
}) => {
	setGrouping(utils.alwaysDefArray(groupedColumn))

	if (groupedColumn) {
		// When grouping a column, sort on that column by default. The user may change it afterwards.
		const sort = [{id: groupedColumn, desc: false}]
		onSortingChange({sort, setSort, instanceTaxonomy})

		// When grouping a column, make that column the most left column and pin it.
		const currentlyPinnedColumns = advancedTableInstance.getLeftFlatHeaders()._map('id')
		const pinnedColumns = [groupedColumn, ...currentlyPinnedColumns].uniq()
		setColumnPinning({left: pinnedColumns})
	}
}
