import {Children, useRef} from 'react'
import {BasicPopout} from '@eitje/web_components'
import {BackButton, SubmitButton} from 'common/components/eitje_button/eitje_buttons'
import {t} from 'initializers/i18n'

export const SubmitPopout = ({
	buttons,
	title = t('popouts.submit.title'),
	text = t('popouts.submit.text'),
	onCancel = _.noop,
	onSubmit,
	submitText,
	disabled,
	...rest
}) => {
	const ref = useRef()

	const handleCancel = e => {
		ref.current?.hide()
		onCancel(e)
	}

	const handleSubmit = e => {
		ref.current?.hide()
		onSubmit(e)
	}

	const _buttons = [
		<BackButton onClick={handleCancel} />,
		...Children.toArray(buttons),
		onSubmit && <SubmitButton onClick={handleSubmit} children={submitText} disabled={disabled} />,
	]

	return <BasicPopout trigger="click" ref={ref} title={title} text={text} buttons={_buttons} disabled={disabled} {...rest} />
}
