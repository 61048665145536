import React, {useRef} from 'react'
import Form from 'initializers/form'
import {PopoutCard, Icon} from '@eitje/web_components'
import {LegacyInput as Input} from '@eitje/form-fields-web'
import {Redirect} from 'components/routing'
import {t} from 'initializers/i18n'
import {API, useGet} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import utils from '@eitje/web_utils'
import useShared from 'hooks/use_shared'
import {history} from 'index'
import usePlacesAutocomplete from 'hooks/use_places_autocomplete'
import {MultiEmailInput} from 'lib/form/fields/multi_text_input'
import useBillingInfo from 'cores/billing/hooks/use_billing_info'
import {Layout, DocsButton, Text} from 'common/components'
import './setup.less'

const Setup = () => {
	const {env, me} = useShared()
	const billingInfo = useBillingInfo()
	const {env_names = [], expired} = billingInfo
	const initialValues = billingInfo.person_name
		? billingInfo
		: {company_name: env.naam, ...billingInfo, person_name: billingInfo.person_name || me.full_name}
	const [formState, setState] = useMergeState(initialValues)
	const {placesInput, placesData} = usePlacesAutocomplete({initialValues: billingInfo})
	const {formatted_address} = placesData

	const {data} = useGet(`environments/${env.id}/current_plan`)
	const form = useRef(null)

	if (billingInfo.person_name) return <Redirect to="/billing/setup_mollie" />

	return (
		<Modal
			insertAtIndex={{1: <DocsButton docsId={8765642} />}}
			okText={t('common.next_step')}
			width={1200}
			onOk={() => form.current.submit()}
			hasCancel
		>
			<div id="billing-setup-page">
				<div className="billing-setup-modal-left-side">
					<div className="billing-setup-modal-title-container">
						<h3>account activeren </h3>
						<h5>{t('billing.texts.activate_account')} </h5>
					</div>

					<Form
						ref={form}
						onSubmit={data => submitForm({...placesData, ...data})}
						afterSubmit={() => history.replace('/billing/setup_mollie')}
						mirrorFields={['first_name', 'last_name', 'emails', 'company_name']}
						setState={setState}
						fieldProps={{label: true, required: true}}
						initialValues={initialValues}
						transNamespace="billing"
					>
						<Input required field="company_name" />
						<Input required field="person_name" />
						<div className="eitje-field-location" fieldWrapper>
							<h5 className="location-label"> {t('form.billing.label.address')} </h5>
							{placesInput}
						</div>
						<MultiEmailInput field="emails" labelVisible />
					</Form>
				</div>
				<ContractInfo info={billingInfo} env_names={env_names} data={data} expired={expired} address={formatted_address} {...formState} />
			</div>
		</Modal>
	)
}

const submitForm = data => {
	if (!data.house_number || !data.postal_code) return addrRequired()
	return API.create('billing_infos', data)
}

const addrRequired = () => utils.errNotif(t('oops'), t('form.validation.billing.address_required'))

const Tier = ({tier, info, tier_price}) => {
	const {is_free} = info
	const price = is_free ? 0 : tier_price
	const content = `${utils.formatMoney(price)} ${t('common.per_member_per_month')}`
	return (
		<Layout card width="full" padding={8} spaceBetween>
			<Text>{t(`common.tiers.${tier}`)} </Text>
			<PopoutCard title={t('billing.titles.staffable_free')} hidden={!is_free}>
				<Text small grey>
					{content}
				</Text>
			</PopoutCard>
		</Layout>
	)
}

const ContractInfo = ({data, info, expired, env_names, person_name, company_name, emails = [], address}) => {
	const count = emails.length
	const billingEnvsStr = `${t('billingEnvsConsent')}: ${env_names.join(', ')} `
	const title = expired ? 'billing.titles.activate_account_expired' : 'billing.titles.activate_account'
	return (
		<div className="billing-setup-modal-right-side">
			<Layout direction="vertical" gap={8}>
				<Text bold popoutTitle={billingEnvsStr}>
					{t(title)} - {company_name}
				</Text>

				<div className="billing-info-card">
					<div className="billing-info-overview">
						<h4>{company_name} </h4>
						<h5>{address} </h5>
						{person_name && (
							<h5>
								{t('abbreviations.attn')} {person_name}
							</h5>
						)}
					</div>
					<div className="recipients-count-card">
						<Icon name="envelope" />
						<Text small>
							{count} {t('common.recipients', {count})}
						</Text>
					</div>
				</div>

				<aside>
					<Tier {...data} info={info} />
				</aside>
			</Layout>
		</div>
	)
}

export default Setup
