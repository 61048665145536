import auth from './reducers/auth'
import entities from './reducers/entities'
import rooster from './reducers/rooster'
import general from './reducers/general'
import revenue from './reducers/revenue'
import uren from './reducers/uren'
import chat from './reducers/chat'
import environment from './reducers/environment'
import {reducer} from '@eitje/easy_api'
import settings from './reducers/new_settings'
import filters from './reducers/filters'
import arbitrary from './reducers/arbitrary'
import taxonomy from './reducers/taxonomy'
import components from './reducers/components'
import fileCacher from 'reducers/file_cachers'
import whatsNew from 'reducers/whats_new'
import planning from 'reducers/planning'
import local from 'reducers/local'
import timeRegistration from 'reducers/time_registration'
import recordErrors from 'reducers/record_errors'
import leave from 'reducers/leave'

export default {
	auth,
	entities,
	records: reducer,
	settings,
	components,
	rooster,
	revenue,
	general,
	uren,
	chat,
	environment,
	filters,
	arbitrary,
	taxonomy,
	fileCacher,
	whatsNew,
	planning,
	local,
	timeRegistration,
	recordErrors,
	leave,
}
