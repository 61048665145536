import {useEffect} from 'react'

import useShared from 'hooks/use_shared'
import {useChannelStateContext} from 'stream-chat-react'
import {ChannelAssets, ChannelInfoHeader, ChannelMembersLists, SmallUserInfo} from './components'
import useChatOrphaned from 'hooks/use_chat_orphaned'
import {t} from 'initializers/i18n'
export const ChannelRhsContainer = () => {
	const {me, env} = useShared()
	const {members: _members, channel} = useChannelStateContext()
	const {type} = channel
	const members = Object.values(_members)
	const isGroup = type == 'group' || type == 'team'
	const is1on1 = type == 'messaging'
	const isOrphaned1on1 = useChatOrphaned(channel, _members)

	useEffect(() => {
		channel.markRead && channel.markRead()
	}, [])

	return (
		<div className={isGroup ? 'right_chat_container' : 'right_chat_container user'}>
			<ChannelInfoHeader />
			{isGroup && <ChannelMembersLists />}
			{is1on1 && !isOrphaned1on1 && <SmallUserInfo userId={members.filter(user => user.user_id != me.id)[0].user_id} />}
			{isOrphaned1on1 && (
				<div style={{width: '100%', paddingBottom: 24}}>
					<div
						style={{width: '92%', marginLeft: '4%', padding: '12px 24px', backgroundColor: '#F5F6F7', borderRadius: 4}}
						className="fRow aCen jCen shadow"
					>
						<p style={{textAlign: 'center'}} className="smallP">
							{t('orphanedChat1')}
						</p>
					</div>
				</div>
			)}

			<ChannelAssets type="image" length={isGroup ? 3 : 6} />
			<ChannelAssets type="file" length={isGroup ? 3 : 5} />
		</div>
	)
}

export default ChannelRhsContainer
