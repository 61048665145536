import {CollapseChevron} from '@eitje/web_components'
import utils from '@eitje/web_utils'
import {formatCssVars, makeCnVariants} from 'helpers'
import {Layout} from 'common/components'
import './styles/index.less'
import {
	CellCheckbox,
	GroupedRowCell,
	GROUPED_CELL_WIDTH,
	DEFAULT_CELL_WIDTH,
	getLeftOffset,
	isLastPinnedColumn,
	renderCell,
	getPinnedColumns,
	TableText,
} from 'common/components/advanced_table'

export * from './constants'
export * from './components'
export * from './cells'
export * from './helpers'

export const AdvancedTableCell = ({row, cell, index, multiEdit, ...rest}) => {
	const {column, getContext} = cell
	const {id: columnName, getIsPinned, getIsGrouped, columnDef} = column
	const {getIsExpanded, getCanExpand, subRows} = row
	const isGroupedColumn = getIsGrouped()
	const canExpand = getCanExpand()
	const context = getContext()
	const styles = formatCssVars({'left-offset': getLeftOffset(index, context)})
	const lastPinned = isLastPinnedColumn(index, context)
	const showCollapseChevron = isGroupedColumn && canExpand
	const pinnedColumns = getPinnedColumns(context)
	const withCheckbox = multiEdit && pinnedColumns[0] === columnName
	const classNames = utils.makeCns(
		makeCnVariants('advanced-table-body-cell', getIsPinned() && 'pinned', lastPinned && 'last-pinned'),
		columnName,
	)
	cell.hasSubRows = utils.exists(subRows)

	const layoutProps = {
		height: 32,
		padding: 8,
		width: columnDef.width || (isGroupedColumn ? GROUPED_CELL_WIDTH : DEFAULT_CELL_WIDTH),
		horizontal: showCollapseChevron && 'last-item-end',
	}

	return (
		<Layout className={classNames} key={cell.id} {...layoutProps} style={styles}>
			{withCheckbox && <CellCheckbox row={row} cell={cell} />}
			{canExpand ? <GroupedRowCell isGroupedColumn={isGroupedColumn} row={row} cell={cell} /> : <TableText>{renderCell(cell)}</TableText>}
			{showCollapseChevron && <CollapseChevron open={getIsExpanded()} />}
		</Layout>
	)
}
