import {channelData, findAvatarImage, lastMessageDisplayTime, modularClick, timeOfLastMessage} from 'actions/chat'
import React, {useContext} from 'react'
import {useSelector} from 'react-redux'
import {ChatStateContext} from '../providers/chat_provider'
import SmartAvatar from './SmartAvatar'
import {t} from 'initializers/i18n'

const LastestMessage = ({latestMessage, bold}) => {
	let text = latestMessage
	if (typeof latestMessage === 'object') text = latestMessage.props.source
	return <p className={bold ? 'subhead eitje-text-secondary bold truncate' : 'eitje-text-secondary truncate'}>{text}</p>
}

//Destructing not possible - functions use API information
const ChannelListBox = props => {
	const {displayTitle} = props
	const {setActivelySelectedChannels, toggleChatDropdown, setCurrentChannel, modular, filterUnreadMessages} = useContext(ChatStateContext)
	const userId = useSelector(state => state.auth.user.id)
	const ent = useSelector(state => state.entities.envs)
	const {otherUser = {}} = channelData(props.channel, userId, ent)
	const hasUnreadMessages = props.unread > 0

	if (!hasUnreadMessages && filterUnreadMessages) {
		return null
	}

	const time = lastMessageDisplayTime(timeOfLastMessage(props))

	return (
		<div
			className={modular ? 'modular channel-box' : props.active ? 'channel-box active' : 'channel-box'}
			onClick={() =>
				modular
					? modularClick(props.channel, setCurrentChannel, setActivelySelectedChannels, toggleChatDropdown)
					: props.setActiveChannel(props.channel)
			}
		>
			<SmartAvatar size={'lg'} userId={otherUser.id} name={props.displayTitle} image={findAvatarImage(props.channel, userId)} />

			<div className="channel-box-text-container">
				<p className="channel-box-title">{props.displayTitle || t('oldUser')}</p>
				<LastestMessage {...props} bold={hasUnreadMessages} />
			</div>

			<div className="channel-box-end">
				<p className="eitje-text-secondary">{time}</p>
				{hasUnreadMessages && <div className="chat_notify_circle">{props.unread}</div>}
			</div>
		</div>
	)
}

export default ChannelListBox
