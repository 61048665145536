import ActiveRecord from 'models/active_record/base'
import {date} from 'initializers/date'
import {DateRange} from 'moment-range'

class User extends ActiveRecord {
	static tableName = 'users'

	salary(dateRange) {
		const {salaries} = this
		if (!salaries) return null
		if (!dateRange) return null
		if (typeof dateRange != DateRange) return salaries.find(c => c.dateRange.contains(dateRange))
		return salaries.find(c => c.dateRange.overlaps(dateRange))
	}

	birthday() {
		return this.verjaardag && date(this.verjaardag)
	}

	nextBirthday() {
		const birthday = this.birthday()
		if (!birthday) return

		const currentYear = date().year()
		let nextBday = birthday.clone().year(currentYear)

		const today = date()

		if (nextBday.isBefore(today)) {
			nextBday.add(1, 'years')
		}

		return nextBday
	}

	previousBirthday() {
		const nextBday = this.nextBirthday()
		if (!nextBday) return
		return nextBday.clone().subtract(1, 'years')
	}

	age() {
		const birthday = this.birthday()
		if (!birthday) return

		const today = date()
		return today.diff(birthday, 'years')
	}

	findContractPart(part, dateRange) {
		const parts = this[part]
		if (!parts || !dateRange) return null
		if (typeof dateRange != DateRange) return parts.find(p => p.dateRange.contains(dateRange))
		return parts.find(c => c.dateRange.overlaps(dateRange))
	}

	workSchedule = dateRange => this.findContractPart('workSchedules', dateRange)
	salary = dateRange => this.findContractPart('salaries', dateRange)
	userEmploymentType = dateRange => this.findContractPart('userEmploymentTypes', dateRange)
	contractHolder = dateRange => this.findContractPart('contractHolders', dateRange)
}

export default User
