import {date} from 'initializers/date'
import {rangeFromStart} from 'helpers/date'
import {PlanningShift, TimeRegistrationShift, User} from 'models/index'
import useFormData from 'hooks/use_form_data'
import {AmtWorked as Base} from 'cores/time_registration/index'

export function _rangeFromStart({start, length = 7, kind = 'day'} = {}) {
	return moment.rangeFromInterval(kind, length - 1, start) // I find length more logical as in 'total length of range'
}

const AmtWorked = ({user}) => {
	const {date} = useFormData()
	if (!date) return null
	return <AmtWorkedInner user={user} date={date} />
}

const AmtWorkedInner = ({date, user}) => {
	const dateRange = rangeFromStart({start: date})
	const shifts = TimeRegistrationShift.where({date: dateRange, user_id: user.id})
	if (!date) return null
	return <Base shifts={shifts} user={user} dateRange={dateRange} />
}

export default AmtWorked
