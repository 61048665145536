import {t} from 'initializers/i18n'
import useShared from 'hooks/use_shared'
import useArbitrary from 'hooks/use_arbitrary'
import {EitjeButton, Layout} from 'common/components'
import './activate.less'

const EitjeActivated = () => {
	return <p className="onboarding-account-was-activated-text"> {t('onboarding.texts.account_activated')} </p>
}

const ActivateEitje = () => {
	const {days_remaining, locked} = useArbitrary('orgStatus') || {}
	const {hasAdminRole, org} = useShared()
	let text = 'activate_initial'
	if (locked) text = 'activate_locked'
	if (org.billing_done) text = 'activate_second'

	if (!hasAdminRole) return null
	return (
		<Layout className="start-eitje-header-left-content">
			<p className="count-down-text"> {t(`onboarding.texts.${text}`, {count: days_remaining})} </p>
			<EitjeButton modalLink="/billing/setup" className="activate-now-button" height="small" dropdown t="onboarding.buttons.activate" />
		</Layout>
	)
}

export {ActivateEitje, EitjeActivated}
