export const ROLE_TRANSLATIONS = {
  financieel: 'financial',
  uren_accorderen: 'approve_hours',
  uren_schrijven: 'write_hours',
  admin: 'admin',
  manager: 'manager',
  onroosterbaar: 'unschedulable',
  contracten: 'contracts',
  verlof: 'leave',
}
