import {t} from 'initializers/i18n'
import {store} from 'index'
import {useRef} from 'react'
import {EitjeButton, ListPicker} from 'common/components'
import {getTableHeaderFieldName, writeStore, useVisibleColumns, useGroupedColumn} from 'common/components/advanced_table'

export const GroupingPicker = ({fields: allTableColumns, tableName, recordType, ...rest}) => {
	const listPickerRef = useRef()
	const savedGrouping = useGroupedColumn({tableName})
	const visibleColumns = useVisibleColumns({tableName, allTableColumns})
	const items = visibleColumns?.map(f => mapField(f, tableName, recordType, savedGrouping))

	const handleChange = value => {
		writeStore(tableName, {grouped: value})
		listPickerRef?.current?.closeDropdown()
	}

	return (
		<ListPicker
			forwardRef={listPickerRef}
			raw
			dropdown
			data-intercom-target="grouping_picker"
			noSort
			showSearch
			allowClear
			single
			placeholder={t('table.group')}
			title={({label}) => `${t('common.group_by')}: ${label}`}
			value={savedGrouping}
			triggerColorSet={savedGrouping?.length ? 'color-bordered-fill' : 'grey-bordered'}
			showClearAll={false}
			onChange={handleChange}
			items={items}
			{...rest}
		>
			{!!savedGrouping?.length && (
				<EitjeButton width="full" iconLeft="unlocked" colorSet="solid" t="table.ungroup" onClick={() => handleChange(null)} />
			)}
		</ListPicker>
	)
}

const mapField = (field, name, recordType) => {
	const _name = getTableHeaderFieldName({name, recordType, field})
	return {id: field, name: _name}
}
