import {t} from 'initializers/i18n'

export const BooleanCell = ({getValue}) => {
	const val = getValue()
	// much smart code. currently, if a cell's value is false, the render doesn't run for some reason. I didn't want to dig into it because this is a small fix
	// but we should fix this hackery next time we add a table

	if (val == 'no') return t('no')
	if (val == 'yes') return t('yes')
	return null
}
