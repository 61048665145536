import _ from 'lodash'

if (!Array.prototype.flat) {
	Object.defineProperty(Array.prototype, 'flat', {
		value: function (depth = 1) {
			return this.reduce(function (flat, toFlatten) {
				return flat.concat(Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten)
			}, [])
		},
	})
}

Array.prototype.sum = function (mapper) {
	const baseArray = mapper ? this.map(mapper) : this
	return baseArray.reduce((tally, val) => tally + val, 0)
}

Array.prototype.avg = function (mapper) {
	const total = this.sum(mapper)
	return total / this.length
}

Array.prototype.uniq = function (arg) {
	if (arg) return _.uniqBy(this, arg)
	return _.uniq(this)
}

Array.prototype.sortBy = function (fn, sort = null) {
	return _.orderBy(this, fn, sort)
}

Array.prototype.groupBy = function (groupField) {
	return _.groupBy(this, groupField)
}

Array.prototype.max = function (prop) {
	if (this.length == 0) return 0
	if (prop) return this._map(prop).max()
	return Math.max(...this)
}

Array.prototype._map = function (mapper) {
	return typeof mapper === 'string' ? this.map(item => item[mapper]) : this.map(mapper)
}

const iterator = (self, fn, mapper) => {
	if (typeof mapper === 'string') {
		const isInverted = mapper.startsWith('!')
		return self[fn](item => {
			const condition = item[mapper.replace('!', '')]
			return isInverted ? !condition : condition
		})
	}
	return fn(mapper)
}

Array.prototype.last = function () {
	return this[this.length - 1]
}

Array.prototype._every = function (mapper) {
	return iterator(this, 'every', mapper)
}

Array.prototype._filter = function (mapper) {
	return iterator(this, 'filter', mapper)
}

Array.prototype._some = function (mapper) {
	return iterator(this, 'some', mapper)
}

Array.prototype.each = Array.prototype.forEach

Array.prototype._moveIndex = function (from, to) {
	this.splice(to, 0, this.splice(from, 1)[0])
}
