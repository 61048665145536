import React, {Fragment, cloneElement, isValidElement, useRef} from 'react'
import {BasicPopout, PopoutCard, Icon} from '@eitje/web_components'
import PropTypes from 'prop-types'
import {parseT} from 'initializers/i18n'
import {makeCnVariants} from 'helpers'
import {Text} from '../text'
import utils from '@eitje/web_utils'
import {handleClickOutside} from 'hooks'
import {ancestorHasCns} from 'hooks'
import './styles/index.less'
import {Layout} from '../layout'

const PADDING = [8, 16]

export const EitjeDropdown = ({elements = [], bodyLayoutClassName, ...rest}) => {
	const dropdownRef = useRef()
	const classNames = utils.makeCns(bodyLayoutClassName, 'eitje-dropdown')

	const body = elements.filter(Boolean).map((item, i) => <DropdownComponent key={i} dropdownRef={dropdownRef} item={item} {...rest} />)

	return (
		<PopoutCard
			eventName="dropdown"
			ref={dropdownRef}
			bodyLayoutProps={{padding: 0, gap: 0, className: classNames}}
			body={body}
			{...rest}
		/>
	)
}

const DropdownElement = ({item, visibleAfterClick, hide}) => {
	const elementRef = useRef()
	const innerRef = useRef()
	const dropdownElementRef = useRef()
	const {isGroup, title, onClick, skipHandleClick, rightElement} = item
	const innerElement = title && isGroup ? <Text bold>{title}</Text> : item.element
	let element = isValidElement(item) ? item : innerElement
	const isPopoutElement = elementRef?.current?.querySelectorAll('.popout-card').length

	element = cloneElement(element, {
		ref: innerRef,
	})

	const popoutTitle = useDisabledMessage(elementRef, item)
	const disabledButtons = item.disabled?.buttons

	// When the DOM element has no children, we can conclude it did not return any in the render
	const children = dropdownElementRef?.current?.children

	const itemReturnsNull = children && Array.from(children).length === 0

	const findAndClickElement = ({children, e}) => {
		let clickableChild = children?.[0]

		if (clickableChild.className.includes('popout-card')) clickableChild = clickableChild.firstChild
		clickableChild && handleClickOutside(e, () => clickableChild.click(), undefined, {current: clickableChild})
	}

	const handleClick = e => {
		// use ancestorHasCns to prevent double click, on element itself and its wrapper

		// 1. If we get an 'onclick', it means the element itself does not have a click action, otherwise you should not pass onClick]
		// 2. If we don't, we need to know if the clicked child is part of the element. If that's true, the element itself should handle clicking.
		// if it isn't, that probably means the row itself or an icon inserted by us was clicked. Then we need to find the DOM node of our element and click that.

		if (onClick) {
			onClick?.(e)
		} else if (!ancestorHasCns(e.target, 'dropdown-element-wrapper')) {
			findAndClickElement({children, e})
		}

		if (visibleAfterClick || isPopoutElement) return
		hide()
	}

	const Wrapper = popoutTitle ? (disabledButtons ? BasicPopout : PopoutCard) : Fragment
	let condOpts = {}
	// skipHandleClick is needed to ensure clicking on the outsides of the item works correctly
	if (!isGroup && !skipHandleClick) {
		condOpts.onClick = handleClick
	}

	const classNames = utils.makeCns('dropdown-element', item.className)

	return (
		<Wrapper
			trigger="hover"
			title={(disabledButtons && item.disabled.title) || popoutTitle}
			layoutProps={{width: 'full'}}
			{...(popoutTitle && item.parentLayoutProps)}
			text={popoutTitle}
			buttons={disabledButtons}
			placement={disabledButtons && 'right'}
		>
			<Layout
				{...condOpts}
				ref={elementRef}
				onMouseEnter={innerRef?.current?.show}
				onMouseLeave={innerRef?.current?.hide}
				className={classNames}
				colorSet
				disabled={item.disabled}
				style={{display: itemReturnsNull && 'none'}}
				intialBackground={Colors.lightGrey}
				hasHover={!isGroup}
				padding={PADDING}
				gap={12}
				width="100%"
				{...(!popoutTitle && item.parentLayoutProps)}
			>
				{item.icon && <Icon name={item.icon} />}
				<div ref={dropdownElementRef} className="dropdown-element-wrapper">
					{element}
				</div>
				{rightElement}
			</Layout>
		</Wrapper>
	)
}

// We add a proptype here to make it clear how the item prop can be passed
const itemType = PropTypes.oneOfType([
	PropTypes.shape({
		element: PropTypes.elementType,
		icon: PropTypes.string,
		disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
		isGroup: PropTypes.bool,
		title: props => {
			const {title, isGroup} = props
			if (isGroup && !title) {
				return new Error()
			}
		},
	}),
	PropTypes.elementType,
])

DropdownElement.propTypes = {
	item: itemType,
}

const DropdownElementWithNesting = ({item, ...rest}) => {
	const element = <Text>{item.title}</Text>
	const rightElement = <Icon className="dropdown-arrow-icon" name="chevron-small-right" />

	const classNames = makeCnVariants('eitje-dropdown', 'nested')

	const body = utils.alwaysArray(item.children).map((item, i) => <DropdownElement item={item} key={i} {...rest} />)

	return (
		<PopoutCard
			placement="right-start"
			body={body}
			layoutProps={{width: 'full'}}
			bodyLayoutProps={{className: classNames, gap: 0, padding: 0}}
			hasHover
			colorSet
		>
			<DropdownElement item={{...item, element, rightElement}} {...rest} />
		</PopoutCard>
	)
}

const DropdownComponent = ({item, dropdownRef, ...rest}) => {
	const props = {item, hide: dropdownRef.current?.hide, ...rest}
	return item.children ? <DropdownElementWithNesting {...props} /> : <DropdownElement {...props} />
}

const useDisabledMessage = (ref, item) => {
	const disabledElement = ref?.current?.querySelector('[disabled]')
	let lokaliseKey = disabledElement?.getAttribute('disabled-t')
	if (_.isString(item.disabled) || _.isObject(item.disabled)) lokaliseKey = item.disabled
	const disabled = (disabledElement || item.disabled) && 'disabled'
	return disabled && parseT(lokaliseKey)
}
